import React, { useEffect, useState } from 'react';
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { useSelector } from 'react-redux';
import { selectImportFields } from '@selectors/importSelectors';
import { IProductsImport } from '@components/Modal/types';
import ImportTemplate from '@components/Modal/ModalComponents/Import/ImportTemplate/ImportTemplate';
import useBindDispatch from '@hooks/useBindDispatch';
import { createTemplateAction } from '@actions/import';

const MappingForm = ({ handleChange, values }: IProductsImport) => {
  const mappedFields = useSelector(selectImportFields);
  const createTemplate = useBindDispatch(createTemplateAction);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(
      Object.keys(values).length === Object.values(values).filter((value) => !!value).length,
    );
  }, [values]);

  const handleCreateTemplate = (name: string) => {
    createTemplate({ name, mappedFields: values });
  };

  return (
    <Box>
      <ImportTemplate valid={valid} handleCreateTemplate={handleCreateTemplate} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Fields in the application</TableCell>
              <TableCell>Fields in the imported document</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(values).map((field) => (
              <TableRow key={field} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {field}
                </TableCell>
                <TableCell component="td" scope="row">
                  <FormControl
                    fullWidth
                    onChange={() => {
                      console.log(123123);
                    }}
                  >
                    <InputLabel
                      required={field !== 'Paper ID' && field !== 'Original store URL'}
                      id={`select-${field}`}
                    >
                      Select field
                    </InputLabel>
                    <Select
                      required={field !== 'Paper ID' && field !== 'Original store URL'}
                      value={values[field]}
                      labelId="select-category-label"
                      id="select-category"
                      name={`${field}`}
                      onChange={handleChange}
                      label="Category"
                    >
                      {mappedFields.map((mappedField) => (
                        <MenuItem value={mappedField} id={field} key={`${field}-${mappedField}`}>
                          {mappedField}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MappingForm;
