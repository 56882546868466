import { IActionButton } from '@components/types';
import IconButton from '@mui/material/IconButton';
import { DeleteOutlined } from '@mui/icons-material';
import * as React from 'react';

const IconButtonDelete = ({ onClick }: IActionButton) => {
  return (
    <IconButton color={'warning'} className={'deleteBtn'} aria-label="delete" onClick={onClick}>
      <DeleteOutlined />
    </IconButton>
  );
};

export default IconButtonDelete;
