import React from 'react';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import { useSelector } from 'react-redux';
import { selectBakedData } from '@selectors/importSelectors';
import { Box, CardMedia } from '@mui/material';
import useBindDispatch from '@hooks/useBindDispatch';
import { setSelectedBakedRecordsAction } from '@actions/import';

const createNameColumn = (id: string, nameObject: Record<string, string>) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {Object.keys(nameObject).map((key: string) => (
        <div key={`${id}-${key}`}>
          {' '}
          {key}: {nameObject[key]}{' '}
        </div>
      ))}
    </Box>
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70, flex: 1 },
  {
    field: 'shopName',
    headerName: 'Shop name',
    width: 120,
    flex: 1,
  },
  {
    field: 'category',
    headerName: 'Category names',
    width: 120,
    flex: 1,
    renderCell: (params) => createNameColumn(params.value.id, params.value.names),
  },
  {
    field: 'logoUrl',
    headerName: 'Product photo',
    width: 130,
    flex: 1,
    renderCell: (params) => (
      <CardMedia
        component="img"
        image={params.value || `${process.env.REACT_APP_BE_URL}404.png`}
        alt="card-image"
      />
    ),
  },
  {
    field: 'names',
    headerName: 'Product names',
    type: 'string',
    width: 60,
    flex: 1,
    renderCell: (params) => createNameColumn(params.row.id, params.value),
  },
  {
    field: 'endDate',
    headerName: 'Promotion end date',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    flex: 1,
    width: 80,
  },
  {
    field: 'discount',
    headerName: 'Discount',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    flex: 1,
    width: 80,
  },
  {
    field: 'oldPrice',
    headerName: 'Old price',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 80,
    flex: 1,
    renderCell: (params) => `${params.value} CHF`,
  },
  {
    field: 'newPrice',
    headerName: 'New price',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 80,
    flex: 1,
    renderCell: (params) => `${params.value} CHF`,
  },
  {
    field: 'url',
    headerName: 'Original store URL',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    flex: 1,
    width: 100,
  },
];

const BakedProductTable = () => {
  const bakedData = useSelector(selectBakedData);
  const setSelected = useBindDispatch(setSelectedBakedRecordsAction);

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={bakedData}
        columns={columns}
        pageSize={5}
        isRowSelectable={(params: GridRowParams) => params.row.valid}
        rowHeight={100}
        selectionModel={bakedData.filter((row) => row.valid && row.selected).map((r) => r.id)}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = bakedData
            .filter((row) => selectedIDs.has(row.id.toString()))
            .map((row) => row.id);

          setSelected(selectedRowData);
        }}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </Box>
  );
};

export default BakedProductTable;
