import { IActionButton } from '@components/types';
import { Button } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import React from 'react';

const AddItemButton = ({ onClick, title }: IActionButton) => {
  return (
    <Button
      color={'primary'}
      onClick={onClick}
      sx={{ margin: '20px 0', width: 'max-content', maxHeight: '35px' }}
      variant="outlined"
      startIcon={<AddCircle />}
    >
      {title}
    </Button>
  );
};

export default AddItemButton;
