import * as types from '@actions/actionTypes';
import { LoginType } from '@actions/types';

export const setIsLoadingAction = (payload: boolean) => ({
  type: types.SET_IS_LOADING,
  payload,
});

export const loginAction = (payload: LoginType) => ({
  type: types.LOGIN,
  payload,
});

export const logoutAction = () => ({
  type: types.LOGOUT,
});
