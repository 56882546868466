import { InitialAppStateType, ReducerAction } from '@reducers/types';
import {
  CLOSE_MODAL,
  CLOSE_NOTIFICATION,
  SET_ADDRESS,
  SET_MENU_OPENED,
  SET_NOTIFICATION,
  SHOW_MODAL,
} from '@actions/actionTypes';

const initialState: InitialAppStateType = {
  menu: {
    opened: true,
  },
  modal: {
    type: null,
    isOpened: false,
    entityId: null,
  },
  notification: {
    type: null,
    isVisible: false,
    message: '',
  },
  autocomplete: {
    addresses: [],
  },
  isLoading: false,
};

const appReducer = (
  state: InitialAppStateType = initialState,
  { type, payload }: ReducerAction,
): InitialAppStateType => {
  switch (type) {
    case SET_MENU_OPENED: {
      return { ...state, menu: { ...state.menu, opened: payload } };
    }
    case CLOSE_MODAL: {
      return { ...state, modal: { ...state.modal, isOpened: false } };
    }
    case SHOW_MODAL: {
      return { ...state, modal: { ...payload, isOpened: true } };
    }
    case SET_NOTIFICATION: {
      const { type, message } = payload;
      return { ...state, notification: { isVisible: true, type, message } };
    }
    case CLOSE_NOTIFICATION: {
      return { ...state, notification: { ...state.notification, isVisible: false } };
    }
    case SET_ADDRESS: {
      return { ...state, autocomplete: { ...state.autocomplete, addresses: payload } };
    }
  }
  return state;
};

export default appReducer;
