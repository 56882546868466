import { IActionButton } from '@components/types';
import IconButton from '@mui/material/IconButton';
import { EditOutlined } from '@mui/icons-material';
import * as React from 'react';

const IconButtonEdit = ({ onClick }: IActionButton) => {
  return (
    <IconButton aria-label="edit" onClick={onClick}>
      <EditOutlined />
    </IconButton>
  );
};

export default IconButtonEdit;
