import React from 'react';
import styles from '@pages/Products/Products.module.scss';
import SwipeableTabs from '@components/SwipeableTabs/SwipeableTabs';
import ImportTab from '@components/ImportTab/ImportTab';
import useBindDispatch from '@hooks/useBindDispatch';
import { deleteDocumentAction, deleteTemplateAction } from '@actions/import';

const ImportPage = () => {
  const deleteDocuments = useBindDispatch(deleteDocumentAction);
  const deleteTemplates = useBindDispatch(deleteTemplateAction);

  return (
    <>
      <div className={styles.header}>
        <h1>Files</h1>
      </div>
      <SwipeableTabs labels={['Papers', 'Products']}>
        <ImportTab
          type={'papers'}
          deleteDocumentAction={deleteDocuments}
          deleteTemplateAction={deleteTemplates}
        />
        <ImportTab
          type={'products'}
          deleteDocumentAction={deleteDocuments}
          deleteTemplateAction={deleteTemplates}
        />
      </SwipeableTabs>
    </>
  );
};

export default ImportPage;
