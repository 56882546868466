import * as types from '@actions/actionTypes';
import { ICategoryResponsePagination, ICreateCategoryRequestPayload } from '@requests/types';
import { ICategoryRecord, IDeleteCategoryRecord } from '@reducers/types';

export const fetchCategoriesAction = (payload: Record<string, any>) => ({
  type: types.FETCH_CATEGORIES,
  payload,
});

export const saveCategoriesAction = (payload: ICategoryResponsePagination) => ({
  type: types.SET_CATEGORIES,
  payload,
});

export const createCategoryAction = (payload: ICreateCategoryRequestPayload) => ({
  type: types.CREATE_CATEGORY,
  payload,
});

export const updateCategoryAction = (payload: Partial<ICategoryRecord>) => ({
  type: types.UPDATE_CATEGORY,
  payload,
});

export const deleteCategoryAction = (payload: IDeleteCategoryRecord) => ({
  type: types.DELETE_CATEGORY,
  payload,
});
