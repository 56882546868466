export const UUID_REGEX = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/;

export const isValidUUID = (uuid?: string) => {
  const match = uuid?.match(UUID_REGEX);
  return match && match?.length === uuid?.length;
};

export const URL_REGEX =
  //eslint-disable-next-line
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const blobFromImage = (image: File) => {
  return URL.createObjectURL(image as Blob);
};

export const countArrayOfObjects = (
  array: Record<any, any>[] | null,
  key: string,
  accumulateBy: string,
) => {
  const result = array
    ?.reduce((acc: Record<string, string | number>[], item) => {
      const index = acc.findIndex((it) => it[key] === item[key]);
      const itemCopy = Object.assign({}, item);
      if (index >= 0) {
        acc[index][accumulateBy] += itemCopy[accumulateBy];
      } else {
        acc.push(itemCopy);
      }
      return acc;
    }, [])
    .sort((a, b) => Number(b[accumulateBy]) - Number(a[accumulateBy]));
  return result;
};

export const capitalizeWord = (word: string | null) =>
  `${word?.charAt(0).toUpperCase()}${word?.slice(1)}`;
