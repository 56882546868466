import { IAppState } from '@store/model';
import { createSelector } from 'reselect';
import qs from 'query-string';

export const selectRouter = (state: IAppState) => state.router;

export const selectSearchQuery = createSelector([selectRouter], (router) => router.location.search);

export const selectShopId = createSelector([selectSearchQuery], (query) => qs.parse(query).shopId);

export const selectCategoryId = createSelector(
  [selectSearchQuery],
  (query) => qs.parse(query)?.categoryId,
);

export const selectPageNumber = createSelector(
  [selectSearchQuery],
  (query) => Number(qs.parse(query)?.page) || 1,
);

export const selectLimit = createSelector([selectSearchQuery], (query) =>
  Number(qs.parse(query)?.limit),
);

export const selectNameQuery = createSelector(
  [selectSearchQuery],
  (query) => qs.parse(query)?.name,
);

export const selectPath = createSelector([selectRouter], (router) => router.location.pathname);
