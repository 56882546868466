import { useEffect, useState } from 'react';

const useDebounce = (searchString: string, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(searchString);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchString);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchString, delay]);

  return debouncedValue;
};

export default useDebounce;
