import { Days, ITimeTableDay, MODAL_TYPES } from '@components/Modal/types';
import { uploadTableAction } from '@actions/import';
import { Moment } from 'moment';
import { LocationHeat } from '@components/types';
import { NotificationTypes } from '@constants/enums';

interface IMenuStateType {
  opened: boolean;
}

export interface IModalStateType {
  type: MODAL_TYPES | null;
  isOpened?: boolean;
  entityId?: string | null;
}

export interface INotificationAlertType {
  isVisible: boolean;
  type: NotificationTypes | null;
  message: string;
}

export interface InitialAppStateType {
  menu: IMenuStateType;
  modal: IModalStateType;
  autocomplete: {
    addresses: [];
  };
  notification: INotificationAlertType;
  isLoading: boolean;
}

export type TranslationObjectType = {
  EN: string;
  DE: string;
  FR: string;
  IT: string;
  BP: string;
};

export interface IShopCategoryModel {
  id: string;
  categoryId?: string;
  shopId?: string;
}

export interface ICategoryRecord {
  ShopCategoryModel: IShopCategoryModel;
  id: string;
  isFavorite: boolean;
  metaCategories?: {
    categoryId: string;
    name: string;
    id: string;
  }[];
  names: TranslationObjectType;
}

export type IDeleteCategoryRecord = Pick<ICategoryRecord, 'id'>;

export interface InitialCategoriesStateType {
  records: ICategoryRecord[];
  pagesTotal: number;
  isLoading: boolean;
}

export type ITimeTableType = {
  [key in Days]: ITimeTableDay;
} & {
  id: string;
  holidays: Array<Moment | string>;
};

export interface IStoreRecord {
  id: string;
  latitude: number;
  longitude: number;
  address: string;
  useShopTimeTable: boolean;
  shopId: string;
  timeTable: ITimeTableType;
}

export interface IPaperRecord {
  id: string;
  externalId: string;
  shopId: string;
  shop: IShopRecord;
  names: TranslationObjectType;
  categories: ICategoryRecord[] | [];
  endDate: string;
  logoUrl: string;
  imagesUrls: string[];
}

export interface IShopRecord {
  id: string;
  isFavorite: boolean;
  categories: ICategoryRecord[];
  name: string;
  logoUrl: string;
  papers: IPaperRecord[];
  timeTable: ITimeTableType;
  stores: IStoreRecord[];
}

export interface IGetWithPagination<T> {
  data: T[];
  pagesTotal: number;
}

export interface IGetWithScroll<T> {
  data: T[];
  hasNext: boolean;
}

export interface IProductRecord {
  id: string;
  externalId: string | null;
  url: string;
  endDate: string;
  discount: string;
  oldPrice: number;
  newPrice: number;
  logoUrl: string;
  paperId: string | null;
  paper: IPaperRecord;
  shopCategoryId: null;
  shopCategory: IShopCategoryModel;
  names: TranslationObjectType;
  shop: IShopRecord;
}

export interface IBakedProductRecord
  extends Pick<
    IProductRecord,
    'id' | 'url' | 'endDate' | 'discount' | 'oldPrice' | 'newPrice' | 'logoUrl' | 'names'
  > {
  valid: boolean;
  selected: boolean;
  category: Pick<ICategoryRecord, 'id' | 'names'>;
}

export interface IBakedPaperRecord extends Pick<IPaperRecord, 'id' | 'endDate' | 'logoUrl'> {
  valid: boolean;
  selected: boolean;
  category: Pick<ICategoryRecord, 'id' | 'names'>;
}

export type IBakedRecord = IBakedProductRecord | IBakedPaperRecord;

export type ImportDocType = 'products' | 'papers' | null;

export type ImportedDocumentType = {
  id: string;
  name: string;
  date: string;
  status: ImportStep;
  type: ImportDocType;
};

export interface ImportStateType {
  id: string;
  isLoading: boolean;
  status: ImportStep;
  baked: IBakedRecord[];
  mappedFields: Record<string, string>;
  type: ImportDocType;
  fields: string[];
  templates: ImportTemplateType[];
  documents: ImportedDocumentType[];
}

export interface InitialProductsStateType {
  records: IProductRecord[];
  pagesTotal: number;
  isLoading: boolean;
}

export interface InitialPapersStateType {
  records: IPaperRecord[];
  pagesTotal: number;
  isLoading: boolean;
}

export type ConversionStatisticType = {
  pageViews: {
    amount: number;
    day: string;
  }[];
  newVisitors: {
    amount: number;
    day: string;
  }[];
  sessions: {
    amount: number;
    day: string;
  }[];
  averageSessionDuration: {
    amount: number;
    day: string;
  }[];
};

export type UsageStatisticType = {
  firstOpen: {
    Android: {
      day: string;
      os: string;
      amount: string;
    }[];
    iOS: {
      day: string;
      os: string;
      amount: string;
    }[];
  } | null;
  totalPerPlatform: StatisticPlatform[] | null;
  conversion: ConversionStatisticType | null;
};

export interface StatisticStateType {
  favoriteShops: {
    isLoading: boolean;
    data: StatisticEventType[] | null;
  };
  favoriteCategories: {
    isLoading: boolean;
    data: StatisticEventType[] | null;
  };
  topProducts: {
    isLoading: boolean;
    data: StatisticEventType[] | null;
  };
  topSearchQueries: {
    isLoading: boolean;
    data: StatisticEventType[] | null;
  };
  topSelectedShops: {
    isLoading: boolean;
    data: StatisticEventType[] | null;
  };
  locations: {
    isLoading: boolean;
    data: StatisticEventType[] | null;
  };
  usage: {
    isLoading: boolean;
    data: UsageStatisticType | null;
  };
  isLoading: boolean;
}

export interface InitialShopsStateType {
  records: IShopRecord[];
  selected: IShopRecord | null;
  pagesTotal: number;
  isLoading: boolean;
}

export type ReducerAction = {
  type: string;
  payload?: any;
};

export type IDocumentImportType = {
  id: string;
  fields: string[];
  mappedFields: Record<string, string>;
  baked: IBakedRecord[];
  status: ImportStep;
  entityIds: string[] | null;
  type: string;
};

export enum ImportStep {
  UPLOAD,
  MAPPING,
  BAKING,
  FINISHED,
}

export type UploadTableType = typeof uploadTableAction;

export type StatisticEventType = {
  id?: string;
  name?: string;
  amount: number;
};

export interface StatisticLocation extends StatisticEventType, LocationHeat {}

export interface StatisticPlatform extends StatisticEventType {
  os: 'iOS' | 'Android';
}

export type ImportTemplateType = {
  id: string;
  name: string;
  mappedFields: Record<string, string>;
  type: ImportDocType;
};
