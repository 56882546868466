import * as types from '@actions/actionTypes';
import { StatisticEventType, StatisticStateType, UsageStatisticType } from '@reducers/types';

export const fetchStatisticAction = (payload: { from: Date; till: Date }) => ({
  type: types.FETCH_STATISTIC,
  payload,
});

export const setStatisticAction = (payload: StatisticStateType) => ({
  type: types.SET_STATISTIC,
  payload,
});

export const setStatisticLoadingAction = (payload: boolean) => ({
  type: types.SET_STATISTIC_IS_LOADING,
  payload,
});

export const setFavoriteShopsStatisticAction = (payload: StatisticEventType[]) => ({
  type: types.SET_FAVORITE_SHOPS_STATISTIC,
  payload,
});

export const setFavoriteShopsStatisticLoadingAction = (payload: boolean) => ({
  type: types.SET_FAVORITE_SHOPS_STATISTIC_LOADING,
  payload,
});

export const setFavoriteCategoriesStatisticAction = (payload: StatisticEventType[]) => ({
  type: types.SET_FAVORITE_CATEGORIES_STATISTIC,
  payload,
});

export const setFavoriteCategoriesStatisticLoadingAction = (payload: boolean) => ({
  type: types.SET_FAVORITE_CATEGORIES_STATISTIC_LOADING,
  payload,
});

export const setTopProductsAction = (payload: StatisticEventType[]) => ({
  type: types.SET_TOP_PRODUCTS_STATISTIC,
  payload,
});

export const setTopProductsLoadingAction = (payload: boolean) => ({
  type: types.SET_TOP_PRODUCTS_STATISTIC_LOADING,
  payload,
});

export const setTopSearchQueriesAction = (payload: StatisticEventType[]) => ({
  type: types.SET_TOP_QUERIES_STATISTIC,
  payload,
});

export const setTopSearchQueriesLoadingAction = (payload: boolean) => ({
  type: types.SET_TOP_QUERIES_STATISTIC_LOADING,
  payload,
});

export const setTopSelectedSearchShopsAction = (payload: StatisticEventType[]) => ({
  type: types.SET_TOP_SELECTED_SEARCH_SHOPS,
  payload,
});

export const setTopSelectedSearchShopsLoadingAction = (payload: boolean) => ({
  type: types.SET_TOP_SELECTED_SEARCH_SHOPS_LOADING,
  payload,
});

export const setTopLocationsAction = (payload: StatisticEventType[]) => ({
  type: types.SET_TOP_LOCATIONS,
  payload,
});

export const setTopLocationsLoadingAction = (payload: boolean) => ({
  type: types.SET_TOP_LOCATIONS_LOADING,
  payload,
});

export const setUsageStatisticAction = (payload: UsageStatisticType) => ({
  type: types.SET_USAGE_STATISTIC,
  payload,
});

export const setUsageStatisticLoadingAction = (payload: boolean) => ({
  type: types.SET_USAGE_STATISTIC_LOADING,
  payload,
});
