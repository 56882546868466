import { Box, Button, Typography } from '@mui/material';
import {
  modalButtonsSectionStyle,
  modalContentContainerStyle,
} from '@components/Modal/ModalContainer';
import React from 'react';
import { IPaperModal } from '@components/Modal/types';

const DeletePaperModal = ({ handleSubmit, paper, closeModal, submitButtonText }: IPaperModal) => {
  return (
    <>
      <Box sx={modalContentContainerStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Delete paper
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure you want to delete this paper?
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          All data will be lost
        </Typography>
      </Box>
      <Box sx={modalButtonsSectionStyle}>
        <Button size="large" variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={handleSubmit.bind(null, paper?.id)} size="large" variant="contained">
          {submitButtonText}
        </Button>
      </Box>
    </>
  );
};

export default DeletePaperModal;
