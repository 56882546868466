import React from 'react';
import { Container, ThemeProvider } from '@mui/material';
import { CardTileTheme } from '@src/themes';
import { ICardTile } from '@components/types';
import FakeCard from '@components/CardItems/FakeCard/FakeCard';

const CardTile = ({ Card, items, isLoading, children }: ICardTile) => {
  return (
    <ThemeProvider theme={CardTileTheme}>
      <Container>
        {children}
        {isLoading
          ? Array(8)
              .fill(1)
              .map((_, idx) => <FakeCard key={`fakeCard-${idx}`} />)
          : items?.map((item) => <Card key={item.id} item={item} />)}
      </Container>
    </ThemeProvider>
  );
};

export default React.memo(CardTile);
