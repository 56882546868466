import * as yup from 'yup';
import { URL_REGEX } from '@src/utils';

export const categoryFormValidationSchema = yup.object({
  translation: yup.object().test({
    test: (obj) => Object.keys(obj).length === Object.values(obj).filter((value) => !!value).length,
    message: 'All names should be filled',
  }),
});

export const productFormValidationSchema = yup.object({
  shop: yup.object().required('Shop is required'),
  category: yup.object().required('Category is required'),
  translation: yup.object().test({
    test: (obj) => Object.keys(obj).length === Object.values(obj).filter((value) => !!value).length,
    message: 'All names should be filled',
  }),
  url: yup
    .string()
    .matches(URL_REGEX, 'Website should be a valid URL (Starts with http or https://)')
    .required('Store url is required'),
  promotionEndDate: yup.date().required('Promotion end date is required'),
  discount: yup.string().required('Discount is required'),
  oldPrice: yup
    .string()
    .matches(
      /^[0-9]{1,6}([,.][0-9]{1,2})?$/,
      'Price should be presented as a number (number or number.number)')
    .required('Old price is required'),
  newPrice: yup
    .string()
    .matches(
      /^[0-9]{1,6}([,.][0-9]{1,2})?$/,
      'Price should be presented as a number (number or number.number)',
    )
    .required('New price is required'),
});

export const paperValidationSchema = yup.object({
  shop: yup.object().required('Shop is required'),
  categories: yup
    .array()
    .test({
      test: (value) => !!value?.length,
      message: 'Category is required',
    })
    .required('Category is required'),
  translation: yup.object().test({
    test: (obj) => Object.keys(obj).length === Object.values(obj).filter((value) => !!value).length,
    message: 'All names should be filled',
  }),
  promotionEndDate: yup.date().required('Promotion end date is required'),
});

export const storeValidationSchema = yup.object({
  address: yup.string().required('Address is required'),
});

export const shopFormValidationSchema = yup.object({
  name: yup.string().required('Shop name is required'),
  categories: yup
    .array()
    .test({
      message: 'Category is required',
      test: (arr) => !!arr?.length,
    })
    .required('Category is required'),
});

export const templateCreationValidationSchema = yup.object({
  name: yup.string().required('Template name is required'),
});
