import * as React from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IMenuItemInteractive } from '@components/types';
import { Link, useLocation } from 'react-router-dom';

const MenuItem = ({ expanded, title, Icon, path }: IMenuItemInteractive) => {
  const { pathname } = useLocation();
  const selected = pathname.includes(title.toLowerCase());

  return (
    <Link to={path} style={{ textDecoration: 'none', color: 'white' }}>
      <ListItemButton
        selected={selected}
        sx={{
          minHeight: 56,
          borderLeft: selected ? '3px solid white' : 'none',
          justifyContent: expanded ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: expanded ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {Icon && <Icon sx={{ color: 'white' }} />}
        </ListItemIcon>
        <ListItemText primary={title} sx={{ opacity: expanded ? 1 : 0 }} />
      </ListItemButton>
    </Link>
  );
};

export default MenuItem;
