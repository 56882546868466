import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getLocalStorage } from '@utils/localstorage';
import { AUTH_TOKEN_KEY } from '@constants/constants';
import { ROUTE } from '@router/routes';

const PrivateRoutes = () => {
  const token = getLocalStorage(AUTH_TOKEN_KEY);
  return token ? <Outlet /> : <Navigate to={ROUTE.LOGIN} />;
};

export default PrivateRoutes;
