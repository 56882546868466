import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Typography,
  Badge,
  Checkbox,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';
import {
  modalButtonsSectionStyle,
  modalContentContainerStyle,
} from '@components/Modal/ModalContainer';
import { ThemeProvider } from '@mui/material/styles';
import { ICreateShopFormState, IShopModal, IShopModalFormState } from '@components/Modal/types';
import { useFormik } from 'formik';
import { grey } from '@mui/material/colors';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import DefaultInput from '@components/Form/Inputs/DefaultInput/DefaultInput';
import { ICategoryRecord } from '@reducers/types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimeTableDialog from '@components/Modal/ModalComponents/TimeTable/TimeTableDialog';
import { modalTheme } from '@src/themes';
import { useDefaultTimeTable } from '@hooks/useDefaultTimeTable';
import { shopFormValidationSchema } from '@components/Modal/ModalComponents/FormValidationSchemas';

const CategoryAutocomplete = Autocomplete<ICategoryRecord, true>;

const ShopModal = ({
  title,
  handleSubmit,
  categories,
  closeModal,
  submitButtonText,
  shop,
}: IShopModal) => {
  const [showTimeTable, setShowTimeTable] = useState<boolean>(false);

  const formik = useFormik<IShopModalFormState>({
    initialValues: {
      id: shop?.id || '',
      logo: null,
      name: shop?.name || '',
      isFavorite: shop?.isFavorite || false,
      categories: shop?.categories || undefined,
      logoUrl: shop?.logoUrl || '',
      workingHours: useDefaultTimeTable(shop),
    },
    validationSchema: shopFormValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, validateForm, isValid, errors } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <ThemeProvider theme={modalTheme}>
      <TimeTableDialog
        show={showTimeTable}
        values={values as Required<ICreateShopFormState>}
        setFieldValue={setFieldValue}
        handleClose={setShowTimeTable.bind(null, false)}
      />
      <Box sx={modalContentContainerStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          {/*TODO: refactoring*/}
          <Badge
            badgeContent={
              <Checkbox
                sx={{
                  color: grey[900],
                  '&.Mui-checked': {
                    color: grey[700],
                  },
                }}
                icon={<FavoriteBorder />}
                checkedIcon={<Favorite />}
                checked={values.isFavorite}
                onChange={(e) => setFieldValue('isFavorite', e.target.checked)}
              />
            }
          >
            <input
              style={{ display: 'none' }}
              accept="image/*"
              id="icon-button-file"
              type="file"
              onChange={(event) => {
                if (event.currentTarget.files) {
                  setFieldValue('logo', event.currentTarget.files[0]);
                }
              }}
            />

            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="upload logo"
                component="span"
                sx={{ border: '2px solid rgba(0, 0, 0, 0.4)', marginBottom: '20px' }}
              >
                <Avatar
                  src={
                    (values.logo && URL.createObjectURL(values.logo as unknown as Blob)) ||
                    values.logoUrl ||
                    '/PhotoAvatar.png'
                  }
                  sx={{
                    width: (theme) => theme.spacing(15),
                    height: (theme) => theme.spacing(15),
                  }}
                />
              </IconButton>
            </label>
          </Badge>
          <div style={{ marginBottom: '20px' }}>
            <Button
              variant="outlined"
              endIcon={<CalendarMonthIcon />}
              onClick={setShowTimeTable.bind(null, true)}
            >
              Working hours
            </Button>
          </div>
          <DefaultInput
            defaultValue={values.name}
            id="shop-name-input"
            name={'name'}
            error={!!errors.name ?? false}
            helperText={errors?.name}
            label={'Shop name'}
            onChange={handleChange}
          />
          <CategoryAutocomplete
            multiple={true}
            id="tags-outlined"
            sx={{ margin: '15px 0' }}
            defaultValue={shop?.categories || undefined}
            options={categories || []}
            getOptionLabel={(category) => category?.names?.EN}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => setFieldValue('categories', value)}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.names.EN}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Categories"
                error={!!errors.categories ?? false}
                helperText={errors?.categories}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          <Box sx={modalButtonsSectionStyle}>
            <Button size="large" variant="text" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" size="large" variant="contained" disabled={!isValid}>
              {submitButtonText}
            </Button>
          </Box>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default ShopModal;
