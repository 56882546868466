import React from 'react';

import { Snackbar, Alert, AlertColor } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectNotification } from '@selectors/appSelectors';
import useBindDispatch from '@hooks/useBindDispatch';
import { closeNotificationAction } from '@actions/app/notification';

const Notification = () => {
  const notification = useSelector(selectNotification);
  const { isVisible, type, message } = notification;
  const handleClose = useBindDispatch(closeNotificationAction);
  return (
    <Snackbar
      open={isVisible}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={type as AlertColor}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
