import * as types from '@actions/actionTypes';
import { IShopRecord } from '@reducers/types';
import {
  ICreateShopFormState,
  ICreateStoreFormState,
  IUpdateShopFormState,
} from '@components/Modal/types';

export const fetchShopsAction = (payload: any) => ({
  type: types.FETCH_SHOPS,
  payload,
});

export const saveShopsAction = (payload: IShopRecord[]) => ({
  type: types.SET_SHOPS,
  payload,
});

export const updateShopAction = (payload: IUpdateShopFormState) => ({
  type: types.UPDATE_SHOP,
  payload,
});

export const createShopAction = (payload: ICreateShopFormState) => ({
  type: types.CREATE_SHOP,
  payload,
});

export const deleteShopAction = (payload: string) => ({
  type: types.DELETE_SHOP,
  payload,
});

export const createStoreAction = (payload: ICreateStoreFormState) => ({
  type: types.CREATE_STORE,
  payload,
});

export const editStoreAction = (payload: ICreateStoreFormState) => ({
  type: types.UPDATE_STORE,
  payload,
});

export const deleteStoreAction = (payload: string) => ({
  type: types.DELETE_STORE,
  payload,
});

export const setShopsLoadingAction = (payload: boolean) => ({
  type: types.SET_SHOPS_LOADING_STATE,
  payload,
});

export const fetchShopAction = (payload: string) => ({
  type: types.FETCH_SHOP,
  payload,
});

export const setSelectedShopAction = (payload: IShopRecord) => ({
  type: types.SET_SELECTED_SHOP,
  payload,
});
