import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement,
} from 'chart.js';

import { Line, Pie } from 'react-chartjs-2';

import { LINE_CHART_OPTIONS } from '@constants/constants';
import { Box, Typography } from '@mui/material';
import MetaBarChart from '@components/Charts/MetaBarChart/MetaBarChart';
import { useSelector } from 'react-redux';
import { selectUsageStatistic, selectUsageStatisticIsLoading } from '@selectors/statisticSelectors';
import LoadingChartWrapper from '@components/Charts/LoadingChartWrapper/LoadingChartWrapper';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

const UsageTab = () => {
  const usageStatisticData = useSelector(selectUsageStatistic);
  const isLoading = useSelector(selectUsageStatisticIsLoading);

  const { totalPerPlatform = [], firstOpen, conversion } = usageStatisticData || {};
  const {
    pageViews = [],
    averageSessionDuration = [],
    sessions = [],
    newVisitors = [],
  } = conversion || {};

  const firstOpenChartData = {
    labels: firstOpen?.iOS.map((record) => record.day),
    datasets: [
      {
        fill: true,
        label: 'iOS',
        data: firstOpen?.iOS.map((record) => record.amount),
        borderColor: 'rgba(53, 162, 235, 0.8)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        fill: true,
        label: 'Android',
        data: firstOpen?.Android.map((record) => record.amount),
        borderColor: 'rgba(254, 67, 60, 0.8)',
        backgroundColor: 'rgba(254, 67, 60, 0.5)',
      },
    ],
  };

  const totalDevicesChartData = {
    labels: totalPerPlatform?.map((record) => record.os),
    datasets: [
      {
        fill: true,
        label: 'Users',
        data: totalPerPlatform?.map((record) => record.amount),
        backgroundColor: ['rgba(53, 162, 235, 0.4)', 'rgba(254, 67, 60, 0.4)'],
        borderColor: ['rgba(53, 162, 235, 0.8)', 'rgba(254, 67, 60, 0.8)'],
      },
    ],
  };

  const sessionsTotal = sessions.reduce((acc, item) => (acc += item.amount), 0);
  const pageViewsTotal = pageViews.reduce((acc, item) => (acc += item.amount), 0);
  const pagesToSessions = +(pageViewsTotal / sessionsTotal).toFixed(2) || 0;
  const averageSessionDurationStat =
    +(
      averageSessionDuration.reduce((acc, item) => (acc += item.amount), 0) /
      averageSessionDuration.length
    ).toFixed(2) || 0;

  return (
    <Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography component={'h2'} variant={'h6'}>
          {' '}
          Application usage per device statistic{' '}
        </Typography>
        <div style={{ display: 'flex', height: '30vh', margin: '0 0' }}>
          <LoadingChartWrapper
            isLoading={isLoading}
            style={{ width: '40vw', display: 'flex', justifyContent: 'center', height: '18vw' }}
          >
            <Line options={LINE_CHART_OPTIONS} data={firstOpenChartData} />
          </LoadingChartWrapper>
          <LoadingChartWrapper
            isLoading={isLoading}
            style={{
              width: '40vw',
              height: '20vw',
              maxHeight: '30vh',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pie data={totalDevicesChartData} options={{ responsive: true }} />
          </LoadingChartWrapper>
        </div>
      </Box>

      <Box sx={{ textAlign: 'center', paddingTop: '25px' }}>
        <Typography component={'h2'} variant={'h6'}>
          {' '}
          Conversion statistic{' '}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            margin: '0 0',
            justifyContent: 'center',
            paddingTop: '25px',
            gap: '10px',
          }}
        >
          <MetaBarChart
            title={'Sessions'}
            subtitle={`Total: ${sessionsTotal}`}
            labels={Array(sessions.length).fill('')}
            data={sessions.map((session) => session.amount)}
            isLoading={isLoading}
          />
          <MetaBarChart
            title={'New visitors'}
            subtitle={`Total: ${newVisitors.reduce((acc, item) => (acc += item.amount), 0)}`}
            labels={Array(newVisitors.length).fill('')}
            data={newVisitors.map((visitors) => visitors.amount)}
            isLoading={isLoading}
          />
          <MetaBarChart
            title={'Page views'}
            subtitle={`Total: ${pageViewsTotal}`}
            labels={Array(pageViews.length).fill('')}
            data={pageViews.map((views) => views.amount)}
            isLoading={isLoading}
          />
          <MetaBarChart
            title={'Pages / Session'}
            subtitle={`${pagesToSessions}`}
            labels={Array(pageViews.length).fill('')}
            data={pageViews?.map((record, index) => record.amount / pageViews[index].amount || 0)}
            isLoading={isLoading}
          />
          <MetaBarChart
            title={'Avg. session duration'}
            subtitle={`${averageSessionDurationStat} min`}
            labels={Array(averageSessionDuration.length).fill('')}
            data={averageSessionDuration.map((avDuration) => avDuration.amount)}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UsageTab;
