import React from 'react';
import styles from '@pages/Products/Products.module.scss';
import SearchInput from '@components/SearchInput/SearchInput';
import CardTile from '@components/CardTile/CardTile';
import ShopCard from '@components/CardItems/Shop/ShopCard';
import { useSelector } from 'react-redux';
import { selectShops, selectShopsLoadingState } from '@selectors/shopsSelectors';
import useBindDispatch from '@hooks/useBindDispatch';
import { MODAL_TYPES } from '@components/Modal/types';
import { showModalAction } from '@actions/app/modal';
import Pagination from '@components/Pagination/Pagination';
import AddItemButton from '@components/Buttons/AddItemButton/AddItemButton';

const Shops = () => {
  const { records, pagesTotal } = useSelector(selectShops);
  const isLoading = useSelector(selectShopsLoadingState);
  const showModal = useBindDispatch(showModalAction);
  const handleShowAddShopModal = () => {
    showModal({ type: MODAL_TYPES.CREATE_SHOP });
  };

  return (
    <>
      <div className={styles.header}>
        <h1>Shops</h1>
        <SearchInput />
      </div>
      <AddItemButton title={'Add new shop'} onClick={handleShowAddShopModal} />
      <Pagination pagesTotal={pagesTotal} />
      <CardTile Card={ShopCard} items={records} isLoading={isLoading} />
    </>
  );
};

export default Shops;
