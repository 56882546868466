import React, { useState } from 'react';
import { Box, MenuItem, Select, Button, FormControl, InputLabel } from '@mui/material';
import TemplateCreationDialog from '@components/Modal/ModalComponents/Import/ImportTemplate/TemplateCreationDialog/TemplateCreationDialog';
import useBindDispatch from '@hooks/useBindDispatch';
import { setMappedFieldsAction } from '@actions/import';
import { useSelector } from 'react-redux';
import { selectTemplates } from '@selectors/importSelectors';
import { ImportTemplateType } from '@reducers/types';

const ImportTemplate = ({
  valid,
  handleCreateTemplate,
}: {
  valid: boolean;
  handleCreateTemplate: (name: string) => void;
}) => {
  const [template, setTemplate] = useState<ImportTemplateType | null>(null);
  const [isCreationDialogShow, setIsCreationDialogShow] = useState(false);
  const setMappedFields = useBindDispatch(setMappedFieldsAction);
  const templates = useSelector(selectTemplates);
  const handleChange = (e: any) => {
    setTemplate(() => {
      const newTemplate = templates.find((template) => template?.id === e.target.value);
      if (newTemplate) {
        setMappedFields(newTemplate.mappedFields);
        return newTemplate;
      } else return null;
    });
  };

  const handleOpenDialog = () => setIsCreationDialogShow(true);
  const handleCloseDialog = () => setIsCreationDialogShow(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'flex-start' }}>
      <TemplateCreationDialog
        show={isCreationDialogShow}
        handleClose={handleCloseDialog}
        handleCreateTemplate={handleCreateTemplate}
      />
      <Button
        size="large"
        variant="contained"
        sx={{ marginBottom: '20px' }}
        onClick={handleOpenDialog}
        disabled={!valid}
      >
        Create template
      </Button>
      <FormControl fullWidth sx={{ marginBottom: '10px' }}>
        <InputLabel id="select-template" shrink>
          Select template
        </InputLabel>
        <Select
          id={'templates'}
          labelId="select-template-label"
          name="templateSelect"
          value={template?.id || ''}
          onChange={handleChange}
          sx={{ width: '100%' }}
        >
          {templates.map((template) => (
            <MenuItem key={template.id} value={template.id} id={template.name}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ImportTemplate;
