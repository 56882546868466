import { AppBar, Box, Tab, Tabs, ThemeProvider, useTheme } from '@mui/material';
import React from 'react';
import { ISwipeableTabs, TabPanelProps } from '@components/types';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { TabsSliderTheme } from '@src/themes';

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return <>{value === index && <Box sx={{ paddingTop: '20px' }}>{children}</Box>}</>;
}

const SwipeableTabs = ({ labels, children }: ISwipeableTabs) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  const theme = useTheme();

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <ThemeProvider theme={TabsSliderTheme}>
      <AppBar position="static" color="primary" style={{ width: 'fit-content' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
        >
          {labels.map((label, idx) => (
            <Tab key={idx} label={label} {...a11yProps(idx)} />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        style={{ width: '100%', height: '80vh', overflowY: 'auto' }}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {children.map((child, idx) => (
          <TabPanel key={idx} value={value} index={idx} dir={theme.direction}>
            {child}
          </TabPanel>
        ))}
      </SwipeableViews>
    </ThemeProvider>
  );
};

export default SwipeableTabs;
