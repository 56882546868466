import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { ImageList, ImageListItem } from '@mui/material';
import { ImageListCarouselProps } from '@components/types';

const ImageListCarousel = ({ imagesPerView, images }: ImageListCarouselProps) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = Math.ceil(images.length / imagesPerView);
  const imageGroups = images.reduce((acc: Array<string>[], img, idx, array) => {
    if (!((idx + 1) % imagesPerView)) {
      acc.push(array.slice(idx, idx + imagesPerView));
    }

    return acc;
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 1200, flexGrow: 1 }}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {imageGroups.map((group, index) => (
          <div key={index}>
            <ImageList cols={imagesPerView % 2 === 0 ? 2 : 1}>
              {group.map((image, idx) => (
                <ImageListItem key={idx}>
                  <img height={'auto'} src={`${image}`} srcSet={`${image}`} loading="lazy" />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        ))}
      </SwipeableViews>
      <MobileStepper
        variant="progress"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </Box>
  );
};

export default ImageListCarousel;
