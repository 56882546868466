import { InitialPapersStateType, ReducerAction } from '@reducers/types';
import { SET_PAPER_IS_LOADING, SET_PAPERS } from '@actions/actionTypes';

const initialState: InitialPapersStateType = {
  records: [],
  pagesTotal: 1,
  isLoading: false,
};

const papersReducer = (
  state: InitialPapersStateType = initialState,
  { type, payload }: ReducerAction,
): InitialPapersStateType => {
  switch (type) {
    case SET_PAPERS: {
      const { data, pagesTotal } = payload;
      return { ...state, records: data, pagesTotal };
    }
    case SET_PAPER_IS_LOADING: {
      return { ...state, isLoading: payload };
    }
  }

  return state;
};

export default papersReducer;
