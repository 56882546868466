import * as types from '@actions/actionTypes';
import { IGetWithPagination, IProductRecord } from '@reducers/types';
import { ICreateProductFormState } from '@components/Modal/types';

export const fetchProductsAction = (payload: any) => ({
  type: types.FETCH_PRODUCTS,
  payload,
});

export const saveProductsAction = (payload: IGetWithPagination<IProductRecord>) => ({
  type: types.SET_PRODUCTS,
  payload,
});

export const createProductAction = (payload: ICreateProductFormState) => ({
  type: types.CREATE_PRODUCT,
  payload,
});

export const deleteProductAction = (payload: string) => ({
  type: types.DELETE_PRODUCT,
  payload,
});

export const updateProductAction = (payload: ICreateProductFormState) => ({
  type: types.UPDATE_PRODUCT,
  payload,
});

export const setProductsLoadingState = (payload: boolean) => ({
  type: types.SET_PRODUCTS_LOADING_STATE,
  payload,
});
