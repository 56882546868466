import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { API } from '@src/requests';
import * as types from '@actions/actionTypes';
import { loginAction, setIsLoadingAction } from '@actions/app';
import { clearLocalStorage, saveLocalStorage } from '@utils/localstorage';
import { AUTH_TOKEN_KEY, USER_DATA } from '@src/constants/constants';
import { findAddressAction, setAddressesAction } from '@actions/app/autocomplete';
import { goTo } from '@src/routeHistory';
import { selectLocation } from '@selectors/appSelectors';
import { ROUTE } from '@router/routes';
import { fetchCategoriesAction } from '@actions/categories';
import { fetchProductsAction } from '@actions/products';
import { fetchShopsAction } from '@actions/shops';
import qs from 'query-string';
import { selectSearchQuery } from '@selectors/routerSelectors';
import { fetchPapersAction } from '@actions/papers';
import { fetchDocumentsForImportAction, fetchTemplatesAction } from '@actions/import';

function* login({ payload }: ReturnType<typeof loginAction>) {
  try {
    yield put(setIsLoadingAction(true));
    const {
      data: { userData, access_token },
      status,
    } = yield call(API.User.login, payload);

    saveLocalStorage(AUTH_TOKEN_KEY, access_token);
    if (status === 201) {
      saveLocalStorage(USER_DATA, JSON.stringify(userData));
      window.location.replace('/');
    }
  } catch (e) {
    console.error(e);
  } finally {
    setIsLoadingAction(false);
  }
}

function* searchAddressEffect({ payload }: ReturnType<typeof findAddressAction>) {
  const response: Record<string, any> = yield call(API.Autocomplete.search, payload);
  const { predictions = [] } = response || {};
  const addresses: string[] = predictions.map(
    (prediction: Record<string, any>) => prediction.description,
  );
  yield put(setAddressesAction(addresses));
}

function* logoutEffect() {
  yield call(clearLocalStorage);
  goTo('login');
}

function* handleLocationEffect() {
  const location: Record<any, any> = yield select(selectLocation);
  const { pathname = null } = location;
  const search: string = yield select(selectSearchQuery);
  const { limit, page } = qs.parse(search) || {};

  switch (pathname) {
    case ROUTE.CATEGORIES: {
      if (!limit || !page) return;
      const { name = '' } = qs.parse(search) || {};
      yield put(fetchCategoriesAction({ name, page, limit }));

      break;
    }
    case ROUTE.IMPORT: {
      yield put(fetchTemplatesAction());
      yield put(fetchDocumentsForImportAction());

      break;
    }
    case ROUTE.PRODUCTS: {
      if (!limit || !page) return;
      const { name = '', shopId = '', categoryId = '' } = qs.parse(search) || {};

      yield put(fetchShopsAction({}));
      yield put(fetchProductsAction({ limit, page, name, shopId, categoryId }));
      break;
    }
    case ROUTE.PAPERS: {
      if (!limit || !page) return;
      const { name = '', shopId = '', categoryId = '' } = qs.parse(search) || {};

      yield put(fetchShopsAction({}));
      yield put(fetchPapersAction({ limit, page, name, shopId, categoryId }));

      break;
    }
    case ROUTE.SHOPS: {
      if (!limit || !page) return;
      const { name = '' } = qs.parse(search) || {};

      yield put(fetchCategoriesAction({}));
      yield put(fetchShopsAction({ limit, page, name }));

      break;
    }

    default:
      break;
  }
}

export function* appSagas() {
  yield takeEvery(types.LOGIN, login);
  yield takeEvery(types.LOGOUT, logoutEffect);
  yield takeEvery(types.SEARCH_ADDRESS, searchAddressEffect);
  yield takeLatest(types.LOCATION_CHANGE, handleLocationEffect);
}
