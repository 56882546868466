export enum NotificationTypes {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum AcceptedImportFormats {
  CSV = 'text/csv',
  MS_EXCEL = 'application/vnd.ms-excel',
  OPEN_EXEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}
