import { Container, Box, ThemeProvider } from '@mui/material';
import Menu from '@components/Menu';
import React from 'react';
import { LayoutTheme, ViewTheme } from '@src/themes';
import { ILayout } from '@components/types';

const Layout = ({ view }: ILayout) => {
  return (
    <ThemeProvider theme={LayoutTheme}>
      <Container>
        <Menu />
        <ThemeProvider theme={ViewTheme}>
          <Box component="main" id={'viewport'}>
            {view}
          </Box>
        </ThemeProvider>
      </Container>
    </ThemeProvider>
  );
};

export default Layout;
