import React, { useEffect, useState } from 'react';
import { modalTheme } from '@src/themes';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  modalButtonsSectionStyle,
  modalContentContainerStyle,
} from '@components/Modal/ModalContainer';

import {
  Box,
  Typography,
  ThemeProvider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  IconButton,
  Avatar,
  Button,
  SelectChangeEvent,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ICreatePaperFormState, IPaperModal } from '@components/Modal/types';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import TranslationInput from '@components/Form/Inputs/TranslationInput/TranslationInput';
import { TranslationForm } from '@components/types';
import { ICategoryRecord, IShopRecord } from '@reducers/types';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ImagesInput from '@components/Modal/ModalComponents/Paper/ImagesInput/ImagesInput';
import { blobFromImage } from '@src/utils';
import DefaultInput from '@components/Form/Inputs/DefaultInput/DefaultInput';
import { paperValidationSchema } from '@components/Modal/ModalComponents/FormValidationSchemas';

const CategoryAutocomplete = Autocomplete<ICategoryRecord, true>;

const PaperModal = ({
  title,
  paper,
  handleSubmit,
  shops,
  closeModal,
  submitButtonText,
}: IPaperModal) => {
  const [selectedShop, setSelectedShop] = useState<IShopRecord>();
  const formik = useFormik<ICreatePaperFormState>({
    initialValues: {
      id: paper?.id || '',
      externalId: paper?.externalId || '',
      shop: shops?.find((shop) => shop.id === paper?.shopId) || shops?.at(0),
      categories: paper?.categories || [],
      logo: null,
      logoUrl: paper?.logoUrl || '',
      images: [],
      imagesUrls: paper?.imagesUrls || [],
      translation: paper?.names || {
        EN: '',
        DE: '',
        FR: '',
        IT: '',
        BP: '',
      },
      promotionEndDate: moment(paper?.endDate, 'YYYY-MM-DD') || moment(),
    },
    validationSchema: paperValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, validateForm, isValid, errors } = formik;

  useEffect(() => {
    setSelectedShop(shops?.find((shop) => shop.id === values.shop?.id));
    if (paper?.shop.id !== values.shop?.id) {
      setFieldValue('categories', []);
    } else {
      setFieldValue('categories', paper?.categories);
    }
  }, [values.shop]);

  const handleChangeShop = (e?: SelectChangeEvent<string>) => {
    const shop = shops?.find((shop) => shop.id === e?.target.value);
    setFieldValue('shop', shop);
  };

  const handleAddPics = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const existingImagesNames = values.images.map((image) => image.name);
      const newImages = Array.from(event.currentTarget.files).filter(
        (image) => !existingImagesNames.includes(image.name),
      );
      setFieldValue('images', [...values.images, ...newImages]);
      setFieldValue('imagesUrls', [
        ...newImages.map((file) => blobFromImage(file)),
        ...values.imagesUrls,
      ]);
    }
  };
  const handleDeleteImage = (e: any, idx: number) => {
    setFieldValue('images', [...values.images.filter((_, index) => index !== idx)]);
    setFieldValue('imagesUrls', [...values.imagesUrls.filter((_, index) => index !== idx)]);
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={modalContentContainerStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel shrink required id="select-shop-input-label">
                Shop name
              </InputLabel>
              <Select
                required
                labelId="select-shop-input"
                value={values.shop?.id}
                name="shop"
                onChange={handleChangeShop}
                label="Shop"
              >
                {shops?.map((shop) => (
                  <MenuItem value={shop.id} id={shop.name} key={shop.id}>
                    {shop.name}
                  </MenuItem>
                ))}
              </Select>

              <input
                style={{ display: 'none' }}
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={(event) => {
                  if (event.currentTarget.files) {
                    setFieldValue('logo', event.currentTarget.files[0]);
                  }
                }}
              />

              <label htmlFor="icon-button-file" style={{ margin: '10px 0' }}>
                <IconButton
                  color="primary"
                  aria-label="upload logo"
                  component="span"
                  sx={{ border: '2px solid rgba(0, 0, 0, 0.4)' }}
                >
                  <Avatar
                    src={
                      values.logo
                        ? URL.createObjectURL(values?.logo as Blob)
                        : values.logoUrl || '/PhotoAvatar.png'
                    }
                    sx={{
                      width: (theme) => theme.spacing(15),
                      height: (theme) => theme.spacing(15),
                    }}
                  />
                </IconButton>
              </label>

              <DefaultInput
                defaultValue={values.externalId}
                id="paper-ext-id-input"
                name={'externalId'}
                label={'Paper ID'}
                onChange={handleChange}
              />
              <TranslationInput
                required={false}
                defaultValue={values.translation.EN}
                onChange={handleChange}
                label={'Paper name EN'}
                error={!!errors.translation}
                helperText={String(errors.translation)}
                language={TranslationForm.EN}
              />
              <TranslationInput
                required={false}
                defaultValue={values.translation.DE}
                error={!!errors.translation}
                helperText={String(errors.translation)}
                onChange={handleChange}
                label={'Paper name DE'}
                language={TranslationForm.DE}
              />
              <TranslationInput
                required={false}
                defaultValue={values.translation.FR}
                error={!!errors.translation}
                helperText={String(errors.translation)}
                onChange={handleChange}
                label={'Paper name FR'}
                language={TranslationForm.FR}
              />
              <TranslationInput
                required={false}
                defaultValue={values.translation.IT}
                onChange={handleChange}
                error={!!errors.translation}
                helperText={String(errors.translation)}
                label={'Paper name IT'}
                language={TranslationForm.IT}
              />
              <TranslationInput
                required={false}
                defaultValue={values.translation.BP}
                onChange={handleChange}
                error={!!errors.translation}
                helperText={String(errors.translation)}
                label={'Paper name PT'}
                language={TranslationForm.BP}
              />


              <CategoryAutocomplete
                multiple={true}
                id="tags-outlined"
                sx={{ margin: '15px 0' }}
                options={selectedShop?.categories || []}
                value={values.categories}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(category) => category?.names?.EN}
                filterSelectedOptions
                onChange={(e, value) => setFieldValue('categories', value)}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.names.EN}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.categories}
                    helperText={String(errors.categories)}
                    label="Categories"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />

              <DesktopDatePicker
                inputFormat="DD.MM.YYYY"
                value={values.promotionEndDate}
                onChange={(value) => {
                  setFieldValue('promotionEndDate', value);
                }}
                label={'Promotion end date'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!!errors.promotionEndDate}
                    helperText={!!errors.promotionEndDate && 'Promotion end date is required'}
                    name={'promotionEndDate'}
                    sx={{ width: '100%', margin: '10px 0' }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />

              <ImagesInput
                images={values.imagesUrls}
                handleChange={handleAddPics}
                handleDeleteImage={handleDeleteImage}
              />
            </FormControl>
            <Box sx={modalButtonsSectionStyle}>
              <Button size="large" variant="text" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" size="large" variant="contained" disabled={!isValid}>
                {submitButtonText}
              </Button>
            </Box>
          </form>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default PaperModal;
