import { createSelector } from 'reselect';

import { IAppState } from '@store/model';

export const selectApp = (state: IAppState) => state.app;
export const selectMenu = (state: IAppState) => state.app.menu;
export const selectRouter = (state: IAppState) => state.router;
export const selectIsLoading = createSelector(selectApp, (app) => app.isLoading);

export const selectIsOpened = createSelector(selectMenu, (menu) => menu.opened);

export const selectModal = createSelector(selectApp, (app) => app.modal);

export const selectNotification = createSelector(selectApp, (app) => app.notification);

export const selectAddressesAutocomplete = createSelector(
  selectApp,
  (app) => app.autocomplete.addresses,
);

export const selectLocation = createSelector(selectRouter, (router) => router.location);
