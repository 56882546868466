import { ImportStateType, ReducerAction, ImportStep } from '@reducers/types';
import {
  CLEAR_IMPORT,
  SET_IMPORT_IS_LOADING,
  SET_IMPORT,
  SET_IMPORT_STEP,
  SET_IMPORT_TYPE,
  SET_IMPORT_MAPPED_FIELDS,
  SET_IMPORT_ID,
  SET_SELECTED_BAKED_RECORDS,
  SET_IMPORT_TEMPLATES,
  SET_DOCUMENTS_FOR_IMPORT,
} from '@actions/actionTypes';

export const initialImportState: ImportStateType = {
  id: '',
  isLoading: false,
  mappedFields: {},
  baked: [],
  status: ImportStep.UPLOAD,
  fields: [],
  type: null,
  templates: [],
  documents: [],
};

const importReducer = (
  state: ImportStateType = initialImportState,
  { type, payload }: ReducerAction,
): ImportStateType => {
  switch (type) {
    case SET_IMPORT: {
      return { ...state, ...payload };
    }
    case SET_IMPORT_ID: {
      return { ...state, id: payload };
    }
    case SET_IMPORT_TYPE: {
      return { ...state, type: payload };
    }
    case SET_IMPORT_STEP: {
      return { ...state, status: payload };
    }
    case SET_IMPORT_MAPPED_FIELDS: {
      return { ...state, mappedFields: payload };
    }
    case SET_IMPORT_IS_LOADING: {
      return { ...state, isLoading: payload };
    }
    case SET_SELECTED_BAKED_RECORDS: {
      const updatedSelection = state.baked.map((record) => ({
        ...record,
        selected: payload.includes(record.id),
      }));

      return { ...state, baked: updatedSelection };
    }
    case CLEAR_IMPORT: {
      return initialImportState;
    }
    case SET_IMPORT_TEMPLATES: {
      return { ...state, templates: payload };
    }
    case SET_DOCUMENTS_FOR_IMPORT: {
      return { ...state, documents: payload };
    }
  }

  return state;
};

export default importReducer;
