import { IMetaBarChart } from '@components/types';

import { Line } from 'react-chartjs-2';
import { META_BAR_CHART_OPTIONS } from '@constants/constants';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Typography } from '@mui/material';
import LoadingChartWrapper from '@components/Charts/LoadingChartWrapper/LoadingChartWrapper';
ChartJS.register(CategoryScale, LinearScale, Title, Tooltip, Legend, ArcElement);

const MetaBarChart = ({ title, subtitle, labels, data, isLoading }: IMetaBarChart) => {
  const chartData = {
    labels,
    datasets: [
      {
        fill: true,
        data,
        backgroundColor: ['rgba(53, 162, 235, 0.4)'],
        borderColor: ['rgba(53, 162, 235, 0.8)'],
      },
    ],
  };

  return (
    <div style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
        <Typography component={'span'} variant={'caption'}>
          {' '}
          {title}{' '}
        </Typography>
        <Typography component={'span'} variant={'subtitle1'}>
          {subtitle}
        </Typography>
      </div>
      <LoadingChartWrapper
        isLoading={isLoading}
        style={{ height: '8vh', width: '10vw' }}
        loaderOffsetTop={'0px'}
        loaderOffsetLeft={'20%'}
      >
        <Line options={META_BAR_CHART_OPTIONS} data={chartData} />
      </LoadingChartWrapper>
    </div>
  );
};

export default MetaBarChart;
