import * as React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useEffect, useState } from 'react';
import useDebounce from '@hooks/useDebounce';
import { useSearchParams } from 'react-router-dom';

const SearchInput = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    searchParams.get('name') || '',
  );
  const debouncedSearch = useDebounce(searchQuery as string);

  useEffect(() => {
    const searchParamsName = searchParams.get('name');
    if (searchQuery) {
      searchParams.set('name', searchQuery);
      setSearchParams(searchParams);
      console.log('location 111');
    } else if (searchParamsName) {
      searchParams.delete('name');
      setSearchParams(searchParams);
      console.log('location 222');
    }
  }, [debouncedSearch]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const name = e.target.value;
    setSearchQuery(name);
  };

  return (
    <TextField
      defaultValue={searchQuery}
      id="outlined-basic"
      label="Search"
      variant="outlined"
      onChange={handleSearch}
      sx={{
        borderRadius: 100,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
