import axios, { AxiosRequestConfig } from 'axios';

import { clearLocalStorage, getLocalStorage } from '@utils/localstorage';
import { AUTH_TOKEN_KEY, CONTENT_TYPE_JSON } from '@constants/constants';
import { ICategoryRecord, ImportDocType, IShopRecord } from '@reducers/types';
import { CreateTemplatePayloadType, ICategoryResponsePagination } from '@requests/types';
import { goTo } from '@src/routeHistory';
import { ICreateStoreFormState } from '@components/Modal/types';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  withCredentials: process.env.REACT_APP_ENV !== 'development',
});

instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${getLocalStorage(AUTH_TOKEN_KEY) || ''}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      clearLocalStorage();
      goTo('login');
    }

    return Promise.reject(error);
  },
);

export const API = {
  User: {
    login: (payload: object) =>
      instance
        .post('auth/login', payload, {
          headers: { ...CONTENT_TYPE_JSON },
        })
        .then((res) => ({ data: res.data, status: res.status }))
        .catch((e) => {
          throw e;
        }),

    getProfile: () =>
      instance
        .get('/user')
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
  },
  Categories: {
    fetch: (payload: object) =>
      instance
        .get<ICategoryResponsePagination>('api/admin/categories', { params: payload })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    create: (payload: object) =>
      instance
        .post<ICategoryRecord>('api/admin/categories', payload)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    update: (payload: ICategoryRecord) =>
      instance
        .patch<ICategoryRecord>(`api/admin/categories/${payload.id}`, payload)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    delete: (payload: ICategoryRecord) =>
      instance
        .delete<ICategoryRecord>(`api/admin/categories/${payload.id}`)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
  },
  Products: {
    fetch: (payload: object) => {
      return instance
        .get<{ data: []; pagesTotal: number }>('api/admin/product', { params: payload })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        });
    },
    fetchOne: (id: string) => {
      return instance
        .get<{ data: []; pagesTotal: number }>(`api/admin/product/${id}`)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        });
    },
    create: (payload: object) =>
      instance
        .post('api/admin/product', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    delete: (id: string) =>
      instance
        .delete(`api/admin/product/${id}`)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    update: (id: string, payload: object) =>
      instance
        .patch(`api/admin/product/${id}`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
  },
  Documents: {
    upload: (payload: File) =>
      instance
        .post(`api/admin/document`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    update: (payload: object, id: string, type: ImportDocType) =>
      instance
        .patch(`api/admin/document/${id}`, payload, {
          params: { type },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    bakeImport: (id: string, type: ImportDocType) =>
      instance
        .post(
          `api/admin/document/bake/${id}`,
          {},
          {
            params: { type },
          },
        )
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    updateBakeSelection: (id: string, type: ImportDocType, selected: string[]) =>
      instance
        .patch(
          `api/admin/document/bake/${id}`,
          { selected },
          {
            params: { type },
          },
        )
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),

    finishImport: (id: string, type: ImportDocType) =>
      instance
        .post(
          `api/admin/document/finish/${id}`,
          {},
          {
            params: { type },
          },
        )
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    restoreImportDraft: (id: string, type: ImportDocType) =>
      instance
        .get(`api/admin/document/restore/${id}`, {
          params: { type },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    createTemplate: (payload: CreateTemplatePayloadType) =>
      instance
        .post(`api/admin/document/template`, {
          ...payload,
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    getTemplates: (type?: ImportDocType) =>
      instance
        .get(`api/admin/document/template`, {
          params: { type },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    getDocuments: (type?: ImportDocType) =>
      instance
        .get(`api/admin/document`, {
          params: { type },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    deleteDocument: (ids: string, type: ImportDocType) =>
      instance
        .post(`api/admin/document/delete`, {
          ids,
          type,
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    deleteTemplate: (ids: string, type: ImportDocType) =>
      instance
        .post(`api/admin/document/template/delete`, {
          ids,
          type,
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
  },
  Papers: {
    fetch: (payload: object) =>
      instance
        .get('api/admin/paper', { params: payload })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    create: (payload: object) =>
      instance
        .post('api/admin/paper', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    delete: (id: string) =>
      instance
        .delete(`api/admin/paper/${id}`)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    update: (id: string, payload: object) =>
      instance
        .patch(`api/admin/paper/${id}`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
  },
  Shops: {
    fetch: (payload: object) =>
      instance
        .get<IShopRecord[]>('api/admin/shops', { params: payload })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    findOne: (id: string) =>
      instance
        .get<IShopRecord[]>(`api/admin/shops/${id}`)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    update: (id: string, payload: IShopRecord) =>
      instance
        .patch(`api/admin/shops/${id}`, payload)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    create: (payload: IShopRecord) =>
      instance
        .post<any>(`api/admin/shops`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    delete: (id: string) =>
      instance
        .delete<any>(`api/admin/shops/${id}`)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
  },
  Stores: {
    create: (payload: ICreateStoreFormState) =>
      instance
        .post<any>(`api/admin/store`, payload)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    delete: (id: string) =>
      instance
        .delete<any>(`api/admin/store/${id}`)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    update: (id: string, payload: ICreateStoreFormState) =>
      instance
        .patch<any>(`api/admin/store/${id}`, payload)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
  },
  Statistics: {
    getFavoriteShopsStatistic: (payload: { from: string; till: string }) =>
      instance
        .get<any>(`api/admin/statistic/favorite/shops`, {
          params: {
            ...payload,
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),

    getFavoriteCategoriesStatistic: (payload: { from: string; till: string }) =>
      instance
        .get<any>(`api/admin/statistic/favorite/categories`, {
          params: {
            ...payload,
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),

    getMostViewedProducts: (payload: { from: string; till: string }) =>
      instance
        .get<any>(`api/admin/statistic/popular/products`, {
          params: {
            ...payload,
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    getPopularShopsSearch: (payload: { from: string; till: string }) =>
      instance
        .get<any>(`api/admin/statistic/search/shops`, {
          params: {
            ...payload,
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    getPopularSearchQuery: (payload: { from: string; till: string }) =>
      instance
        .get<any>(`api/admin/statistic/search/query`, {
          params: {
            ...payload,
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    getSearchLocations: (payload: { from: string; till: string }) =>
      instance
        .get<any>(`api/admin/statistic/search/locations`, {
          params: {
            ...payload,
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
    getUsageInfo: (payload: { from: string; till: string }) =>
      instance
        .get<any>(`api/admin/statistic/usage`, {
          params: {
            ...payload,
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
  },
  Autocomplete: {
    search: (input: string) =>
      instance
        .get('api/city/place/autocomplete/json', { params: { input } })
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        }),
  },
};
