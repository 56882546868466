import { call, put, takeLeading } from 'redux-saga/effects';
import * as types from '@actions/actionTypes';
import {
  fetchStatisticAction,
  setFavoriteCategoriesStatisticAction,
  setFavoriteCategoriesStatisticLoadingAction,
  setFavoriteShopsStatisticAction,
  setFavoriteShopsStatisticLoadingAction,
  setStatisticLoadingAction,
  setTopLocationsAction,
  setTopLocationsLoadingAction,
  setTopProductsAction,
  setTopProductsLoadingAction,
  setTopSearchQueriesAction,
  setTopSearchQueriesLoadingAction,
  setTopSelectedSearchShopsAction,
  setTopSelectedSearchShopsLoadingAction,
  setUsageStatisticAction,
  setUsageStatisticLoadingAction,
} from '@actions/statistic';
import { API } from '@src/requests';
import moment from 'moment';
import { UsageStatisticType } from '@reducers/types';
import { showNotificationAction } from '@actions/app/notification';
import { NotificationTypes } from '@constants/enums';

function* fetchEffect({ payload }: ReturnType<typeof fetchStatisticAction>) {
  try {
    yield put(setStatisticLoadingAction(true));
    yield put(
      showNotificationAction({
        message: 'Fetching statistics data',
        type: NotificationTypes.INFO,
      }),
    );

    const { from: _from, till: _till } = payload;
    const from = moment(_from).format('YYYYMMDD');
    const till = moment(_till).format('YYYYMMDD');

    yield put(setFavoriteShopsStatisticLoadingAction(true));
    yield put(setFavoriteCategoriesStatisticLoadingAction(true));
    yield put(setTopProductsLoadingAction(true));
    yield put(setTopSearchQueriesLoadingAction(true));
    yield put(setTopSelectedSearchShopsLoadingAction(true));
    yield put(setTopLocationsLoadingAction(true));
    yield put(setUsageStatisticLoadingAction(true));

    const favoriteShops: [] = yield call(API.Statistics.getFavoriteShopsStatistic, { from, till });
    yield put(setFavoriteShopsStatisticAction(favoriteShops));

    const favoriteCategories: [] = yield call(API.Statistics.getFavoriteCategoriesStatistic, {
      from,
      till,
    });
    yield put(setFavoriteCategoriesStatisticAction(favoriteCategories));

    const topProducts: [] = yield call(API.Statistics.getMostViewedProducts, { from, till });
    yield put(setTopProductsAction(topProducts));

    const topSelectedShops: [] = yield call(API.Statistics.getPopularShopsSearch, { from, till });
    yield put(setTopSelectedSearchShopsAction(topSelectedShops));

    const topSearchQueries: [] = yield call(API.Statistics.getPopularSearchQuery, { from, till });
    yield put(setTopSearchQueriesAction(topSearchQueries));

    const locations: [] = yield call(API.Statistics.getSearchLocations, { from, till });
    yield put(setTopLocationsAction(locations));

    const usage: UsageStatisticType = yield call(API.Statistics.getUsageInfo, { from, till });
    yield put(setUsageStatisticAction(usage));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setStatisticLoadingAction(false));
  }
}

export function* statisticSagas() {
  yield takeLeading(types.FETCH_STATISTIC, fetchEffect);
}
