import React, {useEffect, useState} from 'react';
import {
  Button,
  TableContainer,
  Table,
  TableBody,
  Paper,
  TableRow,
  TableCell,
  TextField,
  Box,
  PaperProps,
  Switch, TableHead,
} from '@mui/material';
import { Days, ITimeTable } from '@components/Modal/types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Moment } from 'moment/moment';
import { PickersDay } from '@mui/x-date-pickers';
import momentTimezone from 'moment-timezone';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const TimeTable = ({ values, setFieldValue }: ITimeTable) => {
  const days = Object.keys(values.workingHours).filter((day) => day !== 'holidays') as Days[];
  const [isHolidaysPickerOpen, setIsHolidaysPickerOpen] = useState<boolean>(false);
  const { moment } = new AdapterMoment({ instance: momentTimezone });
  moment.tz.setDefault('UTC');

  const handleCloseHolidayPicker = () => {
    setIsHolidaysPickerOpen(false);
  };

  const CustomContent = (props: PaperProps) => {
    return (
      <div style={{ display: 'inline-grid' }}>
        <IconButton
          aria-label="close"
          onClick={handleCloseHolidayPicker}
          sx={{
            marginLeft: 'auto',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {props.children}
      </div>
    );
  };

  const handleChangeHolidays = (value: Moment | null) => {
    if (
      !values.workingHours.holidays.find((holiday: Moment | string) =>
        (holiday as Moment).isSame(value, 'day'),
      )
    ) {
      setFieldValue('workingHours.holidays', [...values.workingHours.holidays, value]);
    } else {
      setFieldValue(
        'workingHours.holidays',
        values.workingHours.holidays.filter(
          (holiday: Moment | string) => !(holiday as Moment).isSame(value, 'day'),
        ),
      );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <TableContainer component={Paper}>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell align="left">Hours</TableCell>
              <TableCell></TableCell>
              <TableCell>Closed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {days.map((day) => (
              <TableRow key={day}>
                <TableCell component="th" scope="row">
                  {day}
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <TimePicker
                      label="From"
                      disabled={!values.workingHours[day]?.till || !values.workingHours[day]?.till}
                      value={values.workingHours[day]?.from}
                      onChange={(value) => setFieldValue(`workingHours.${day}.from`, value)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <TimePicker
                      label="Till"
                      disabled={!values.workingHours[day]?.till || !values.workingHours[day]?.till}
                      value={values.workingHours[day]?.till}
                      onChange={(value) => setFieldValue(`workingHours.${day}.till`, value)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Switch
                    checked={!values.workingHours[day]?.from && !values.workingHours[day]?.till}
                    onChange={(e) => {
                      setFieldValue(`workingHours.${day}.from`, e.target.checked ? null : moment());
                      setFieldValue(`workingHours.${day}.till`, e.target.checked ? null : moment());
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div style={{ margin: '5px auto 5px 0' }}>
          <DesktopDatePicker
            inputFormat="DD.MM.YYYY"
            open={isHolidaysPickerOpen}
            value={null}
            onChange={handleChangeHolidays}
            components={{
              PaperContent: CustomContent,
            }}
            disableOpenPicker
            PopperProps={{
              placement: 'top-end',
            }}
            renderInput={(params) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  variant="outlined"
                  endIcon={<CalendarMonthIcon />}
                  onClick={() => setIsHolidaysPickerOpen((prev) => !prev)}
                >
                  Holidays
                </Button>
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    disabled: true,
                    placeholder: values.workingHours.holidays
                      .map((date: Moment | string) => (date as Moment).format('DD/MM/YYYY'))
                      .join(' '),
                  }}
                  fullWidth
                  sx={{ width: '100%', margin: '0 10px' }}
                />
              </div>
            )}
            renderDay={(holiday, selectedDays, pickersDayProps) => {
              let selectedMuiClass = 'Mui-active';
              if (
                values.workingHours.holidays.find((_holiday: Moment | string) =>
                  (_holiday as Moment).isSame(holiday, 'day'),
                )
              ) {
                selectedMuiClass = 'Mui-selected';
              }
              return (
                <PickersDay className={selectedMuiClass} value={undefined} {...pickersDayProps} />
              );
            }}
          />
        </div>
      </TableContainer>
    </LocalizationProvider>
  );
};

export default TimeTable;
