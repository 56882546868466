import React, { SyntheticEvent } from 'react';
import { Checkbox, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DeleteOutlined, Favorite, FavoriteBorder } from '@mui/icons-material';
import { grey, red } from '@mui/material/colors';
import { IShopRecord } from '@reducers/types';
import useBindDispatch from '@hooks/useBindDispatch';
import { updateShopAction } from '@actions/shops';
import { MODAL_TYPES } from '@components/Modal/types';
import { showModalAction } from '@actions/app/modal';
import { appendPath, goTo } from '@src/routeHistory';
import { ICard } from '@components/types';
import IconButtonDelete from '@components/Buttons/IconButtons/IconButtonDelete';
import IconButtonLike from '@components/Buttons/IconButtons/IconButtonLike';

const ShopCard = ({ item: _item }: ICard) => {
  const shop = _item as IShopRecord;
  const updateShop = useBindDispatch(updateShopAction);
  const showModal = useBindDispatch(showModalAction);

  const handleChangeIsFavorite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isFavorite = e.target.checked;
    updateShop({ id: shop.id, isFavorite });
  };

  const handleDeleteShop = () => {
    showModal({ type: MODAL_TYPES.DELETE_SHOP, entityId: shop.id });
  };

  const handleOpenShopPage = (e: SyntheticEvent<HTMLElement>) => {
    const { tagName } = e.target as HTMLElement;

    if (tagName === 'DIV' || tagName === 'IMG') appendPath(shop.id);
  };

  return (
    <Card style={{ cursor: 'pointer' }} onClick={handleOpenShopPage}>
      <CardActions>
        <IconButtonLike onClick={handleChangeIsFavorite} checked={shop.isFavorite} />
        <IconButtonDelete onClick={handleDeleteShop} />
      </CardActions>
      <CardMedia
        component="img"
        image={shop.logoUrl || './No_Image_Available.jpg'}
        alt="card-image"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {shop.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ShopCard;
