import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { templateCreationValidationSchema } from '@components/Modal/ModalComponents/FormValidationSchemas';

const TemplateCreationDialog = ({
  show,
  handleClose,
  handleCreateTemplate,
}: {
  show: boolean;
  handleClose: () => void;
  handleCreateTemplate: (name: string) => void;
}) => {
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: templateCreationValidationSchema,
    onSubmit: (values) => {
      handleCreateTemplate(values.name);
      handleClose();
    },
  });

  const { values, setFieldValue, validateForm, isValid, submitForm } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <Dialog open={show} onClose={handleClose} style={{ padding: '24px' }}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontFamily: 'Helvetica',
          fontSize: '20px',
          fontHeight: '24px',
          textTransform: 'uppercase',
          fontWeight: 700,
        }}
      >
        CREATE TEMPLATE
      </DialogTitle>
      <DialogContent style={{ width: '550px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '0.16px',
            fontFamily: 'Effra',
            textAlign: 'center',
          }}
        >
          We will create a template from the proportions you have chosen.
        </Typography>
        <TextField
          fullWidth
          label={'Template name'}
          onChange={(e) => setFieldValue('name', e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: '15px' }}
          value={values.name}
        />
      </DialogContent>
      <DialogActions sx={{ marginLeft: 'auto' }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={submitForm} variant="contained" disabled={!isValid}>
          Create template
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateCreationDialog;
