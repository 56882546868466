import React, { useState } from 'react';
import { Link, List, ListItem, ListItemText, ListSubheader, Skeleton } from '@mui/material';

import * as uuid from 'uuid';

import { IExpandableList } from '@components/types';

const FakeListItem = () => {
  return (
    <>
      <ListItem
        secondaryAction={
          <div style={{ color: '#9FA2B4' }}>
            <Skeleton animation={'wave'} height={20} width={60} />
          </div>
        }
      >
        <ListItemText primary={<Skeleton animation={'wave'} height={20} width={60} />} />
      </ListItem>
    </>
  );
};

const ExpandableList = ({
  style,
  title,
  data,
  previewAmount,
  valueText,
  double,
  isLoading,
}: IExpandableList) => {
  const [expand, setExpand] = useState<boolean>(false);

  const handleExpandList = () => {
    setExpand((prev) => !prev);
  };

  return (
    <>
      <List
        style={style}
        subheader={
          <ListItem>
            <ListSubheader component={'span'}>{title}</ListSubheader>
            <Link onClick={handleExpandList}>{expand ? 'Collapse' : 'View all'}</Link>
          </ListItem>
        }
      >
        {isLoading
          ? Array(4)
              .fill(0)
              .map(() => <FakeListItem key={uuid.v4()} />)
          : data?.slice(0, previewAmount).map((record) => (
              <ListItem
                key={uuid.v4()}
                secondaryAction={
                  <div style={{ color: '#9FA2B4' }}>
                    {record.amount.toFixed(double ? 2 : 0)} {valueText}
                  </div>
                }
              >
                <ListItemText primary={record.name} />
              </ListItem>
            ))}

        {expand &&
          data?.slice(previewAmount).map((record) => {
            return (
              <ListItem
                key={uuid.v4()}
                secondaryAction={
                  <div style={{ color: '#9FA2B4' }}>
                    {record.amount.toFixed(double ? 2 : 0)} {valueText}
                  </div>
                }
              >
                <ListItemText primary={record.name} />
              </ListItem>
            );
          })}
      </List>
    </>
  );
};

export default ExpandableList;
