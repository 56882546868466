import { InitialShopsStateType, ReducerAction } from '@reducers/types';
import { SET_SELECTED_SHOP, SET_SHOPS, SET_SHOPS_LOADING_STATE } from '@actions/actionTypes';

const initialState: InitialShopsStateType = {
  records: [],
  selected: null,
  pagesTotal: 1,
  isLoading: false,
};

const shopsReducer = (
  state: InitialShopsStateType = initialState,
  { type, payload }: ReducerAction,
): InitialShopsStateType => {
  switch (type) {
    case SET_SHOPS: {
      const { data, pagesTotal } = payload;
      return { ...state, records: data, pagesTotal };
    }
    case SET_SHOPS_LOADING_STATE: {
      return { ...state, isLoading: payload };
    }
    case SET_SELECTED_SHOP: {
      return { ...state, selected: payload };
    }
  }

  return state;
};

export default shopsReducer;
