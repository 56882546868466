import { ReducerAction, StatisticStateType } from '@reducers/types';
import {
  SET_FAVORITE_CATEGORIES_STATISTIC,
  SET_FAVORITE_CATEGORIES_STATISTIC_LOADING,
  SET_FAVORITE_SHOPS_STATISTIC,
  SET_FAVORITE_SHOPS_STATISTIC_LOADING,
  SET_STATISTIC,
  SET_STATISTIC_IS_LOADING,
  SET_TOP_LOCATIONS,
  SET_TOP_LOCATIONS_LOADING,
  SET_TOP_PRODUCTS_STATISTIC,
  SET_TOP_PRODUCTS_STATISTIC_LOADING,
  SET_TOP_QUERIES_STATISTIC,
  SET_TOP_QUERIES_STATISTIC_LOADING,
  SET_TOP_SELECTED_SEARCH_SHOPS,
  SET_TOP_SELECTED_SEARCH_SHOPS_LOADING,
  SET_USAGE_STATISTIC,
  SET_USAGE_STATISTIC_LOADING,
} from '@actions/actionTypes';

const initialState: StatisticStateType = {
  favoriteShops: {
    isLoading: false,
    data: null,
  },
  favoriteCategories: {
    isLoading: false,
    data: null,
  },
  topProducts: {
    isLoading: false,
    data: null,
  },
  topSearchQueries: {
    isLoading: false,
    data: null,
  },
  topSelectedShops: {
    isLoading: false,
    data: null,
  },
  locations: {
    isLoading: false,
    data: null,
  },
  usage: {
    isLoading: false,
    data: {
      firstOpen: null,
      totalPerPlatform: null,
      conversion: null,
    },
  },
  isLoading: false,
};

const statisticReducer = (
  state: StatisticStateType = initialState,
  { type, payload }: ReducerAction,
): StatisticStateType => {
  switch (type) {
    case SET_STATISTIC: {
      return { ...state, ...payload };
    }
    case SET_STATISTIC_IS_LOADING: {
      return { ...state, isLoading: payload };
    }
    case SET_FAVORITE_SHOPS_STATISTIC: {
      return { ...state, favoriteShops: { data: payload, isLoading: false } };
    }
    case SET_FAVORITE_SHOPS_STATISTIC_LOADING: {
      return { ...state, favoriteShops: { data: state.favoriteShops.data, isLoading: payload } };
    }
    case SET_FAVORITE_CATEGORIES_STATISTIC: {
      return { ...state, favoriteCategories: { data: payload, isLoading: false } };
    }
    case SET_FAVORITE_CATEGORIES_STATISTIC_LOADING: {
      return {
        ...state,
        favoriteCategories: { data: state.favoriteCategories.data, isLoading: payload },
      };
    }
    case SET_TOP_PRODUCTS_STATISTIC: {
      return { ...state, topProducts: { data: payload, isLoading: false } };
    }
    case SET_TOP_PRODUCTS_STATISTIC_LOADING: {
      return { ...state, topProducts: { data: state.topProducts.data, isLoading: payload } };
    }
    case SET_TOP_QUERIES_STATISTIC: {
      return { ...state, topSearchQueries: { data: payload, isLoading: false } };
    }
    case SET_TOP_QUERIES_STATISTIC_LOADING: {
      return {
        ...state,
        topSearchQueries: { data: state.topSearchQueries.data, isLoading: payload },
      };
    }
    case SET_TOP_SELECTED_SEARCH_SHOPS: {
      return { ...state, topSelectedShops: { data: payload, isLoading: false } };
    }
    case SET_TOP_SELECTED_SEARCH_SHOPS_LOADING: {
      return {
        ...state,
        topSelectedShops: { data: state.topSelectedShops.data, isLoading: payload },
      };
    }
    case SET_TOP_LOCATIONS: {
      return { ...state, locations: { data: payload, isLoading: false } };
    }
    case SET_TOP_LOCATIONS_LOADING: {
      return { ...state, locations: { data: state.locations.data, isLoading: payload } };
    }
    case SET_USAGE_STATISTIC: {
      return { ...state, usage: { data: payload, isLoading: false } };
    }
    case SET_USAGE_STATISTIC_LOADING: {
      return { ...state, usage: { data: state.usage.data, isLoading: payload } };
    }
  }

  return state;
};

export default statisticReducer;
