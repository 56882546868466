import React from 'react';
import ExpandableList from '@components/List/ExpandableList/ExpandableList';
import { useSelector } from 'react-redux';
import {
  selectTopProductsStatistic,
  selectTopProductsStatisticIsLoading,
} from '@selectors/statisticSelectors';

const ProductsTab = () => {
  const productViews = useSelector(selectTopProductsStatistic);
  const isLoading = useSelector(selectTopProductsStatisticIsLoading);

  return (
    <>
      <ExpandableList
        title={'Most popular products'}
        previewAmount={6}
        data={productViews}
        double
        valueText={'sec'}
        isLoading={isLoading}
      />
    </>
  );
};

export default ProductsTab;
