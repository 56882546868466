import React, { useEffect, useState } from 'react';
import {
  modalButtonsSectionStyle,
  modalContentContainerStyle,
} from '@components/Modal/ModalContainer';
import { ThemeProvider } from '@mui/material/styles';
import { ICreateStoreFormState, IStoreModal } from '@components/Modal/types';
import {
  FormControlLabel,
  Box,
  Typography,
  Checkbox,
  Autocomplete,
  TextField,
  Button,
  Collapse,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { selectAddressesAutocomplete } from '@selectors/appSelectors';
import useBindDispatch from '@hooks/useBindDispatch';
import { findAddressAction } from '@actions/app/autocomplete';
import useDebounce from '@hooks/useDebounce';
import TimeTable from '@components/Modal/ModalComponents/TimeTable/TimeTable';
import { modalTheme } from '@src/themes';
import { useDefaultTimeTable } from '@hooks/useDefaultTimeTable';
import {storeValidationSchema} from '@components/Modal/ModalComponents/FormValidationSchemas';

const StoreModal = ({
  handleSubmit,
  closeModal,
  submitButtonText,
  shop,
  store,
  title,
}: IStoreModal) => {
  const addresses = useSelector(selectAddressesAutocomplete);
  const searchAction = useBindDispatch(findAddressAction);
  const [search, setSearch] = useState<string>('');
  const debounceSearch = useDebounce(search);

  useEffect(() => {
    searchAction(debounceSearch);
  }, [debounceSearch]);

  const formik = useFormik<ICreateStoreFormState>({
    initialValues: {
      id: store?.id || '',
      shopId: shop?.id || '',
      address: store?.address || '',
      useShopTimeTable: !!store?.useShopTimeTable,
      workingHours: useDefaultTimeTable(store),
    },
    validationSchema: storeValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, validateForm, isValid } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <ThemeProvider theme={modalTheme}>
      <Box sx={modalContentContainerStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            fullWidth
            onChange={(event, value) => setFieldValue('address', value)}
            value={values.address}
            options={addresses}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
                label="Address"
              />
            )}
          />
          <Box sx={{ width: '100%', marginTop: '30px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name={'useShopTimeTable'}
                  checked={values.useShopTimeTable}
                  onChange={handleChange}
                />
              }
              label="Use shop working hours"
            />
          </Box>
          <Box style={{ marginBottom: '15px' }}>
            <Collapse in={!values.useShopTimeTable}>
              <TimeTable values={values} setFieldValue={setFieldValue} />
            </Collapse>
          </Box>
          <Box sx={modalButtonsSectionStyle} style={{ width: '100%' }}>
            <Button size="large" variant="text" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" size="large" variant="contained" disabled={!isValid}>
              {submitButtonText}
            </Button>
          </Box>
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default StoreModal;
