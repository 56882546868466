import { call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { API } from '@src/requests';
import * as types from '@actions/actionTypes';

import {
  createCategoryAction,
  deleteCategoryAction,
  fetchCategoriesAction,
  saveCategoriesAction,
  updateCategoryAction,
} from '@actions/categories';
import { closeModalAction, showModalAction } from '@actions/app/modal';
import { MODAL_TYPES } from '@components/Modal/types';
import { ICategoryResponsePagination } from '@requests/types';
import { selectSearchQuery } from '@selectors/routerSelectors';
import qs from 'query-string';

function* fetch({ payload }: ReturnType<typeof fetchCategoriesAction>) {
  try {
    const response: ICategoryResponsePagination = yield call(API.Categories.fetch, payload);

    yield put(saveCategoriesAction(response));
  } catch (e) {
    console.error(e);
  }
}

function* createCategoryEffect({ payload }: ReturnType<typeof createCategoryAction>) {
  try {
    const search: string = yield select(selectSearchQuery);
    const { name = '', page = 1, limit = 8 } = qs.parse(search) || {};
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));
    yield call(API.Categories.create, payload);
    yield put(fetchCategoriesAction({ name, page, limit }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* updateCategoryEffect({ payload }: ReturnType<typeof updateCategoryAction>): any {
  try {
    const search: string = yield select(selectSearchQuery);
    const { name = '', page = 1, limit = 8 } = qs.parse(search) || {};
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(API.Categories.update, payload);

    yield put(fetchCategoriesAction({ name, page, limit }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* deleteCategoryEffect({ payload }: ReturnType<typeof deleteCategoryAction>): any {
  try {
    const search: string = yield select(selectSearchQuery);
    const { name = '', page = 1, limit = 8 } = qs.parse(search) || {};
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(API.Categories.delete, payload);
    yield put(fetchCategoriesAction({ name, page, limit }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

export function* categoriesSagas() {
  yield takeLeading(types.FETCH_CATEGORIES, fetch);
  yield takeEvery(types.CREATE_CATEGORY, createCategoryEffect);
  yield takeEvery(types.UPDATE_CATEGORY, updateCategoryEffect);
  yield takeEvery(types.DELETE_CATEGORY, deleteCategoryEffect);
}
