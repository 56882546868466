import { all, spawn } from 'redux-saga/effects';
import * as appSagas from '@sagas/app';
import * as categoriesSagas from '@sagas/categories';
import * as productsSagas from '@sagas/products';
import * as shopsSagas from '@sagas/shops';
import * as papersSagas from '@sagas/papers';
import * as importSagas from '@sagas/import';
import * as statisticSagas from '@sagas/statistic';

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(appSagas),
      ...Object.values(categoriesSagas),
      ...Object.values(productsSagas),
      ...Object.values(shopsSagas),
      ...Object.values(papersSagas),
      ...Object.values(importSagas),
      ...Object.values(statisticSagas),
    ].map(spawn),
  );
}
