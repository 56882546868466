import { IMenuItem } from '@components/types';
import { Avatar, ListItem, ListItemAvatar } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';

const MenuHeader = ({ expanded }: IMenuItem) => {
  if (expanded) {
    return (
      <ListItem
        sx={{
          minHeight: 112,
          justifyContent: expanded ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemAvatar
          sx={{
            minWidth: 0,
            mr: expanded ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          <Avatar alt={`Avatar n`} src={`/static/images/avatar/1.jpg`} />
        </ListItemAvatar>
        <ListItemText
          primary={'Fox Deal'}
          sx={{ opacity: expanded ? 1 : 0 }}
          primaryTypographyProps={{ sx: { fontSize: 24 } }}
        />
      </ListItem>
    );
  } else {
    return (
      <ListItem
        sx={{
          minHeight: 112,
          width: 0,
          padding: 0,
        }}
      ></ListItem>
    );
  }
};

export default MenuHeader;
