import { IGetWithPagination, IPaperRecord, IShopRecord } from '@reducers/types';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { API } from '@src/requests';
import * as types from '@actions/actionTypes';
import {
  createPaperAction,
  deletePaperAction,
  fetchPapersAction,
  savePapersAction,
  setIsLoadingAction,
  updatePaperAction,
} from '@actions/papers';
import { closeModalAction, showModalAction } from '@actions/app/modal';
import { MODAL_TYPES } from '@components/Modal/types';
import { selectSearchQuery } from '@selectors/routerSelectors';
import qs from 'query-string';
import { showNotificationAction } from '@actions/app/notification';
import { NotificationTypes } from '@constants/enums';

function* fetchEffect({ payload }: ReturnType<typeof fetchPapersAction>) {
  try {
    yield put(setIsLoadingAction(true));
    const data: IGetWithPagination<IPaperRecord> = yield call(API.Papers.fetch, payload);

    yield put(savePapersAction(data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setIsLoadingAction(false));
  }
}

function* createEffect({ payload }: ReturnType<typeof createPaperAction>) {
  try {
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));

    const formData = new FormData();

    payload?.logo && formData.append('logoImage', payload.logo);
    payload.images.forEach((image, idx) => {
      formData.append(`image-${idx}`, image);
    });
    formData.append('translation', JSON.stringify(payload.translation));
    formData.append('shopId', String(payload.shop?.id));
    formData.append('endDate', payload.promotionEndDate.format());
    formData.append('categories', payload.categories.map((category) => category.id).join(','));

    yield call(API.Papers.create, formData);

    const search: string = yield select(selectSearchQuery);
    const { limit, page, name = '', shopId = '', categoryId = '' } = qs.parse(search) || {};
    yield put(fetchPapersAction({ limit, page, name, shopId, categoryId }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* deleteEffect({ payload }: ReturnType<typeof deletePaperAction>) {
  yield put(closeModalAction());
  try {
    yield put(setIsLoadingAction(true));

    yield call(API.Papers.delete, payload);

    const search: string = yield select(selectSearchQuery);
    const { limit, page, name = '', shopId = '', categoryId = '' } = qs.parse(search) || {};
    yield put(fetchPapersAction({ limit, page, name, shopId, categoryId }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setIsLoadingAction(false));
  }
}

function* updateEffect({ payload }: ReturnType<typeof updatePaperAction>) {
  yield put(closeModalAction());
  try {
    yield put(setIsLoadingAction(true));
    const formData = new FormData();

    payload?.logo && formData.append('logoImage', payload.logo);
    payload.images.forEach((image, idx) => {
      formData.append(`image-${idx}`, image);
    });
    formData.append('externalId', payload.externalId);
    formData.append('imagesUrls', payload.imagesUrls.join(','));
    formData.append('translation', JSON.stringify(payload.translation));
    formData.append('shopId', String(payload.shop?.id));
    formData.append('endDate', payload.promotionEndDate.format());
    formData.append('categories', payload.categories.map((category) => category.id).join(','));

    yield call(API.Papers.update, payload.id, formData);

    const search: string = yield select(selectSearchQuery);
    const { limit, page, name = '', shopId = '', categoryId = '' } = qs.parse(search) || {};
    yield put(fetchPapersAction({ limit, page, name, shopId, categoryId }));
  } catch (e: any) {
    console.error(e);
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );
  } finally {
    yield put(setIsLoadingAction(false));
  }
}

export function* productsSagas() {
  yield takeEvery(types.FETCH_PAPERS, fetchEffect);
  yield takeLatest(types.CREATE_PAPER, createEffect);
  yield takeLatest(types.DELETE_PAPER, deleteEffect);
  yield takeLatest(types.UPDATE_PAPER, updateEffect);
}
