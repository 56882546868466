import { Store, combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import rootSaga from '@sagas/root';
import { createReduxHistoryContext } from 'redux-first-history';
import { middlewares, sagaMiddleware } from './enhancer';
import { IAppState } from './model';
import routeHistory from '@src/routeHistory';

import appReducer from '@reducers/app';
import categoriesReducer from '@reducers/categories';
import shopsReducer from '@reducers/shops';
import papersReducer from '@reducers/papers';

import productsReducer from '@reducers/products';
import importReducer from '@reducers/import';
import statisticReducer from '@reducers/statistic';

export type TAppStore = Store<IAppState>;

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: routeHistory,
  // savePreviousLocations: 1,
});

const store: TAppStore = configureStore({
  reducer: combineReducers({
    app: appReducer,
    categories: categoriesReducer,
    shops: shopsReducer,
    products: productsReducer,
    papers: papersReducer,
    import: importReducer,
    statistics: statisticReducer,
    router: routerReducer,
  }),
  // enhancers: [enhancer],
  middleware: [routerMiddleware, ...middlewares],
});

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);

export default store;
