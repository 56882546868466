import React from 'react';
import { Box } from '@mui/material';
import { ILoadingChartWrapper } from '@components/types';

const LoadingChartWrapper = ({
  isLoading,
  style,
  children,
  loaderOffsetLeft,
  loaderOffsetTop,
}: ILoadingChartWrapper) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
      }}
    >
      {isLoading && (
        <div
          className="lds-ripple"
          style={{
            position: 'absolute',
            left: loaderOffsetLeft || '45%',
            top: loaderOffsetTop || '40%',
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      <Box sx={{ filter: isLoading ? 'blur(6px)' : '' }} style={style}>
        {children}
      </Box>
    </div>
  );
};

export default LoadingChartWrapper;
