import { InitialCategoriesStateType, ReducerAction } from '@reducers/types';
import { SET_CATEGORIES, FETCH_CATEGORIES } from '@actions/actionTypes';

const initialState: InitialCategoriesStateType = {
  records: [],
  pagesTotal: 1,
  isLoading: false,
};

const categoriesReducer = (
  state: InitialCategoriesStateType = initialState,
  { type, payload }: ReducerAction,
): InitialCategoriesStateType => {
  switch (type) {
    case FETCH_CATEGORIES: {
      return initialState;
    }
    case SET_CATEGORIES: {
      const { data, pagesTotal } = payload;
      return { ...state, records: data, pagesTotal };
    }
  }

  return state;
};

export default categoriesReducer;
