import * as React from 'react';
import { useCallback } from 'react';
import { Avatar, Box, Button } from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { ThemeProvider } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import { IDropZone } from '@components/types';
import { showNotificationAction } from '@actions/app/notification';
import useBindDispatch from '@hooks/useBindDispatch';
import { dropZoneTheme } from '@src/themes';
import { AcceptedImportFormats, NotificationTypes } from '@constants/enums';

const DropZone = ({ action }: IDropZone) => {
  const setNotificationError = useBindDispatch(showNotificationAction);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const validFormat =
      file.type === AcceptedImportFormats.CSV ||
      file.type === AcceptedImportFormats.MS_EXCEL ||
      file.type === AcceptedImportFormats.OPEN_EXEL;
    if (validFormat) {
      action(file);
    } else {
      setNotificationError({
        message: 'Wrong file type. Acceptable types: xls, csv',
        type: NotificationTypes.ERROR,
      });
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <ThemeProvider theme={dropZoneTheme}>
      <Box
        {...getRootProps()}
        sx={{
          width: 'fill-available',
          minHeight: '50vh',
          height: 'fill-available',
          backgroundColor: isDragActive ? 'rgba(211, 211, 211, 0.5)' : 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px dashed rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
        }}
      >
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Avatar>
            <NoteAddOutlinedIcon />
          </Avatar>
          <Box>
            <Button variant="text" component="label">
              Click to upload
              <input hidden accept="image/*" multiple type="file" {...getInputProps()} />
            </Button>
            or drag and drop
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default DropZone;
