import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useBindDispatch from '@hooks/useBindDispatch';
import { showModalAction } from '@actions/app/modal';
import { MODAL_TYPES } from '@components/Modal/types';
import { ICreateStoreCard } from '@components/types';

const CreateStoreCard = ({ shopId }: ICreateStoreCard) => {
  const showModal = useBindDispatch(showModalAction);

  const handleClick = () => {
    showModal({ type: MODAL_TYPES.CREATE_STORE, entityId: shopId });
  };

  return (
    <Card style={{ cursor: 'pointer', minHeight: '75px' }} onClick={handleClick}>
      <CardContent>
        <AddCircleOutlineIcon sx={{ marginBottom: '5px' }} />
        <Typography gutterBottom variant="h5" component="div">
          Add new address
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CreateStoreCard;
