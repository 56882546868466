import React, { useEffect, useRef } from 'react';
import { SimpleHeat } from 'simpleheat-ts';
import { IHeatMapLayer } from '@components/types';
import { Overlay } from 'pigeon-maps';

const HeatMapLayer = ({ mapRef, data }: IHeatMapLayer) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);

  const heater = useRef(
    new SimpleHeat(document.createElement('canvas'), document.createElement('canvas')),
  );

  useEffect(() => {
    const { center = 0, zoom = 1 } = mapRef.current?.state || {};
    if (mapRef.current) {
      const converted = data.map((location) => [
        ...mapRef.current.latLngToPixel([location.lat, location.lng], center, zoom),
        location.amount,
      ]);
      heater.current.data(converted);
    }

    if (canvas.current) {
      const ctx = canvas.current.getContext('2d', { willReadFrequently: true });
      if (ctx) {
        if (canvas.current) {
          heater.current.radius(zoom);
          heater.current.draw(ctx, 0.5);
        }
      }
    }
  }, [mapRef.current?.state]);

  return (
    <Overlay>
      <div
        ref={containerRef}
        style={{ width: 'inherit', height: 'inherit', background: 'rgba(99,102,106, 0.5)' }}
      >
        <canvas
          ref={canvas}
          width={mapRef.current?.state.width}
          height={mapRef.current?.state.height}
        />
      </div>
    </Overlay>
  );
};

export default HeatMapLayer;
