import { IAppState } from '@store/model';
import { createSelector } from 'reselect';
import { selectCategoryId, selectShopId } from '@selectors/routerSelectors';
import { selectShopsData } from '@selectors/shopsSelectors';

export const selectProducts = (state: IAppState) => state.products;

export const selectProductsData = createSelector([selectProducts], (products) => products.records);

export const selectIsLoading = createSelector(selectProducts, (products) => products.isLoading);

export const selectProductsPagesAmount = createSelector(
  [selectProducts],
  (products) => products.pagesTotal,
);

export const selectedShopId = createSelector([selectShopsData, selectShopId], (shops, id) =>
  shops.find((shop) => shop.id === id),
);

export const selectCategoryByIdFromShop = createSelector(
  [selectedShopId, selectCategoryId],
  (shop, id) => shop?.categories?.find((category) => category.id === id),
);
