import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Chip,
  Card,
  Container,
  CardMedia,
  CardContent,
  CardActions,
} from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { selectShop } from '@selectors/shopsSelectors';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import useBindDispatch from '@hooks/useBindDispatch';
import { fetchShopAction, updateShopAction } from '@actions/shops';
import StoreCard from '@components/CardItems/Store/StoreCard';
import CreateStoreCard from '@components/CardItems/Store/CreateStoreCard';
import { showModalAction } from '@actions/app/modal';
import { MODAL_TYPES } from '@components/Modal/types';
import { shopPageIdTheme } from '@src/themes';
import CardGrid from '@components/CardTile/CardTile';
import IconButtonEdit from '@components/Buttons/IconButtons/IconButtonEdit';
import IconButtonLike from '@components/Buttons/IconButtons/IconButtonLike';
import Pagination from '@components/Pagination/Pagination';
import { IStoreRecord } from '@reducers/types';
import qs from 'query-string';

const ShopPageId = () => {
  const { id = '' } = useParams();

  const { search = '' } = useLocation();
  const { limit = 1, page = 1 } = qs.parse(search);

  const shop = useSelector(selectShop);
  const showModal = useBindDispatch(showModalAction);
  const updateShop = useBindDispatch(updateShopAction);
  const fetchShop = useBindDispatch(fetchShopAction);
  const [pagesTotal, setPagesTotal] = useState(1);
  const [storesToDisplay, setStoresToDisplay] = useState<Array<IStoreRecord>>([]);

  useEffect(() => {
    fetchShop(id);
  }, [id]);

  useEffect(() => {
    let pageNumber = Number(page) - 1;

    if (pageNumber < 0) pageNumber = 0;

    const offset = pageNumber * Number(limit);
    if (shop?.stores) {
      setStoresToDisplay(shop.stores?.slice(offset, offset + Number(limit)));
    }
  }, [shop, page, limit]);

  useEffect(() => {
    setPagesTotal(Math.ceil((shop?.stores?.length || Number(limit)) / Number(limit)));
  }, [shop]);

  const handleChangeIsFavorite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isFavorite = e.target.checked;
    updateShop({ id, isFavorite });
  };

  const handleShowEditShopModal = () => {
    showModal({ type: MODAL_TYPES.EDIT_SHOP, entityId: id });
  };

  return (
    <ThemeProvider theme={shopPageIdTheme}>
      <Container>
        <CardActions sx={{ right: '5px', top: '10px', position: 'absolute' }}>
          <IconButtonEdit onClick={handleShowEditShopModal} />
        </CardActions>
        <Paper elevation={5}>
          <Card component={Box}>
            <CardContent>
              <CardActions>
                <IconButtonLike onClick={handleChangeIsFavorite} checked={shop?.isFavorite} />
              </CardActions>
              <CardMedia
                component="img"
                image={shop?.logoUrl || '../No_Image_Available.jpg'}
                alt="card-image"
                height={'162px'}
              />
            </CardContent>
          </Card>
          <Box sx={{ marginLeft: '30px' }}>
            <Typography variant="h4">Shop name</Typography>
            <Typography variant="h6">{shop?.name}</Typography>
            <Typography variant="h4">Shop categories</Typography>
            {shop?.categories?.map((category) => (
              <Chip key={category.id} variant="outlined" size="small" label={category.names.EN} />
            ))}
          </Box>
        </Paper>
      </Container>
      <Typography
        sx={{
          marginTop: '28px',
          fontFamily: 'Helvetica',
          fontSize: '16px',
          lineHeight: '18px',
          fontWeight: 700,
        }}
      >
        Store addresses
      </Typography>
      <Pagination pagesTotal={pagesTotal} />
      <CardGrid Card={StoreCard} items={storesToDisplay}>
        <CreateStoreCard shopId={id} />
      </CardGrid>
    </ThemeProvider>
  );
};

export default ShopPageId;
