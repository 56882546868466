import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import { uploadTableAction } from '@actions/import';
import React, { ReactNode, RefObject, SyntheticEvent } from 'react';
import {
  ImportDocType,
  IPaperRecord,
  IProductRecord,
  IShopRecord,
  IStoreRecord,
  StatisticEventType,
} from '@reducers/types';
import { DeleteDocumentPayloadType } from '@actions/types';

export enum MenuItemName {
  STATISTIC = 'Statistic',
  CATEGORIES = 'Categories',
  SHOPS = 'Shops',
  PAPERS = 'Papers',
  PRODUCTS = 'Products',
  INITIAL = 'Initial',
  IMPORT = 'Import',
}

export interface IMenuItem {
  expanded: boolean;
  Icon?: OverridableComponent<SvgIconTypeMap<Record<any, any>, 'svg'>> & { muiName: string };
}

export interface IMenuItemInteractive extends IMenuItem {
  title: MenuItemName;
  path: string;
}

export interface IDropZone {
  action: typeof uploadTableAction;
}

export interface ILayout {
  view: ReactNode;
}

export interface IActionButton {
  onClick: (e: any) => void;
  title?: string;
  checked?: boolean;
}

export interface ICard {
  item: IProductRecord | IShopRecord | IStoreRecord | IPaperRecord;
}

export interface ICreateStoreCard {
  shopId: string;
}

export interface ICardTile {
  Card: React.FC<ICard>;
  items: Array<IProductRecord | IShopRecord | IStoreRecord | IPaperRecord> | undefined;
  isLoading?: boolean;
  children?: ReactNode;
}

export interface IPagination {
  pagesTotal: number;
  style?: React.CSSProperties;
}

export interface ISwipeableTabs {
  labels: string[];
  children: ReactNode[];
}

export interface IExpandableList {
  double?: boolean;
  style?: React.CSSProperties;
  title: string;
  data: StatisticEventType[] | null;
  previewAmount: number;
  valueText?: string;
  isLoading?: boolean;
}

export type LocationHeat = {
  lng: number;
  lat: number;
  amount: number;
};

export interface IHeatMapLayer {
  mapRef: RefObject<any>;
  data: LocationHeat[];
}

export interface IMetaBarChart {
  title: string;
  subtitle: string;
  labels: string[];
  data: number[];
  isLoading?: boolean;
}

export interface IButton {
  disabled?: boolean;
  children: ReactNode;
  action: (e: SyntheticEvent) => void;
}

export interface ILoadingChartWrapper {
  isLoading?: boolean;
  style?: React.CSSProperties;
  children: ReactNode;
  loaderOffsetTop?: string;
  loaderOffsetLeft?: string;
}

export interface IDateRangePicker {
  onClick: ({ startDate, endDate }: { startDate: Date; endDate: Date }) => void;
  isLoading?: boolean;
}

export enum TranslationForm {
  EN = 'EN',
  DE = 'DE',
  FR = 'FR',
  IT = 'IT',
  BP = 'BP',
}

export interface IDefaultInput {
  required?: boolean;
  id?: string;
  name?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  error?: boolean;
  helperText?: string | boolean;
  defaultValue?: string;
  disabled?: boolean;
}

export interface ITranslationInput extends IDefaultInput {
  language: TranslationForm;
}

export type TranslationInputProps = Required<
  Pick<ITranslationInput, 'onChange' | 'label' | 'language'>
>;

export interface ImageListCarouselProps {
  imagesPerView: number;
  images: string[];
}

export interface ImagePortalProps {
  title?: string;
  show: boolean;
  handleClose: () => void;
  imagesPerView: number;
  images: string[];
}

export interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export interface ISmartTableToolbar {
  numSelected: number;
  title: string;
  handleDelete: () => void;
  selected: readonly string[];
}

export interface ISmartHeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
}
type Order = 'asc' | 'desc';

export interface ISmartTableHeader<T> extends Omit<ISmartTable<T>, 'data' | 'deleteAction'> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  numSelected: number;
}

export interface ISmartTable<T> {
  headerCells: ISmartHeadCell<T>[];
  title: string;
  data: Record<string, any>[];
  deleteAction: (payload: DeleteDocumentPayloadType) => void;
  type: ImportDocType;
}
