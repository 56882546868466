import { IAppState } from '@store/model';
import { createSelector } from 'reselect';

export const selectStatistics = (state: IAppState) => state.statistics;

export const selectStatisticsLoadingState = createSelector(
  selectStatistics,
  (statistics) => statistics.isLoading,
);

export const selectFavoriteShopsStatistic = createSelector(
  selectStatistics,
  (statistics) => statistics.favoriteShops.data,
);

export const selectFavoriteShopsStatisticIsLoading = createSelector(
  selectStatistics,
  (statistics) => statistics.favoriteShops.isLoading,
);

export const selectFavoriteCategoriesStatistic = createSelector(
  selectStatistics,
  (statistics) => statistics.favoriteCategories.data,
);

export const selectFavoriteCategoriesStatisticIsLoading = createSelector(
  selectStatistics,
  (statistics) => statistics.favoriteCategories.isLoading,
);

export const selectTopProductsStatistic = createSelector(
  selectStatistics,
  (statistics) => statistics.topProducts.data,
);

export const selectTopProductsStatisticIsLoading = createSelector(
  selectStatistics,
  (statistics) => statistics.topProducts.isLoading,
);

export const selectTopSearchQueriesStatistic = createSelector(
  selectStatistics,
  (statistics) => statistics.topSearchQueries.data,
);

export const selectTopSearchQueriesStatisticIsLoading = createSelector(
  selectStatistics,
  (statistics) => statistics.topSearchQueries.isLoading,
);

export const selectTopSelectedShopsStatistic = createSelector(
  selectStatistics,
  (statistics) => statistics.topSelectedShops.data,
);

export const selectTopSelectedShopsStatisticIsLoading = createSelector(
  selectStatistics,
  (statistics) => statistics.topSelectedShops.isLoading,
);

export const selectLocationsStatistic = createSelector(
  selectStatistics,
  (statistics) => statistics.locations.data,
);

export const selectLocationsStatisticIsLoading = createSelector(
  selectStatistics,
  (statistics) => statistics.locations.isLoading,
);

export const selectUsageStatistic = createSelector(
  selectStatistics,
  (statistics) => statistics.usage.data,
);

export const selectUsageStatisticIsLoading = createSelector(
  selectStatistics,
  (statistics) => statistics.usage.isLoading,
);
