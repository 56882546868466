import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker as DateRangeCalendar } from 'react-date-range';
import moment from 'moment';
import { OutlinedInput, InputAdornment, Icon, Button } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { IDateRangePicker } from '@components/types';

const DateRangePicker = ({ onClick, isLoading }: IDateRangePicker) => {
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({
    visible: false,
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: new Date(),
    key: 'selection',
  });

  const setDate = (item: any) => {
    setState(item.selection);
    // validate();
  };
  const handleClickSearch = () => {
    onClick({ startDate: state.startDate, endDate: state.endDate });
    setVisible(false);
  };

  useEffect(() => {
    handleClickSearch();
  }, []);

  return (
    <div style={{ position: 'relative', height: '-webkit-fill-available' }}>
      <div>
        <div
          style={{
            zIndex: 10,
            display: 'flex',
            justifyItems: 'baseline',
            gap: '5px',
          }}
        >
          <OutlinedInput
            sx={{ width: '20vw', height: '40px', minWidth: '260px' }}
            value={`${moment(state.startDate).format('DD.MM.YYYY')} - ${moment(
              state.endDate,
            ).format('DD.MM.YYYY')}`}
            disabled={true}
            onClick={() => setVisible((prev) => !prev)}
            endAdornment={
              <InputAdornment position="end">
                <Icon>
                  <CalendarTodayIcon />
                </Icon>
              </InputAdornment>
            }
          />
          <Button
            variant="contained"
            style={{ height: '40px' }}
            onClick={() => handleClickSearch()}
            disabled={isLoading}
          >
            Search
          </Button>
        </div>
        {visible && (
          <DateRangeCalendar
            toggle={false}
            moveRangeOnFirstSelection={false}
            preventSnapRefocus={true}
            calendarFocus="backwards"
            months={2}
            direction="horizontal"
            ranges={[state]}
            date={new Date()}
            onChange={setDate}
          />
        )}
      </div>
    </div>
  );
};

export default DateRangePicker;
