import moment from 'moment/moment';
import { IShopRecord, IStoreRecord } from '@reducers/types';

export const useDefaultTimeTable = (item?: IStoreRecord | IShopRecord | null) => ({
  Monday: {
    from: item ? item?.timeTable?.Monday?.from : moment(),
    till: item ? item?.timeTable?.Monday?.till : moment(),
  },
  Tuesday: {
    from: item ? item?.timeTable?.Tuesday?.from : moment(),
    till: item ? item?.timeTable?.Tuesday?.till : moment(),
  },
  Wednesday: {
    from: item ? item?.timeTable?.Wednesday?.from : moment(),
    till: item ? item?.timeTable?.Wednesday?.till : moment(),
  },
  Thursday: {
    from: item ? item?.timeTable?.Thursday?.from : moment(),
    till: item ? item?.timeTable?.Thursday?.till : moment(),
  },
  Friday: {
    from: item ? item?.timeTable?.Friday?.from : moment(),
    till: item ? item?.timeTable?.Friday?.till : moment(),
  },
  Saturday: {
    from: item ? item?.timeTable?.Saturday?.from : moment(),
    till: item ? item?.timeTable?.Saturday?.till : moment(),
  },
  Sunday: {
    from: item ? item?.timeTable?.Sunday?.from : moment(),
    till: item ? item?.timeTable?.Sunday?.till : moment(),
  },
  holidays: item?.timeTable?.holidays?.map((holiday) => moment(holiday)) || [],
});
