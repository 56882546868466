import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ImportModal } from '@components/Modal/types';
import { useSelector } from 'react-redux';
import {
  selectImportStatus,
  selectImportType,
  selectIsImportLoading,
} from '@selectors/importSelectors';
import { ImportStep } from '@reducers/types';
import DropZone from '@components/DropZone/DropZone';
import LoaderModal from '@components/Modal/ModalComponents/LoaderModal/LoaderModal';
import MappingForm from '@components/Modal/ModalComponents/Import/MappingForm/MappingForm';
import useBindDispatch from '@hooks/useBindDispatch';

import useImportForm from '@hooks/useImportForm';
import { setImportStepAction } from '@actions/import';
import BakedProductTable from '@components/Modal/ModalComponents/Import/BakedTable/BakedProductTable';
import BakedPaperTable from '@components/Modal/ModalComponents/Import/BakedTable/BakedPaperTable';

const steps = ['Upload', 'Correlation of fields', 'Import products'];

const Import = ({ uploadAction }: ImportModal) => {
  const setStep = useBindDispatch(setImportStepAction);
  const isLoading = useSelector(selectIsImportLoading);
  const type = useSelector(selectImportType);
  const status = useSelector(selectImportStatus);
  const [slidesMap, setSlidesMap] = useState<Map<ImportStep, ReactNode>>(new Map());

  const { submitForm, values, handleChange } = useImportForm();

  useEffect(() => {
    const map = new Map();

    map.set(ImportStep.UPLOAD, <DropZone action={uploadAction} />);
    map.set(ImportStep.MAPPING, <MappingForm handleChange={handleChange} values={values} />);
    map.set(ImportStep.BAKING, type === 'products' ? <BakedProductTable /> : <BakedPaperTable />);

    setSlidesMap(map);
  }, [type, values]);

  const handleNext = () => {
    if (status === ImportStep.MAPPING) {
      submitForm().then(() => setStep(status + 1));
    } else {
      setStep(status + 1);
    }
  };

  const handleBack = () => {
    setStep(status - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={status}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={{ marginTop: '35px' }}>
        {isLoading ? <LoaderModal /> : slidesMap.get(status)}
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color="inherit"
            disabled={status === ImportStep.UPLOAD || status === ImportStep.FINISHED}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            onClick={handleNext}
            disabled={status === ImportStep.UPLOAD || status === ImportStep.FINISHED}
          >
            {status === ImportStep.BAKING ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Import;
