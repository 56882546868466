import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import useBindDispatch from '@hooks/useBindDispatch';
import { deleteCategoryAction } from '@actions/categories';
import {
  modalButtonsSectionStyle,
  modalContentContainerStyle,
} from '@components/Modal/ModalContainer';
import { ICategoryModal } from '@components/Modal/types';

const DeleteCategoryModal = ({ closeModal, category }: ICategoryModal) => {
  const deleteCategory = useBindDispatch(deleteCategoryAction);

  const handleDeleteCategory = () => {
    deleteCategory({ id: category?.id as string });
  };

  return (
    <>
      <Box sx={modalContentContainerStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Delete category
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {`Are you sure you want to delete category ${category?.names.EN}?`}
        </Typography>
      </Box>
      <Box sx={modalButtonsSectionStyle}>
        <Button size="large" variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={handleDeleteCategory} size="large" variant="contained">
          Delete category
        </Button>
      </Box>
    </>
  );
};

export default DeleteCategoryModal;
