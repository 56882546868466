import { call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { API } from '@src/requests';
import * as types from '@actions/actionTypes';
import {
  createShopAction,
  createStoreAction,
  deleteShopAction,
  deleteStoreAction,
  editStoreAction,
  fetchShopAction,
  fetchShopsAction,
  saveShopsAction,
  setSelectedShopAction,
  setShopsLoadingAction,
  updateShopAction,
} from '@actions/shops';
import { IShopRecord, IStoreRecord } from '@reducers/types';
import { closeModalAction, showModalAction } from '@actions/app/modal';
import { MODAL_TYPES } from '@components/Modal/types';
import { selectSearchQuery } from '@selectors/routerSelectors';
import qs from 'query-string';
import { selectShop } from '@selectors/shopsSelectors';
import { fetchCategoriesAction } from '@actions/categories';

function* fetchEffect({ payload }: ReturnType<typeof fetchShopsAction>) {
  try {
    yield put(setShopsLoadingAction(true));
    const data: IShopRecord[] = yield call(API.Shops.fetch, payload);

    yield put(saveShopsAction(data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setShopsLoadingAction(false));
  }
}

function* findOne({ payload }: ReturnType<typeof fetchShopAction>) {
  try {
    yield put(setShopsLoadingAction(true));
    yield put(fetchCategoriesAction({}));
    const data: IShopRecord = yield call(API.Shops.findOne, payload);

    yield put(setSelectedShopAction(data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setShopsLoadingAction(false));
  }
}

function* updateShopEffect({ payload }: ReturnType<typeof updateShopAction>): any {
  try {
    const { id } = payload;
    const formData = new FormData();
    payload?.logo && formData.append('logoImage', payload.logo);
    payload?.categories &&
      formData.append('categories', payload.categories.map((category) => category.id)?.join(','));
    payload?.name && formData.append('name', payload?.name);
    formData.append('isFavorite', String(payload.isFavorite));
    payload.workingHours && formData.append('workingHours', JSON.stringify(payload.workingHours));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(API.Shops.update, id, formData);

    const search: string = yield select(selectSearchQuery);
    const { limit = 8, page = 0, name = '' } = qs.parse(search) || {};
    yield put(fetchShopsAction({ limit, page, name }));

    const selected = yield select(selectShop);

    if (selected) yield put(fetchShopAction(selected.id));
    yield put(setShopsLoadingAction(false));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* createShopEffect({ payload }: ReturnType<typeof createShopAction>): any {
  try {
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));
    const formData = new FormData();
    payload?.logo && formData.append('logoImage', payload.logo);
    payload.categories &&
      formData.append('categories', payload.categories.map((category) => category.id).join(','));
    payload.name && formData.append('name', payload.name);
    formData.append('isFavorite', String(payload.isFavorite));
    formData.append('workingHours', JSON.stringify(payload.workingHours));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(API.Shops.create, formData);
    const search: string = yield select(selectSearchQuery);
    const { limit = 8, page = 0, name = '' } = qs.parse(search) || {};
    yield put(fetchShopsAction({ limit, page, name }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* deleteShopEffect({ payload }: ReturnType<typeof deleteShopAction>) {
  try {
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));
    yield call(API.Shops.delete, payload);
    const search: string = yield select(selectSearchQuery);
    const { limit = 8, page = 0, name = '' } = qs.parse(search) || {};
    yield put(fetchShopsAction({ limit, page, name }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* createStoreEffect({ payload }: ReturnType<typeof createStoreAction>) {
  try {
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));
    yield call(API.Stores.create, payload);

    const selected: IStoreRecord = yield select(selectShop);

    if (selected) yield put(fetchShopAction(selected.id));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* deleteStoreEffect({ payload }: ReturnType<typeof deleteStoreAction>) {
  try {
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));
    yield call(API.Stores.delete, payload);

    const selected: IStoreRecord = yield select(selectShop);

    if (selected) yield put(fetchShopAction(selected.id));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* updateStoreEffect({ payload }: ReturnType<typeof editStoreAction>) {
  try {
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));
    yield call(API.Stores.update, payload.id, payload);
    const selected: IStoreRecord = yield select(selectShop);

    if (selected) yield put(fetchShopAction(selected.id));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

export function* shopsSagas() {
  yield takeLeading(types.FETCH_SHOPS, fetchEffect);
  yield takeLeading(types.FETCH_SHOP, findOne);
  yield takeEvery(types.UPDATE_SHOP, updateShopEffect);
  yield takeEvery(types.CREATE_SHOP, createShopEffect);
  yield takeEvery(types.DELETE_SHOP, deleteShopEffect);
  yield takeEvery(types.CREATE_STORE, createStoreEffect);
  yield takeEvery(types.DELETE_STORE, deleteStoreEffect);
  yield takeEvery(types.UPDATE_STORE, updateStoreEffect);
}
