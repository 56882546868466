import React, { useEffect, useRef, useState } from 'react';
import { Map as PigeonMap, Point } from 'pigeon-maps';
import HeatMapLayer from '@components/Statistics/Search/Map/Layers/HeatMapLayer';
import { LocationHeat } from '@components/types';

const Map = ({ data }: { data?: LocationHeat[] | null }) => {
  const mapRef = useRef(null);
  const [center, setCenter] = useState<[number, number] | undefined>([46.839, 8.393]);

  useEffect(() => {
    setCenter(
      data?.reduce(
        (acc: Point | [number, number], location) => {
          const { lat = 46.839, lng = 8.393 } = location;
          acc[0] = (acc[0] + lat) / 2;
          acc[1] = (acc[1] + lng) / 2;

          return acc;
        },
        [46.839, 8.393],
      ),
    );
  }, [data]);
  return (
    <PigeonMap
      height={378}
      defaultCenter={[39, 34]}
      center={center}
      zoom={center?.length ? 6 : 4}
      ref={mapRef}
      boxClassname={'map'}
    >
      <HeatMapLayer mapRef={mapRef} data={data || []} />
    </PigeonMap>
  );
};

export default Map;
