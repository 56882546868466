import React from 'react';
import { Box, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import IconButtonEdit from '@components/Buttons/IconButtons/IconButtonEdit';
import IconButtonDelete from '@components/Buttons/IconButtons/IconButtonDelete';
import moment from 'moment/moment';
import { ICard } from '@components/types';
import { IPaperRecord } from '@reducers/types';
import { MODAL_TYPES } from '@components/Modal/types';
import useBindDispatch from '@hooks/useBindDispatch';
import { showModalAction } from '@actions/app/modal';

const PaperCard = ({ item }: ICard) => {
  const { id, logoUrl = '', names, endDate = '', shop } = item as IPaperRecord;

  const showModal = useBindDispatch(showModalAction);

  const handleDeletePaper = () => {
    showModal({ type: MODAL_TYPES.DELETE_PAPER, entityId: id });
  };

  const handleEditPaper = () => {
    showModal({ type: MODAL_TYPES.EDIT_PAPER, entityId: id });
  };

  return (
    <Card>
      <CardActions>
        <IconButtonEdit onClick={handleEditPaper} />
        <IconButtonDelete onClick={handleDeletePaper} />
      </CardActions>
      <CardMedia
        style={{
          height: '30px',
          width: '30px',
          alignSelf: 'start',
          marginBottom: '15px',
        }}
        component="img"
        image={shop?.logoUrl || './No_Image_Available.jpg'}
        alt="card-image"
      />
      <CardContent>
        <CardMedia component="img" image={logoUrl || './No_Image_Available.jpg'} alt="card-image" />
        <Box className={'cardFooter'}>
          <Typography gutterBottom variant="h5" component="div">
            {names?.EN}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography gutterBottom variant="h6" component="div">
              Valid until {moment(endDate).format('DD-MM-YYYY')}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PaperCard;
