import React from 'react';
import { Box, Skeleton } from '@mui/material';
import ExpandableList from '@components/List/ExpandableList/ExpandableList';
import Map from '@components/Statistics/Search/Map/Map';
import { LocationHeat } from '@components/types';
import { countArrayOfObjects } from '@src/utils';
import { useSelector } from 'react-redux';
import {
  selectLocationsStatistic,
  selectLocationsStatisticIsLoading,
  selectTopSearchQueriesStatistic,
  selectTopSearchQueriesStatisticIsLoading,
  selectTopSelectedShopsStatistic,
  selectTopSelectedShopsStatisticIsLoading,
} from '@selectors/statisticSelectors';

const CountryBox = ({
  name,
  amount,
  isLoading,
}: {
  name?: string;
  amount?: number;
  isLoading?: boolean;
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid black',
        textAlign: 'center',
        alignItems: 'center',
        gap: '15px',
        height: '25%',
        justifyContent: 'center',
      }}
    >
      {isLoading ? (
        <Skeleton animation={'wave'} width={85} height={30} />
      ) : (
        <span
          style={{
            fontFamily: 'Effra',
            fontSize: '24px',
            lineHeight: '18px',
            letterSpacing: '0.16px',
            fontWeight: 400,
          }}
        >
          {name}
        </span>
      )}
      {isLoading ? (
        <Skeleton animation={'wave'} width={65} height={50} />
      ) : (
        <span
          style={{
            fontFamily: 'Effra',
            fontSize: '32px',
            lineHeight: '18px',
            letterSpacing: '0.16px',
            fontWeight: 400,
          }}
        >
          {amount}
        </span>
      )}
    </Box>
  );
};

const SearchTab = () => {
  const locations = useSelector(selectLocationsStatistic);
  const isLoadingLocations = useSelector(selectLocationsStatisticIsLoading);
  const selectedShops = useSelector(selectTopSelectedShopsStatistic);
  const selectedShopsLoadingState = useSelector(selectTopSelectedShopsStatisticIsLoading);
  const popularSearchQueries = useSelector(selectTopSearchQueriesStatistic);
  const popularSearchQueriesLoadingState = useSelector(selectTopSearchQueriesStatisticIsLoading);

  return (
    <>
      <Box sx={{ display: 'flex', overflow: 'auto' }}>
        <Map data={locations as unknown as LocationHeat[]} />
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20vw', padding: '0 25px' }}>
          {isLoadingLocations
            ? Array(4)
                .fill(0)
                .map((_, index) => (
                  <CountryBox isLoading={isLoadingLocations} key={String(index)} />
                ))
            : countArrayOfObjects(locations, 'name', 'amount')
                ?.slice(0, 4)
                .map(({ name, amount }) => (
                  <CountryBox name={name as string} amount={amount as number} key={name} />
                ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '40px',
          justifyContent: 'space-between',
        }}
      >
        <ExpandableList
          style={{ width: '45%' }}
          title={'Most selected shops'}
          data={selectedShops}
          previewAmount={6}
          isLoading={selectedShopsLoadingState}
        />

        <ExpandableList
          style={{ width: '45%' }}
          title={'Most repeated search requests'}
          data={popularSearchQueries}
          previewAmount={6}
          isLoading={popularSearchQueriesLoadingState}
        />
      </Box>
    </>
  );
};

export default SearchTab;
