import { createTheme } from '@mui/material';

export const shopPageIdTheme = createTheme({
  palette: {
    warning: {
      main: '#fff',
      contrastText: '#121212',
    },
    success: {
      main: '#121212',
    },
  },
  typography: {
    h4: {
      marginBottom: '15px',
      letterSpacing: '0.16px',
      fontFamily: 'Helvetica',
      lineHeight: '18px',
      fontWeight: 700,
      fontSize: '16px',
    },
    h6: {
      marginBottom: '30px',
      fontFamily: 'Helvetica',
      lineHeight: '20px',
      fontWeight: 400,
      fontSize: '16px',
    },
  },
  components: {
    MuiCardMedia: {
      defaultProps: {
        sx: {
          height: 140,
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        sx: { marginLeft: 'auto' },
      },
    },
    MuiContainer: {
      defaultProps: {
        sx: {
          marginTop: '24px',
          padding: '0!important',
          maxWidth: '100vw!important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        sx: { right: '5px', top: '10px', position: 'absolute' },
      },
    },
    MuiPaper: {
      defaultProps: {
        sx: {
          display: 'flex',
          flexDirection: 'row',
          padding: '30px',
          width: 'fill-available',
          zIndex: 20,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        sx: {
          overflow: 'visible',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: 162,
          height: 162,
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
    },
    MuiCardActions: {
      defaultProps: {
        sx: {
          position: 'absolute',
          zIndex: '10',
          right: '-10px',
          top: '-24px',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          padding: '14px',
          boxShadow: 3,
          width: 24,
          height: 24,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        sx: {
          marginLeft: '8px',
          padding: '14px',
          boxShadow: 3,
          width: 24,
          height: 24,
        },
      },
    },
  },
});

export const timeTableTheme = createTheme({
  typography: {
    h6: {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
      fontWeight: 700,
      fontSize: '20px',
    },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        sx: { display: 'flex', flexDirection: 'row' },
      },
    },
    MuiTableContainer: {
      defaultProps: {
        sx: { overflow: 'hidden' },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true },
        autoFocus: true,
        margin: 'dense',
        type: 'number',
        variant: 'standard',
        sx: { width: '80px', margin: '8px' },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: { padding: '25px' },
      },
    },
    MuiPaper: {
      defaultProps: {
        // sx: { maxWidth: '1200px!important' },
      },
    },
  },
});

export const dropZoneTheme = createTheme({
  typography: {
    h6: {
      marginBottom: '40px',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
      fontWeight: 700,
      fontSize: '20px',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          color: '#000000',
          textTransform: 'none',
          ml: 1,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
          textDecoration: 'underline #000000',
        },
      },
    },
    MuiAvatar: {
      defaultProps: {
        sx: {
          backgroundColor: (theme) => theme.palette.grey[50],
          border: (theme) => `1px solid ${theme.palette.info.main}`,
          color: (theme) => theme.palette.info.main,
        },
      },
    },
  },
});

export const modalTheme = createTheme({
  typography: {
    h6: {
      marginBottom: '40px',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica',
      fontWeight: 700,
      fontSize: '20px',
    },
  },
});

///////////////////

export const LayoutTheme = createTheme({
  components: {
    MuiContainer: {
      defaultProps: {
        sx: {
          display: 'flex',
          height: '100vh',
          margin: 0,
          width: '100%',
          maxWidth: '100vw!important',
          padding: '0!important',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        sx: {
          position: 'relative!important',
        },
      },
    },
  },
});

export const ViewTheme = createTheme({
  palette: {
    warning: {
      main: '#fff',
      contrastText: '#121212',
    },
  },
  components: {
    MuiTable: {
      defaultProps: {
        sx: {
          minWidth: 650,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        sx: { marginBottom: '15px' },
      },
    },
    MuiTableRow: {
      defaultProps: {
        sx: { '&:last-child td, &:last-child th': { border: 0 } },
      },
    },
    MuiTableCell: {
      defaultProps: {
        sx: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          flexWrap: 'nowrap',
          maxWidth: '100px',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        sx: {
          width: 'fit-content',
          margin: 'auto 0 15px auto',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        style: {
          marginLeft: '10px',
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
        },
      },
    },
  },
});

export const CardTileTheme = createTheme({
  palette: {
    warning: {
      main: '#fff',
      contrastText: '#121212',
    },
    success: {
      main: '#121212',
    },
    secondary: {
      main: '#272727',
    },
  },
  typography: {
    fontFamily: 'Helvetica',
    h5: {
      fontSize: '11px',
      lineHeight: '13px',
    },
    h6: {
      fontSize: '11px',
      lineHeight: '13px',
      color: '#C0C0C0',
      alignSelf: 'self-end',
    },
    h4: {
      fontSize: '9px',
      color: '#A6A6A6',
      textDecoration: 'line-through',
    },
    h3: {
      fontSize: '15px',
      lineHeight: '17px',
      fontWeight: '700',
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        sx: {
          alignContent: 'baseline',
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          width: '100%',
          display: 'flex',
          padding: '20px 10px!important',
          margin: 0,
          flexDirection: 'row',
          flexWrap: 'wrap',
          columnGap: '30px',
          rowGap: '25px',
          maxWidth: 'unset!important',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 5,
        sx: {
          overflow: 'visible',
          position: 'relative',
          padding: '11px 11px 10px 11px',
          // display: 'flex',
          height: 'fit-content',
          width: 250,
        },
      },
    },
    MuiCardActions: {
      defaultProps: {
        sx: {
          position: 'absolute',
          zIndex: '10',
          right: '-20px',
          top: '-24px',
        },
      },
    },
    MuiCardMedia: {
      defaultProps: {
        sx: {
          height: 150,
          // height: '30px',
          // width: '30px',
          // alignSelf: 'start',
          // marginBottom: '15px',
        },
      },
    },
    MuiCardContent: {
      defaultProps: {
        sx: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '8px',
          padding: '0',
          width: '100%',
          paddingBottom: '0px!important',
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
    },
    MuiBadge: {
      defaultProps: {
        sx: {
          width: '100%',
        },
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        sx: {
          marginLeft: '8px',
          padding: '14px',
          boxShadow: 3,
          width: 24,
          height: 24,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          padding: '14px',
          boxShadow: 3,
          width: 24,
          height: 24,
        },
      },
    },
  },
});

export const TabsSliderTheme = createTheme({
  components: {
    MuiAppBar: {
      defaultProps: {
        sx: {
          marginTop: '25px',
          border: 'none',
          width: '30vw',
          boxShadow: 'none',
          color: '#19857b',
        },
      },
    },
    MuiList: {
      defaultProps: {
        sx: {
          paddingBottom: 0,
          height: 'max-content',
          bgcolor: 'background.paper',
          border: '1px solid #DFE0EB',
          borderRadius: '8px',
          minWidth: '250px',
        },
      },
    },
    MuiListSubheader: {
      defaultProps: {
        sx: {
          width: '100%',
          padding: 0,
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        sx: {
          borderBottom: '1px solid #DFE0EB',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        sx: {
          whiteSpace: 'nowrap',
          cursor: 'pointer',
          textDecoration: 'none',
          userSelect: 'none',
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        sx: { overflow: 'visible' },
        TabIndicatorProps: {
          style: {
            background: '#DADADA',
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        sx: {
          backgroundColor: '#fff',
          color: 'black!important',
          '&$selected': {
            backgroundColor: 'blue',
          },
        },
      },
    },
  },
});
