import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { Avatar, ListItem, ListItemAvatar } from '@mui/material';
import { IMenuItem } from '@components/types';
import ButtonExit from '@components/Buttons/ExitButton';
import { getLocalStorage } from '@utils/localstorage';
import { USER_DATA } from '@constants/constants';

const MenuItemProfile = ({ expanded, Icon }: IMenuItem) => {
  const user = JSON.parse(getLocalStorage(USER_DATA) || '{}');

  return (
    <ListItem
      sx={{
        minHeight: 48,
        justifyContent: expanded ? 'initial' : 'center',
        px: 2.5,
      }}
    >
      <ListItemAvatar
        sx={{
          minWidth: 0,
          mr: expanded ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        <Avatar alt={`Avatar n`} src={`/static/images/avatar/1.jpg`} />
      </ListItemAvatar>
      <ListItemText
        primary={`${user?.firstname} ${user?.lastname}`}
        sx={{ opacity: expanded ? 1 : 0 }}
      />
      <ButtonExit expanded={expanded} Icon={Icon} />
    </ListItem>
  );
};

export default MenuItemProfile;
