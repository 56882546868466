import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useBindDispatch from '@hooks/useBindDispatch';
import {
  loadImportedDocumentAction,
  setImportIdAction,
  setImportTypeAction,
} from '@actions/import';
import { showModalAction } from '@actions/app/modal';
import { MODAL_TYPES } from '@components/Modal/types';

const useImport = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const showModal = useBindDispatch(showModalAction);
  const setImportId = useBindDispatch(setImportIdAction);
  const setImportType = useBindDispatch(setImportTypeAction);
  const loadImportedDocument = useBindDispatch(loadImportedDocumentAction);

  const handleShowImportModal = (type: MODAL_TYPES) => {
    showModal({ type });
  };

  useEffect(() => {
    setImportType(
      pathname
        .split('/')
        .filter((x) => x)
        .at(0) as string,
    );

    if (pathname.includes('import')) {
      if (pathname.includes('product')) {
        handleShowImportModal(MODAL_TYPES.IMPORT_PRODUCT);
      }
      if (pathname.includes('paper')) {
        handleShowImportModal(MODAL_TYPES.IMPORT_PAPER);
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname.includes('import') && id) {
      setImportId(id);
      loadImportedDocument();
    }
  }, [id]);
};

export default useImport;
