import {
  createProductAction,
  deleteProductAction,
  fetchProductsAction,
  saveProductsAction,
  setProductsLoadingState,
  updateProductAction,
} from '@actions/products';
import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { API } from '@src/requests';
import * as types from '@actions/actionTypes';
import { IGetWithPagination, IProductRecord, IShopRecord } from '@reducers/types';
import { closeModalAction, showModalAction } from '@actions/app/modal';
import { MODAL_TYPES } from '@components/Modal/types';
import { selectSearchQuery } from '@selectors/routerSelectors';
import qs from 'query-string';
import { selectShopsData } from '@selectors/shopsSelectors';

function* fetchEffect({ payload }: ReturnType<typeof fetchProductsAction>) {
  try {
    yield put(setProductsLoadingState(true));
    const data: IGetWithPagination<IProductRecord> = yield call(API.Products.fetch, payload);

    yield put(saveProductsAction(data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setProductsLoadingState(false));
  }
}

function* createEffect({ payload }: ReturnType<typeof createProductAction>) {
  try {
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));

    const shop = payload.shop;
    const paperId = payload.paper?.id;
    const formData = new FormData();

    payload?.logo && formData.append('logoImage', payload.logo);
    shop &&
      formData.append(
        'shopCategoryId',
        shop.categories.find((category) => category.id === payload.category?.id)!.ShopCategoryModel
          .id,
      );
    formData.append('externalId', payload.externalId);
    formData.append('url', payload.url);
    formData.append('translation', JSON.stringify(payload.translation));
    formData.append('shopId', shop!.id);
    formData.append('discount', payload.discount);
    formData.append('oldPrice', payload.oldPrice);
    formData.append('newPrice', payload.newPrice);
    formData.append('endDate', payload.promotionEndDate.format());
    paperId && formData.append('paperId', paperId);

    yield call(API.Products.create, formData);

    const search: string = yield select(selectSearchQuery);
    const { limit = 8, page = 0, name = '', shopId = '', categoryId = '' } = qs.parse(search) || {};
    yield put(fetchProductsAction({ limit, page, name, shopId, categoryId }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* deleteEffect({ payload }: ReturnType<typeof deleteProductAction>) {
  try {
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));
    yield call(API.Products.delete, payload);
    const search: string = yield select(selectSearchQuery);
    const { limit = 8, page = 0, name = '', shopId = '', categoryId = '' } = qs.parse(search) || {};
    yield put(fetchProductsAction({ limit, page, name, shopId, categoryId }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

function* updateEffect({ payload }: ReturnType<typeof updateProductAction>) {
  try {
    yield put(showModalAction({ type: MODAL_TYPES.LOADER }));

    const shop = payload.shop;
    const formData = new FormData();
    const paperId = payload.paper?.id;

    payload?.logo && formData.append('logoImage', payload.logo);
    shop &&
      formData.append(
        'shopCategoryId',
        shop.categories.find((category) => category.id === payload.category?.id)!.ShopCategoryModel
          .id,
      );
    paperId && formData.append('paperId', paperId);
    formData.append('url', payload.url);
    formData.append('externalId', payload.externalId);
    formData.append('translation', JSON.stringify(payload.translation));
    shop && formData.append('shopId', shop.id);
    formData.append('discount', payload.discount);
    formData.append('oldPrice', payload.oldPrice);
    formData.append('newPrice', payload.newPrice);
    formData.append('endDate', payload.promotionEndDate.format());

    yield call(API.Products.update, payload.productId, formData);

    const search: string = yield select(selectSearchQuery);
    const { limit = 8, page = 0, name = '', shopId = '', categoryId = '' } = qs.parse(search) || {};
    yield put(fetchProductsAction({ limit, page, name, shopId, categoryId }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(closeModalAction());
  }
}

export function* productsSagas() {
  yield takeLatest(types.FETCH_PRODUCTS, fetchEffect);
  yield takeEvery(types.CREATE_PRODUCT, createEffect);
  yield takeEvery(types.DELETE_PRODUCT, deleteEffect);
  yield takeEvery(types.UPDATE_PRODUCT, updateEffect);
}
