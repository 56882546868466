export enum ROUTE {
  LOGIN = '/login',
  MAIN = '/',
  CATEGORIES = '/categories',
  SHOPS = '/shops',
  PAPERS = '/papers',
  PRODUCTS = '/products',
  STATISTIC = '/statistic',
  IMPORT = '/import',
}
