import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { selectMappedFields } from '@selectors/importSelectors';
import useBindDispatch from '@hooks/useBindDispatch';
import { useEffect } from 'react';
import { setMappedFieldsAction } from '@actions/import';

const useImportForm = () => {
  const mappedFields = useSelector(selectMappedFields);
  const setMappedFields = useBindDispatch(setMappedFieldsAction);

  const formik = useFormik({
    initialValues: mappedFields,
    onSubmit: (values) => {
      setMappedFields(values);
    },
  });

  const { submitForm, values, handleChange, setValues } = formik;

  useEffect(() => {
    setValues(mappedFields);
  }, [mappedFields, setValues]);

  return { submitForm, values, handleChange };
};

export default useImportForm;
