import * as types from '@actions/actionTypes';
import { ImportDocType, ImportStateType, ImportStep, ImportTemplateType } from '@reducers/types';
import { DeleteDocumentPayloadType } from '@actions/types';

export const uploadTableAction = (payload: File) => ({
  type: types.UPLOAD_TABLE,
  payload,
});

export const setMappedFieldsAction = (payload: Record<string, string>) => ({
  type: types.SET_IMPORT_MAPPED_FIELDS,
  payload,
});

export const setImportAction = (payload: Partial<ImportStateType>) => ({
  type: types.SET_IMPORT,
  payload,
});

export const setImportIdAction = (payload: string) => ({
  type: types.SET_IMPORT_ID,
  payload,
});

export const setImportTypeAction = (payload: string) => ({
  type: types.SET_IMPORT_TYPE,
  payload,
});

export const clearImportAction = () => ({
  type: types.CLEAR_IMPORT,
});

export const setImportStepAction = (payload: ImportStep) => ({
  type: types.SET_IMPORT_STEP,
  payload,
});

export const setImportIsLoading = (payload: boolean) => ({
  type: types.SET_IMPORT_IS_LOADING,
  payload,
});

export const loadImportedDocumentAction = () => ({
  type: types.LOAD_DOCUMENT,
});

export const setSelectedBakedRecordsAction = (payload: string[]) => ({
  payload,
  type: types.SET_SELECTED_BAKED_RECORDS,
});

export const createTemplateAction = (payload: Omit<ImportTemplateType, 'id' | 'type'>) => ({
  payload,
  type: types.CREATE_IMPORT_TEMPLATE,
});

export const fetchTemplatesAction = (payload?: ImportDocType) => ({
  payload,
  type: types.FETCH_IMPORT_TEMPLATES,
});

export const setTemplatesAction = (payload: ImportTemplateType[]) => ({
  payload,
  type: types.SET_IMPORT_TEMPLATES,
});

export const fetchDocumentsForImportAction = (payload?: ImportDocType) => ({
  payload,
  type: types.FETCH_DOCUMENTS_FOR_IMPORT,
});

export const setDocumentsForImport = (payload?: ImportDocType) => ({
  payload,
  type: types.SET_DOCUMENTS_FOR_IMPORT,
});

export const deleteDocumentAction = (payload: DeleteDocumentPayloadType) => ({
  payload,
  type: types.DELETE_IMPORT_DOCUMENT,
});

export const deleteTemplateAction = (payload: DeleteDocumentPayloadType) => ({
  payload,
  type: types.DELETE_IMPORT_TEMPLATE,
});
