import React from 'react';

import Button from '@mui/material/Button';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { appendPath } from '@src/routeHistory';
import useImport from '@hooks/useImport';

const ImportButton = () => {
  useImport();

  return (
    <Button
      onClick={() => appendPath('/import')}
      sx={{ marginLeft: 'auto' }}
      variant="contained"
      startIcon={<NoteAddOutlinedIcon />}
    >
      Import file
    </Button>
  );
};

export default ImportButton;
