import { Box, Button, Typography } from '@mui/material';
import {
  modalButtonsSectionStyle,
  modalContentContainerStyle,
} from '@components/Modal/ModalContainer';
import React from 'react';
import { IProductModal } from '@components/Modal/types';

const DeleteProductModal = ({ product, closeModal, handleSubmit }: IProductModal) => {
  return (
    <>
      <Box sx={modalContentContainerStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Delete product
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {`Are you sure you want to delete product ${product?.names.EN}?`}
        </Typography>
      </Box>
      <Box sx={modalButtonsSectionStyle}>
        <Button size="large" variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={handleSubmit.bind(null, product?.id)} size="large" variant="contained">
          Delete product
        </Button>
      </Box>
    </>
  );
};

export default DeleteProductModal;
