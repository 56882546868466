import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Typography, Box, TextField } from '@mui/material';
import Button from '@components/Buttons/Button';
import useBindDispatch from '@hooks/useBindDispatch';
import { loginAction } from '@actions/app';

const LoginPage = () => {
  const validate = () => setValid(!!(loginRef?.current.value && passwordRef.current.value));
  const login = useBindDispatch(loginAction);
  const [valid, setValid] = useState<boolean>(false);
  const loginRef = useRef({ value: '' });
  const passwordRef = useRef({ value: '' });

  useEffect(() => {
    validate();
  }, []);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    login({
      email: loginRef?.current.value,
      password: passwordRef.current.value,
    });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        flexDirection={'column'}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            inputRef={loginRef}
            onChange={validate}
            type={'email'}
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            inputRef={passwordRef}
            onChange={validate}
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button disabled={!valid} action={handleSubmit}>
            {' '}
            SIGN IN{' '}
          </Button>
        </Box>
      </Box>
      {/*</Container>*/}
    </>
  );
};

export default LoginPage;
