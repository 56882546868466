const MODULE_APP = 'APP';
const MODULE_MENU = `${MODULE_APP}/MENU`;
const MODULE_MODAL = `${MODULE_APP}/MODAL`;
const MODULE_AUTOCOMPLETE = `${MODULE_APP}/AUTOCOMPLETE`;
const MODULE_NOTIFICATION = `${MODULE_APP}/NOTIFICATION`;
const MODULE_CATEGORIES = 'CATEGORIES';
const MODULE_STATISTICS = 'STATISTICS';
const MODULE_PRODUCTS = 'PRODUCTS';
const MODULE_SHOPS = 'SHOPS';
const MODULE_PAPERS = 'PAPERS';
const MODULE_IMPORT = 'IMPORT';
const MODULE_ROUTER = '@@router';

export const LOCATION_CHANGE = `${MODULE_ROUTER}/LOCATION_CHANGE`;

export const FETCH_STATISTIC = `${MODULE_STATISTICS}/FETCH`;
export const SET_STATISTIC = `${MODULE_STATISTICS}/SET`;
export const SET_STATISTIC_IS_LOADING = `${MODULE_STATISTICS}/SET_STATISTIC_IS_LOADING`;
export const SET_FAVORITE_SHOPS_STATISTIC = `${MODULE_STATISTICS}/SET_FAVORITE_SHOPS`;
export const SET_FAVORITE_SHOPS_STATISTIC_LOADING = `${MODULE_STATISTICS}/SET_FAVORITE_SHOPS_LOADING`;
export const SET_FAVORITE_CATEGORIES_STATISTIC = `${MODULE_STATISTICS}/SET_FAVORITE_CATEGORIES_STATISTIC`;
export const SET_FAVORITE_CATEGORIES_STATISTIC_LOADING = `${MODULE_STATISTICS}/SET_FAVORITE_CATEGORIES_STATISTIC_LOADING`;
export const SET_TOP_PRODUCTS_STATISTIC = `${MODULE_STATISTICS}/SET_TOP_PRODUCTS`;
export const SET_TOP_PRODUCTS_STATISTIC_LOADING = `${MODULE_STATISTICS}/SET_TOP_PRODUCTS_LOADING`;
export const SET_TOP_QUERIES_STATISTIC = `${MODULE_STATISTICS}/SET_TOP_QUERIES`;
export const SET_TOP_QUERIES_STATISTIC_LOADING = `${MODULE_STATISTICS}/SET_TOP_QUERIES_LOADING`;
export const SET_TOP_SELECTED_SEARCH_SHOPS = `${MODULE_STATISTICS}/SET_TOP_SELECTED_SEARCH_SHOPS`;
export const SET_TOP_SELECTED_SEARCH_SHOPS_LOADING = `${MODULE_STATISTICS}/SET_TOP_SELECTED_SEARCH_SHOPS_LOADING`;
export const SET_TOP_LOCATIONS = `${MODULE_STATISTICS}/SET_TOP_LOCATIONS`;
export const SET_TOP_LOCATIONS_LOADING = `${MODULE_STATISTICS}/SET_TOP_LOCATIONS_LOADING`;
export const SET_USAGE_STATISTIC = `${MODULE_STATISTICS}/SET_USAGE_STATISTIC`;
export const SET_USAGE_STATISTIC_LOADING = `${MODULE_STATISTICS}/SET_USAGE_STATISTIC_LOADING`;

export const LOGIN = `${MODULE_APP}/LOGIN`;
export const LOGOUT = `${MODULE_APP}/LOGOUT`;
export const SET_IS_LOADING = `${MODULE_APP}/SET_IS_LOADING`;
export const SET_MENU_OPENED = `${MODULE_MENU}/SET_MENU_OPENED`;
export const SEARCH_ADDRESS = `${MODULE_AUTOCOMPLETE}/SEARCH_ADDRESS`;
export const SET_ADDRESS = `${MODULE_AUTOCOMPLETE}/SET_ADDRESS`;

export const FETCH_CATEGORIES = `${MODULE_CATEGORIES}/FETCH`;
export const SET_CATEGORIES = `${MODULE_CATEGORIES}/SET`;
export const SET_LOADING_CATEGORIES = `${MODULE_CATEGORIES}/SET_LOADING`;
export const CREATE_CATEGORY = `${MODULE_CATEGORIES}/CREATE`;
export const UPDATE_CATEGORY = `${MODULE_CATEGORIES}/UPDATE`;
export const DELETE_CATEGORY = `${MODULE_CATEGORIES}/DELETE`;

export const FETCH_PRODUCTS = `${MODULE_PRODUCTS}/FETCH`;
export const SET_PRODUCTS = `${MODULE_PRODUCTS}/SET`;
export const CREATE_PRODUCT = `${MODULE_PRODUCTS}/CREATE`;
export const DELETE_PRODUCT = `${MODULE_PRODUCTS}/DELETE`;
export const UPDATE_PRODUCT = `${MODULE_PRODUCTS}/UPDATE`;
export const SET_PRODUCTS_LOADING_STATE = `${MODULE_PRODUCTS}/SET_LOADING`;

export const FETCH_PAPERS = `${MODULE_PAPERS}/FETCH`;
export const SET_PAPERS = `${MODULE_PAPERS}/SET`;
export const CREATE_PAPER = `${MODULE_PAPERS}/CREATE_PAPER`;
export const DELETE_PAPER = `${MODULE_PAPERS}/DELETE_PAPER`;
export const UPDATE_PAPER = `${MODULE_PAPERS}/UPDATE_PAPER`;
export const SET_PAPER_IS_LOADING = `${MODULE_PAPERS}/SET_IS_LOADING`;

export const CLOSE_MODAL = `${MODULE_MODAL}/CLOSE`;
export const SHOW_MODAL = `${MODULE_MODAL}/SHOW`;

export const SET_NOTIFICATION = `${MODULE_NOTIFICATION}/SET_NOTIFICATION`;
export const CLOSE_NOTIFICATION = `${MODULE_NOTIFICATION}/CLOSE_NOTIFICATION`;

export const FETCH_SHOPS = `${MODULE_SHOPS}/FETCH`;
export const CREATE_SHOP = `${MODULE_SHOPS}/CREATE`;
export const SET_SHOPS = `${MODULE_SHOPS}/SET`;
export const UPDATE_SHOP = `${MODULE_SHOPS}/UPDATE`;
export const DELETE_SHOP = `${MODULE_SHOPS}/DELETE`;
export const CREATE_STORE = `${MODULE_SHOPS}/CREATE_STORE`;
export const UPDATE_STORE = `${MODULE_SHOPS}/UPDATE_STORE`;
export const DELETE_STORE = `${MODULE_SHOPS}/DELETE_STORE`;
export const FETCH_SHOP = `${MODULE_SHOPS}/FETCH_SHOP`;
export const SET_SELECTED_SHOP = `${MODULE_SHOPS}/SET_SELECTED`;
export const SET_SHOPS_LOADING_STATE = `${MODULE_SHOPS}/SET_LOADING`;

export const SET_IMPORT = `${MODULE_IMPORT}/SET`;
export const SET_IMPORT_ID = `${MODULE_IMPORT}/SET_ID`;
export const UPLOAD_TABLE = `${MODULE_IMPORT}/UPLOAD_TABLE`;
export const LOAD_DOCUMENT = `${MODULE_IMPORT}/LOAD_DOCUMENT`;
export const SET_IMPORT_IS_LOADING = `${MODULE_IMPORT}/SET_IS_LOADING`;
export const CLEAR_IMPORT = `${MODULE_IMPORT}/CLEAR`;
export const SET_IMPORT_STEP = `${MODULE_IMPORT}/SET_STEP`;
export const SET_IMPORT_TYPE = `${MODULE_IMPORT}/SET_TYPE`;
export const SET_IMPORT_MAPPED_FIELDS = `${MODULE_IMPORT}/SET_MAPPED_FIELDS`;
export const SET_SELECTED_BAKED_RECORDS = `${MODULE_IMPORT}/SET_SELECTED_BAKED_RECORDS`;
export const CREATE_IMPORT_TEMPLATE = `${MODULE_IMPORT}/CREATE_IMPORT_TEMPLATE`;
export const SET_IMPORT_TEMPLATES = `${MODULE_IMPORT}/SET_IMPORT_TEMPLATES`;
export const FETCH_IMPORT_TEMPLATES = `${MODULE_IMPORT}/FETCH_IMPORT_TEMPLATES`;
export const FETCH_DOCUMENTS_FOR_IMPORT = `${MODULE_IMPORT}/FETCH_DOCUMENTS_FOR_IMPORT`;
export const SET_DOCUMENTS_FOR_IMPORT = `${MODULE_IMPORT}/SET_DOCUMENTS_FOR_IMPORT`;
export const DELETE_IMPORT_DOCUMENT = `${MODULE_IMPORT}/DELETE_IMPORT_DOCUMENT`;
export const DELETE_IMPORT_TEMPLATE = `${MODULE_IMPORT}/DELETE_IMPORT_TEMPLATE`;
