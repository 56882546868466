import React from 'react';
import DefaultInput from '@components/Form/Inputs/DefaultInput/DefaultInput';
import { ITranslationInput } from '@components/types';

const TranslationInput = ({
  label,
  onChange,
  language,
  defaultValue,
  required = true,
  error,
}: ITranslationInput) => {
  return (
    <DefaultInput
      error={error}
      defaultValue={defaultValue}
      id={`translation-${language}-input`}
      name={`translation.${language}`}
      label={label}
      onChange={onChange}
      required={required}
    />
  );
};

export default TranslationInput;
