import React from 'react';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import { useSelector } from 'react-redux';
import { selectBakedData } from '@selectors/importSelectors';
import { Box, CardMedia } from '@mui/material';
import useBindDispatch from '@hooks/useBindDispatch';
import { setSelectedBakedRecordsAction } from '@actions/import';
import { ICategoryRecord } from '@reducers/types';
import ImagePortal from '@components/ImageListCarousel/ImagePortal/ImagePortal';

const createNameColumn = (id: string, nameObject: Record<string, string>) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {Object.keys(nameObject).map((key: string) => (
        <div key={`${id}-${key}`}>
          {' '}
          {key}: {nameObject[key]}{' '}
        </div>
      ))}
    </Box>
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70, flex: 1 },
  {
    field: 'shopName',
    headerName: 'Shop name',
    width: 120,
    flex: 1,
  },
  {
    field: 'categories',
    headerName: 'Category names',
    width: 120,
    flex: 1,
    renderCell: (params) =>
      createNameColumn(
        params.value.id,
        params.value.map((category: ICategoryRecord) => Object.values(category.names)).flat(),
      ),
  },
  {
    field: 'logoUrl',
    headerName: 'Paper photo',
    width: 130,
    flex: 1,
    renderCell: (params) => (
      <ImagePortal
        image={params.value || `${process.env.REACT_APP_BE_URL}404.png`}
        imagesPerView={4}
        images={params.row.images}
      />
    ),
  },
  {
    field: 'names',
    headerName: 'Paper names',
    type: 'string',
    width: 60,
    flex: 1,
    renderCell: (params) => createNameColumn(params.row.id, params.value),
  },
  {
    field: 'endDate',
    headerName: 'Promotion end date',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    flex: 1,
    width: 80,
  },
];

const BakedPaperTable = () => {
  const bakedData = useSelector(selectBakedData);
  const setSelected = useBindDispatch(setSelectedBakedRecordsAction);

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={bakedData}
        columns={columns}
        pageSize={5}
        isRowSelectable={(params: GridRowParams) => params.row.valid}
        disableSelectionOnClick
        rowHeight={100}
        selectionModel={bakedData.filter((row) => row.valid && row.selected).map((r) => r.id)}
        onCellClick={(params, e) => {
          e.stopPropagation();
          e.preventDefault();

          if (params.field !== '__check__') return;

          const { id = '' } = params || {};
          const selectedRowData = bakedData.filter((row) => row.selected).map((row) => row.id);

          if (selectedRowData.includes(id as string)) {
            setSelected(selectedRowData.filter((rowId) => rowId !== id.toString()));
          } else {
            selectedRowData.push(id.toString());
            setSelected(selectedRowData);
          }
        }}
        onSelectionModelChange={(ids, _) => {
          const selectedIDs = new Set(ids);
          const selectedRowData = bakedData
            .filter((row) => selectedIDs.has(row.id.toString()))
            .map((row) => row.id);

          setSelected(selectedRowData);
        }}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </Box>
  );
};

export default BakedPaperTable;
