import { IActionButton } from '@components/types';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import * as React from 'react';
import { Checkbox } from '@mui/material';

const IconButtonLike = ({ onClick, checked = false }: IActionButton) => {
  return (
    <Checkbox
      icon={<FavoriteBorder />}
      checkedIcon={<Favorite color={'success'} />}
      checked={checked}
      onChange={onClick}
    />
  );
};

export default IconButtonLike;
