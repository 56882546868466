import React from 'react';
import { ITimeTableDialog } from '@components/Modal/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from '@mui/material';

import TimeTable from '@components/Modal/ModalComponents/TimeTable/TimeTable';
import { timeTableTheme } from '@src/themes';

const TimeTableDialog = ({ show, handleClose, values, setFieldValue }: ITimeTableDialog) => {
  return (
    <ThemeProvider theme={timeTableTheme}>
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Working hours</DialogTitle>
        <DialogContent>
          <TimeTable values={values} setFieldValue={setFieldValue} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Save changes</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default TimeTableDialog;
