import React from 'react';
import styles from '@pages/Products/Products.module.scss';
import DateRangePicker from '@components/DateRangePicker/DateRangePicker';
import { Box } from '@mui/material';
import SwipeableTabs from '@components/SwipeableTabs/SwipeableTabs';
import InterestTab from '@components/Statistics/Interests/InterestTab';
import ProductsTab from '@components/Statistics/Products/ProductsTab';
import SearchTab from '@components/Statistics/Search/SearchTab';
import useBindDispatch from '@hooks/useBindDispatch';
import { fetchStatisticAction } from '@actions/statistic';
import UsageTab from '@components/Statistics/Usage/UsageTab';
import { useSelector } from 'react-redux';
import { selectStatisticsLoadingState } from '@selectors/statisticSelectors';

const Statistic = () => {
  const fetchStatistic = useBindDispatch(fetchStatisticAction);
  const isLoading = useSelector(selectStatisticsLoadingState);

  const handleFetchStatistic = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    fetchStatistic({ from: startDate, till: endDate });
  };
  return (
    <>
      <div className={styles.header}>
        <h1>Statistic</h1>
      </div>
      <DateRangePicker onClick={handleFetchStatistic} isLoading={isLoading} />
      <Box>
        <SwipeableTabs labels={['Interests', 'Products', 'Search', 'Usage']}>
          <InterestTab />
          <ProductsTab />
          <SearchTab />
          <UsageTab />
        </SwipeableTabs>
      </Box>
    </>
  );
};

export default Statistic;
