import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Avatar,
  TextField,
  SelectChangeEvent,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  modalButtonsSectionStyle,
  modalContentContainerStyle,
} from '@components/Modal/ModalContainer';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TranslationInput from '@components/Form/Inputs/TranslationInput/TranslationInput';
import DefaultInput from '@components/Form/Inputs/DefaultInput/DefaultInput';
import { productFormValidationSchema } from '@components/Modal/ModalComponents/FormValidationSchemas';
import { ThemeProvider } from '@mui/material/styles';
import { TranslationForm } from '@components/types';
import { ICreateProductFormState, IProductModal } from '@components/Modal/types';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { modalTheme } from '@src/themes';
import { IShopRecord } from '@reducers/types';

const ProductModal = ({
  closeModal,
  shops,
  product,
  title,
  submitButtonText,
  handleSubmit,
}: IProductModal) => {
  const formik = useFormik<ICreateProductFormState>({
    initialValues: {
      productId: product?.id || '',
      externalId: product?.externalId || '',
      shop: (product?.shop || shops?.at(0)) as IShopRecord,
      category:
        shops
          ?.find((shop) => shop.id === product?.shop.id)
          ?.categories.find(
            (category) => category?.ShopCategoryModel?.id == String(product?.shopCategoryId),
          ) || shops?.at(0)?.categories[0],
      paper: shops
        ?.find((shop) => shop.id === product?.shop.id)
        ?.papers?.find((paper) => paper?.id === product?.paperId),
      logo: null,
      logoUrl: product?.logoUrl || '',
      translation: product?.names || {
        EN: '',
        DE: '',
        FR: '',
        IT: '',
        BP: '',
      },
      url: product?.url || '',
      promotionEndDate: moment(product?.endDate, 'YYYY-MM-DD') || moment(),
      discount: (product && String(product?.discount)) || '',
      oldPrice: (product && String(product?.oldPrice)) || '',
      newPrice: (product && String(product?.newPrice)) || '',
    },
    validationSchema: productFormValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, validateForm, isValid, errors } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  const handleChangeShop = (e?: SelectChangeEvent<string>) => {
    const shop = shops?.find((shop) => shop.id === e?.target.value);
    setFieldValue('shop', shop);
    setFieldValue('category', shop?.categories[0]);
  };

  const handleChangeCategory = (e?: SelectChangeEvent<string>) => {
    setFieldValue(
      'category',
      values.shop?.categories.find((category) => category.id === e?.target.value),
    );
  };

  const handleChangePaper = (e?: SelectChangeEvent<string>) => {
    setFieldValue(
      'paper',
      values.shop?.papers.find((paper) => paper.id === e?.target.value),
    );
  };

  const handleLogoChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) setFieldValue('logo', e.currentTarget.files[0]);
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={modalContentContainerStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {' '}
            {title}{' '}
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel required id="select-shop-input-label">
                Shop name
              </InputLabel>
              <Select
                required
                labelId="select-shop-label"
                value={values.shop.id}
                name="shop"
                onChange={handleChangeShop}
                label="Shop"
              >
                {shops?.map((shop) => (
                  <MenuItem value={shop.id} id={shop.name} key={shop.id}>
                    {shop.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel required id="select-category">
                Select category EN
              </InputLabel>
              <Select
                required
                value={values.category?.id}
                labelId="select-category-label"
                id="select-category"
                name={'category'}
                onChange={handleChangeCategory}
                label="Category"
              >
                {values.shop?.categories?.map((category) => (
                  <MenuItem value={category.id} id={category.names.EN} key={category.id}>
                    {category.names.EN}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <input
              style={{ display: 'none' }}
              accept="image/*"
              id="icon-button-file"
              type="file"
              onChange={handleLogoChanged}
            />

            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="upload logo"
                component="span"
                sx={{ border: '2px solid rgba(0, 0, 0, 0.4)' }}
              >
                <Avatar
                  src={
                    values.logo
                      ? URL.createObjectURL(values?.logo as Blob)
                      : values.logoUrl || '/PhotoAvatar.png'
                  }
                  sx={{
                    width: (theme) => theme.spacing(15),
                    height: (theme) => theme.spacing(15),
                  }}
                />
              </IconButton>
            </label>

            <DefaultInput
              defaultValue={values.externalId}
              id="product-id-input"
              name={'externalId'}
              label={'Product ID'}
              onChange={handleChange}
            />

            <TranslationInput
              required={false}
              defaultValue={values.translation.EN}
              onChange={handleChange}
              error={Boolean(errors.translation)}
              helperText={String(errors.translation)}
              label={'Product name EN'}
              language={TranslationForm.EN}
            />
            <TranslationInput
              required={false}
              defaultValue={values.translation.DE}
              onChange={handleChange}
              error={Boolean(errors.translation)}
              helperText={String(errors.translation)}
              label={'Product name DE'}
              language={TranslationForm.DE}
            />
            <TranslationInput
              required={false}
              defaultValue={values.translation.FR}
              onChange={handleChange}
              error={Boolean(errors.translation)}
              helperText={String(errors.translation)}
              label={'Product name FR'}
              language={TranslationForm.FR}
            />
            <TranslationInput
              required={false}
              defaultValue={values.translation.IT}
              onChange={handleChange}
              error={Boolean(errors.translation)}
              helperText={String(errors.translation)}
              label={'Product name IT'}
              language={TranslationForm.IT}
            />
            <TranslationInput
              required={false}
              defaultValue={values.translation.BP}
              onChange={handleChange}
              error={Boolean(errors.translation)}
              helperText={String(errors.translation)}
              label={'Product name PT'}
              language={TranslationForm.BP}
            />

            <DefaultInput
              required
              defaultValue={values.url}
              id="store-url-input"
              name={'url'}
              label={'Original store URL'}
              onChange={handleChange}
              error={Boolean(formik.errors.url)}
              helperText={formik.errors.url}
            />

            <DesktopDatePicker
              inputFormat="DD.MM.YYYY"
              value={values.promotionEndDate}
              onChange={(value) => {
                setFieldValue('promotionEndDate', value);
              }}
              label={'Promotion end date'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={Boolean(errors.promotionEndDate)}
                  helperText={Boolean(errors.promotionEndDate) && 'Promotion end date is required'}
                  name={'promotionEndDate'}
                  sx={{ width: '100%', margin: '10px 0' }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel id="select-paper" shrink>
                {' '}
                Paper
              </InputLabel>
              <Select
                name="paper"
                value={values.paper?.id}
                labelId="paper-input-label"
                id="paper-input-id"
                label="Paper"
                onChange={handleChangePaper}
              >
                {values.shop.papers
                  ?.filter((paper) =>
                    paper.categories
                      .map((category) => category.id)
                      ?.includes(String(values.category?.id)),
                  )
                  .map((paper) => (
                    <MenuItem value={paper.id} key={paper.id}>
                      {paper.names.EN}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <DefaultInput
              defaultValue={values.discount}
              required
              id="discount-input"
              error={!!errors.discount}
              helperText={errors.discount}
              name={'discount'}
              label={'Discount'}
              onChange={handleChange}
            />

            <DefaultInput
              defaultValue={values.oldPrice}
              required
              id="old-price-input"
              error={!!errors.oldPrice}
              helperText={errors.oldPrice}
              name={'oldPrice'}
              label={'Old price'}
              onChange={handleChange}
            />
            <DefaultInput
              defaultValue={values.newPrice}
              error={!!errors.newPrice}
              helperText={errors.newPrice}
              required
              id="new-price-input"
              name={'newPrice'}
              label={'New price'}
              onChange={handleChange}
            />

            <Box sx={modalButtonsSectionStyle}>
              <Button size="large" variant="text" onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" size="large" variant="contained" disabled={!isValid}>
                {submitButtonText}
              </Button>
            </Box>
          </form>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default ProductModal;
