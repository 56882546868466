import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import * as React from 'react';
import { IMenuItem } from '@components/types';
import useBindDispatch from '@hooks/useBindDispatch';
import { logoutAction } from '@actions/app';

const ExitButton = ({ expanded, Icon }: IMenuItem) => {
  const logout = useBindDispatch(logoutAction);
  return (
    <ListItemButton
      onClick={logout}
      sx={{
        minHeight: 48,
        visibility: expanded ? 'visible' : 'hidden',
        justifyContent: 'right!important',
        margin: '0!important',
        padding: '0!important',
        borderRadius: '100px',
        px: 2.5,
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: expanded ? 3 : 'auto',
        }}
      >
        {Icon && <Icon sx={{ color: 'white' }} />}
      </ListItemIcon>
    </ListItemButton>
  );
};

export default ExitButton;
