import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ExpandableList from '@components/List/ExpandableList/ExpandableList';
import { BAR_CHART_OPTIONS } from '@constants/constants';
import { useSelector } from 'react-redux';
import {
  selectFavoriteCategoriesStatistic,
  selectFavoriteCategoriesStatisticIsLoading,
  selectFavoriteShopsStatistic,
  selectFavoriteShopsStatisticIsLoading,
} from '@selectors/statisticSelectors';
import LoadingChartWrapper from '@components/Charts/LoadingChartWrapper/LoadingChartWrapper';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const InterestTab = () => {
  const favoriteShops = useSelector(selectFavoriteShopsStatistic);
  const favoriteShopsIsLoading = useSelector(selectFavoriteShopsStatisticIsLoading);
  const favoriteCategories = useSelector(selectFavoriteCategoriesStatistic);
  const favoriteCategoriesLoadingState = useSelector(selectFavoriteCategoriesStatisticIsLoading);

  const chartData = {
    labels: favoriteShops?.map((shop) => shop.name),
    datasets: [
      {
        data: favoriteShops?.map((shop) => shop.amount),
        backgroundColor: '#D9D9D9',
      },
    ],
  };

  return (
    <div style={{ width: '99%' }}>
      <LoadingChartWrapper
        isLoading={favoriteShopsIsLoading}
        style={{ height: '30vw', width: '50vw', margin: '0 auto 25px auto' }}
      >
        <Bar options={BAR_CHART_OPTIONS} data={chartData} />
      </LoadingChartWrapper>
      <ExpandableList
        style={{ width: '50%' }}
        title={'Top categories'}
        data={favoriteCategories}
        previewAmount={4}
        isLoading={favoriteCategoriesLoadingState}
      />
    </div>
  );
};

export default InterestTab;
