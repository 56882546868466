import * as types from '@actions/actionTypes';

export const findAddressAction = (payload: string) => ({
  type: types.SEARCH_ADDRESS,
  payload,
});

export const setAddressesAction = (payload: string[]) => ({
  type: types.SET_ADDRESS,
  payload,
});
