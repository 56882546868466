import * as types from '@actions/actionTypes';
import { put, takeEvery, select, call, takeLatest } from 'redux-saga/effects';
import {
  clearImportAction,
  createTemplateAction,
  deleteDocumentAction,
  deleteTemplateAction,
  fetchDocumentsForImportAction,
  fetchTemplatesAction,
  setDocumentsForImport,
  setImportAction,
  setImportIdAction,
  setImportIsLoading,
  setImportStepAction,
  setTemplatesAction,
  uploadTableAction,
} from '@actions/import';
import {
  selectBakedChecked,
  selectImportId,
  selectImportStatus,
  selectImportType,
  selectMappedFields,
} from '@selectors/importSelectors';
import { IDocumentImportType, ImportDocType, ImportStep } from '@reducers/types';

import { setImportIdUrl, backRoute, leaveImportUrl } from '@src/routeHistory';
import { API } from '@src/requests';
import { showNotificationAction } from '@actions/app/notification';
import { closeModalAction } from '@actions/app/modal';
import { fetchProductsAction } from '@actions/products';
import { NotificationTypes } from '@constants/enums';
import { fetchPapersAction } from '@actions/papers';

function* importStepChangedEffect() {
  const status: number = yield select(selectImportStatus);
  const type: ImportDocType = yield select(selectImportType);

  try {
    yield put(setImportIsLoading(true));

    switch (status) {
      case ImportStep.UPLOAD: {
        yield put(clearImportAction());
        yield call(backRoute);

        break;
      }
      case ImportStep.MAPPING: {
        yield call(updateDocumentEffect);
        yield call(restoreDraftEffect);

        break;
      }
      case ImportStep.BAKING: {
        yield call(bakeDraftEffect);
        yield call(restoreDraftEffect);

        break;
      }
      case ImportStep.FINISHED: {
        yield call(updateBakeSelectionEffect);
        yield call(finishImportEffect);
        if (type === 'products') {
          yield put(fetchProductsAction({}));
        } else if (type === 'papers') {
          yield put(fetchPapersAction());
        }
        yield put(closeModalAction());
        yield put(clearImportAction());
        yield call(leaveImportUrl);

        break;
      }
    }
  } catch (e: any) {
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );

    yield put(setImportStepAction(status - 1));
  } finally {
    yield put(setImportIsLoading(false));
  }
}

function* uploadTableEffect({ payload }: ReturnType<typeof uploadTableAction>): any {
  try {
    yield put(setImportIsLoading(true));

    const currentStep: number = yield select(selectImportStatus);

    const type: ImportDocType = yield select(selectImportType);

    const tableFromData = new FormData();
    tableFromData.append('table', payload);
    tableFromData.append('type', type as string);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { id } = yield call(API.Documents.upload, tableFromData);

    yield call(setImportIdUrl, id);
    yield put(setImportIdAction(id));

    yield put(
      showNotificationAction({
        message: 'Document successfully uploaded!',
        type: NotificationTypes.SUCCESS,
      }),
    );

    yield put(setImportIsLoading(false));
    yield put(setImportStepAction(currentStep + 1));
  } catch (e: any) {
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );
    yield put(setImportIsLoading(false));
    console.error(e);
  }
}
function* restoreDraftEffect(): any {
  try {
    const id: string = yield select(selectImportId);
    const type: string = yield select(selectImportType);

    const { status, fields, mappedFields, baked }: IDocumentImportType = yield call(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      API.Documents.restoreImportDraft,
      id,
      type,
    );

    yield put(fetchTemplatesAction());
    yield put(setImportAction({ status, fields, mappedFields, baked }));
  } catch (e: any) {
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );
  }
}

function* updateDocumentEffect(): any {
  try {
    const id: string = yield select(selectImportId);
    const currentStatus: number = yield select(selectImportStatus);
    const mappedFields: Record<string, string> = yield select(selectMappedFields);
    const type: string = yield select(selectImportType);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(API.Documents.update, { status: currentStatus, mappedFields }, id, type);
  } catch (e: any) {
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );
  }
}

function* bakeDraftEffect(): any {
  try {
    const id: string = yield select(selectImportId);
    const type: string = yield select(selectImportType);

    yield call(updateDocumentEffect);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(API.Documents.bakeImport, id, type);
  } catch (e) {
    console.error(e);
  }
}

function* updateBakeSelectionEffect(): any {
  const id: string = yield select(selectImportId);
  const type: string = yield select(selectImportType);
  const selected: string[] = yield select(selectBakedChecked);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  yield call(API.Documents.updateBakeSelection, id, type, selected);
}

function* finishImportEffect(): any {
  const id: string = yield select(selectImportId);
  const type: string = yield select(selectImportType);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  yield call(API.Documents.finishImport, id, type);
}

function* createImportTemplate({ payload }: ReturnType<typeof createTemplateAction>): any {
  try {
    const type: string = yield select(selectImportType);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(API.Documents.createTemplate, { ...payload, type });
    yield put(fetchTemplatesAction());
  } catch (e: any) {
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );
    console.error(e);
  }
}

function* fetchTemplatesEffect(): any {
  try {
    const type: string = yield select(selectImportType);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const templates = yield call(API.Documents.getTemplates, type);

    yield put(setTemplatesAction(templates));
  } catch (e: any) {
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );

    console.error(e);
  }
}

function* fetchDocumentsForImportEffect({
  payload,
}: ReturnType<typeof fetchDocumentsForImportAction>): any {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const documents = yield call(API.Documents.getDocuments, { type: payload });
    yield put(setDocumentsForImport(documents));
  } catch (e: any) {
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );
    console.error(e);
  }
}

function* deleteDocumentEffect({ payload }: ReturnType<typeof deleteDocumentAction>): any {
  try {
    const { ids, type } = payload;

    yield call(API.Documents.deleteDocument, ids.join(','), type);
    yield put(fetchDocumentsForImportAction());
  } catch (e: any) {
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );
    console.error(e);
  }
}

function* deleteTemplateEffect({ payload }: ReturnType<typeof deleteTemplateAction>): any {
  try {
    const { ids, type } = payload;

    yield call(API.Documents.deleteTemplate, ids.join(','), type);
    yield put(fetchTemplatesAction());
  } catch (e: any) {
    yield put(
      showNotificationAction({
        message: e?.response?.data?.error || 'Something went wrong',
        type: NotificationTypes.ERROR,
      }),
    );
    console.error(e);
  }
}

export function* importSagas() {
  yield takeEvery(types.SET_IMPORT_STEP, importStepChangedEffect);
  yield takeEvery(types.UPLOAD_TABLE, uploadTableEffect);
  yield takeEvery(types.LOAD_DOCUMENT, restoreDraftEffect);
  yield takeLatest(types.CREATE_IMPORT_TEMPLATE, createImportTemplate);
  yield takeLatest(types.FETCH_IMPORT_TEMPLATES, fetchTemplatesEffect);
  yield takeLatest(types.FETCH_DOCUMENTS_FOR_IMPORT, fetchDocumentsForImportEffect);
  yield takeLatest(types.DELETE_IMPORT_DOCUMENT, deleteDocumentEffect);
  yield takeLatest(types.DELETE_IMPORT_TEMPLATE, deleteTemplateEffect);
}
