import { Middleware } from 'redux';
import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

export const sagaMiddleware = createSagaMiddleware({
  onError(err) {
    console.log(err);
  },
});

const middlewares: Middleware[] = [sagaMiddleware];

if (process.env.REACT_APP_ENV === `development`) {
  middlewares.push(logger);
}

export { middlewares };
// const composeEnhancers = composeWithDevTools({
//   // Specify name here, actionsBlacklist, actionsCreators and other options if needed
// });
//
// const applyEnhancer = () => {
//   return composeEnhancers(applyMiddleware(...middlewares));
// };
//
// const enhancer = applyEnhancer();
//
// export default enhancer;
