import { INotificationAlertType } from '@reducers/types';
import * as types from '@actions/actionTypes';

export const showNotificationAction = (
  payload: Pick<INotificationAlertType, 'type' | 'message'>,
) => ({
  type: types.SET_NOTIFICATION,
  payload,
});

export const closeNotificationAction = () => ({
  type: types.CLOSE_NOTIFICATION,
});
