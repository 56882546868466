import {
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ImageListCarousel from '@components/ImageListCarousel/ImageListCarousel';
import { ImagePortalProps } from '@components/types';

const PortalDialog = ({ title, show, handleClose, imagesPerView, images }: ImagePortalProps) => {
  return (
    <Dialog open={show} sx={{ width: '50vw', height: '80vh', margin: 'auto' }}>
      <DialogTitle>
        {title || 'Preview'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ImageListCarousel imagesPerView={imagesPerView} images={images} />
      </DialogContent>
      <DialogActions>
        {/*<Button onClick={handleClose}>Cancel</Button>*/}
        {/*<Button onClick={handleClose}>Save changes</Button>*/}
      </DialogActions>
    </Dialog>
  );
};

const ImagePortal = ({
  image,
  imagesPerView = 1,
  images,
}: {
  image?: string;
  imagesPerView?: number;
  images: string[];
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <PortalDialog
        show={show}
        handleClose={() => setShow(false)}
        imagesPerView={imagesPerView}
        images={images}
      />
      <CardMedia onClick={() => setShow(true)} component="img" image={image} alt="card-image" />
    </>
  );
};

export default ImagePortal;
