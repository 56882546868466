import { IPagination } from '@components/types';
import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Pagination as MuiPagination } from '@mui/material';
import qs from 'query-string';

const samePageNumber = (prev: IPagination, next: IPagination) =>
  prev.pagesTotal === next.pagesTotal;

const Pagination = ({ pagesTotal, style }: IPagination) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { search, pathname } = useLocation();
  const { limit = null, page = null } = qs.parse(search);

  useEffect(() => {
    if (!pathname.includes('import')) {
      if (!limit && !page) {
        searchParams.set('limit', '12');
        searchParams.set('page', '1');
        setSearchParams(searchParams);
      } else if (!limit) {
        searchParams.set('limit', '12');
        setSearchParams(searchParams);
      } else if (!page) {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
      }
    }
  }, [limit, page]);

  const handlePageChanged = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    const pageFromQuery = Number(searchParams.get('page'));
    if (pageNumber !== pageFromQuery) {
      searchParams.set('page', String(pageNumber));
      setSearchParams(searchParams);
    }
  };

  return (
    <MuiPagination
      page={Number(page) || 1}
      count={pagesTotal || 1}
      onChange={handlePageChanged}
      style={style}
    />
  );
};

export default React.memo(Pagination, samePageNumber);
