import React from 'react';
import { Box, Card, CardContent, Skeleton } from '@mui/material';

const FakeCard = () => {
  return (
    <Card>
      <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
      <CardContent>
        <Box className={'cardFooter'}>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Skeleton animation="wave" height={50} width="80%" />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FakeCard;
