import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';

import { Drawer as MuiDrawer } from '@mui/material';
import { List, CssBaseline, Divider, IconButton } from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';
import {
  ChevronLeft,
  ChevronRight,
  PieChartOutline,
  ViewStreamOutlined,
  ShoppingCartOutlined,
  ImportContacts,
  CakeOutlined,
  ImportExport,
} from '@mui/icons-material';

import MenuItem from '@components/Menu/MenuItem';
import style from './Menu.module.scss';
import { MenuItemName } from '@components/types';
import { useSelector } from 'react-redux';
import { selectIsOpened } from '@selectors/appSelectors';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import useBindDispatch from '@hooks/useBindDispatch';
import { setMenuOpenedAction } from '@actions/app/menu';
import MenuItemProfile from '@components/Menu/MenuItemProfile';
import MenuHeader from '@components/Menu/MenuHeader/MenuHeader';
import { ROUTE } from '@router/routes';

const drawerWidth = 255;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Menu = () => {
  const theme = useTheme();
  const isOpen = useSelector(selectIsOpened);
  const setOpen = useBindDispatch(setMenuOpenedAction);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <>
      <CssBaseline />
      <Drawer variant="permanent" open={isOpen} className={style.container}>
        <DrawerHeader
          sx={{
            justifyContent: isOpen ? 'end' : 'center',
          }}
        >
          <MenuHeader expanded={isOpen} />
          {!isOpen ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              title={'expand menu'}
              edge="start"
              sx={{
                margin: 0,
                minHeight: 48,
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerClose} sx={{ color: 'white' }} title={'collapse menu'}>
              {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        <List>
          <MenuItem
            expanded={isOpen}
            title={MenuItemName.STATISTIC}
            Icon={PieChartOutline}
            path={`${ROUTE.STATISTIC}`}
          />
          <MenuItem
            expanded={isOpen}
            title={MenuItemName.CATEGORIES}
            Icon={ViewStreamOutlined}
            path={`${ROUTE.CATEGORIES}?limit=12&page=1`}
          />
          <MenuItem
            expanded={isOpen}
            title={MenuItemName.SHOPS}
            Icon={ShoppingCartOutlined}
            path={`${ROUTE.SHOPS}?limit=12&page=1`}
          />
          <MenuItem
            expanded={isOpen}
            title={MenuItemName.PAPERS}
            Icon={ImportContacts}
            path={`${ROUTE.PAPERS}?limit=12&page=1`}
          />
          <MenuItem
            expanded={isOpen}
            title={MenuItemName.PRODUCTS}
            Icon={CakeOutlined}
            path={`${ROUTE.PRODUCTS}?limit=12&page=1`}
          />
          <MenuItem
            expanded={isOpen}
            title={MenuItemName.IMPORT}
            Icon={ImportExport}
            path={`${ROUTE.IMPORT}`}
          />
        </List>
        <Divider sx={{ background: 'grey', marginTop: 'auto' }} />
        <List>
          <MenuItemProfile expanded={isOpen} Icon={LogoutOutlined} />
        </List>
      </Drawer>
    </>
  );
};

export default Menu;
