import { Badge, Box, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import useBindDispatch from '@hooks/useBindDispatch';
import { showModalAction } from '@actions/app/modal';

import * as React from 'react';
import { MODAL_TYPES } from '@components/Modal/types';
import moment from 'moment';
import IconButtonDelete from '@components/Buttons/IconButtons/IconButtonDelete';
import IconButtonEdit from '@components/Buttons/IconButtons/IconButtonEdit';
import { ICard } from '@components/types';
import { IProductRecord } from '@reducers/types';

const ProductCard = ({ item }: ICard) => {
  const {
    id,
    logoUrl = '',
    names,
    endDate = '',
    discount = '',
    oldPrice = 0,
    newPrice = 0,
    shop,
  } = item as IProductRecord;

  const showModal = useBindDispatch(showModalAction);

  const handleDeleteProduct = () => {
    showModal({ type: MODAL_TYPES.DELETE_PRODUCT, entityId: id });
  };

  const handleEditProduct = () => {
    showModal({ type: MODAL_TYPES.EDIT_PRODUCT, entityId: id });
  };

  return (
    <Card>
      <CardActions>
        <IconButtonEdit onClick={handleEditProduct} />
        <IconButtonDelete onClick={handleDeleteProduct} />
      </CardActions>
      <CardMedia
        style={{
          height: '30px',
          width: '30px',
          alignSelf: 'start',
          marginBottom: '15px',
        }}
        component="img"
        image={shop?.logoUrl || './No_Image_Available.jpg'}
        alt="card-image"
      />
      <Badge
        color="secondary"
        badgeContent={<span style={{ maxWidth: '35px', left: '5%' }}>{discount}</span>}
      >
        <CardContent>
          <CardMedia
            component="img"
            image={logoUrl || './No_Image_Available.jpg'}
            alt="card-image"
          />
          <Box className={'cardFooter'}>
            <Typography gutterBottom variant="h5" component="div">
              {names?.EN}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography gutterBottom variant="h6" component="div">
                Valid until {moment(endDate).format('DD-MM-YYYY')}
              </Typography>
              <Box>
                <Typography gutterBottom variant="h4" component="div">
                  CHF {oldPrice}
                </Typography>
                <Typography gutterBottom variant="h3" component="div">
                  CHF {newPrice}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Badge>
    </Card>
  );
};

export default ProductCard;
