import {
  ICategoryRecord,
  IPaperRecord,
  IProductRecord,
  IShopRecord,
  IStoreRecord,
  TranslationObjectType,
  UploadTableType,
} from '@reducers/types';
import { Moment } from 'moment/moment';

export enum MODAL_TYPES {
  CREATE_CATEGORY,
  LOADER,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  IMPORT_PRODUCT,
  IMPORT_PAPER,
  CREATE_SHOP,
  EDIT_SHOP,
  DELETE_SHOP,
  CREATE_STORE,
  EDIT_STORE,
  DELETE_STORE,
  CREATE_PAPER,
  DELETE_PAPER,
  EDIT_PAPER,
}

export interface IModal {
  title?: string;
  submitButtonText?: string;
  closeModal: () => void;
  handleSubmit: (data: any) => void;
}

export interface ICategoryModal extends IModal {
  category?: ICategoryRecord;
}

export interface IProductModal extends IModal {
  shops?: Pick<IShopRecord, 'id' | 'name' | 'categories' | 'papers'>[];
  product?: IProductRecord;
}

export interface IShopModal extends IModal {
  categories?: ICategoryRecord[];
  shop?: IShopRecord | null;
}

export interface IStoreModal extends IModal {
  shop?: IShopRecord | null;
  store?: IStoreRecord;
}

export interface IPaperModal extends IModal {
  paper?: IPaperRecord;
  shops?: IShopRecord[];
}

export interface ImportModal {
  uploadAction: UploadTableType;
}

export interface IProductsImport {
  handleChange: any;
  values: any;
}

export enum Days {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export interface ITimeTableDay {
  from?: Moment | null;
  till?: Moment | null;
}

export type ITimeTableState = {
  [key in Days]: ITimeTableDay | null;
} & {
  holidays: Array<Moment | string>;
};

export interface IUpdateShopFormState extends ICreateShopFormState {
  id: string;
}

export type IShopModalFormState = ICreateShopFormState | IUpdateShopFormState;

export interface ITimeTable {
  values: Required<ICreateShopFormState | ICreateStoreFormState>;
  setFieldValue: (field: string, value: any) => void;
}

export interface ITimeTableDialog extends ITimeTable {
  show: boolean;
  handleClose: () => void;
}

export interface ICreateStoreFormState {
  id: string;
  shopId: string;
  address: string;
  useShopTimeTable: boolean;
  workingHours: ITimeTableState;
}

export interface ICreateProductFormState {
  productId: string;
  externalId: string;
  shop: IShopRecord;
  category: ICategoryRecord | undefined;
  paper: IPaperRecord | undefined;
  logo: File | null;
  logoUrl: string;
  translation: TranslationObjectType;
  url: string;
  promotionEndDate: Moment;
  discount: string;
  oldPrice: string;
  newPrice: string;
}

export interface ICreatePaperFormState {
  id: string;
  externalId: string;
  shop: IShopRecord | undefined;
  logo: File | null;
  translation: TranslationObjectType;
  categories: ICategoryRecord[];
  promotionEndDate: Moment;
  logoUrl: string;
  images: File[];
  imagesUrls: string[];
}

export interface ICreateShopFormState {
  logo?: any;
  name?: string;
  logoUrl?: string;
  isFavorite?: boolean;
  categories?: ICategoryRecord[];
  workingHours?: ITimeTableState;
}
