import React from 'react';
import { IStoreModal } from '@components/Modal/types';
import { Box, Button, Typography } from '@mui/material';
import {
  modalButtonsSectionStyle,
  modalContentContainerStyle,
} from '@components/Modal/ModalContainer';

const DeleteStoreModal = ({ handleSubmit, store, closeModal }: IStoreModal) => {
  return (
    <>
      <Box sx={modalContentContainerStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Delete store address
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: '48px' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure you want to delete shop address
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {store?.address}?
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          All data will be lost
        </Typography>
      </Box>
      <Box sx={modalButtonsSectionStyle}>
        <Button size="large" variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={handleSubmit.bind(null, store?.id)} size="large" variant="contained">
          Delete store
        </Button>
      </Box>
    </>
  );
};

export default DeleteStoreModal;
