import { Box, Typography, Button, Autocomplete, TextField } from '@mui/material';
import React, {SyntheticEvent, useEffect} from 'react';

import {
  modalButtonsSectionStyle,
  modalContentContainerStyle,
} from '@components/Modal/ModalContainer';
import TranslationInput from '@components/Form/Inputs/TranslationInput/TranslationInput';
import { TranslationForm } from '@components/types';
import { ICategoryModal } from '@components/Modal/types';
import { useFormik } from 'formik';
import { categoryFormValidationSchema } from '@components/Modal/ModalComponents/FormValidationSchemas';

const CategoryModal = ({
  closeModal,
  handleSubmit,
  category,
  title,
  submitButtonText,
}: ICategoryModal) => {
  const formik = useFormik({
    initialValues: {
      translation: {
        EN: category?.names.EN || '',
        DE: category?.names.DE || '',
        FR: category?.names.FR || '',
        IT: category?.names.IT || '',
        BP: category?.names.BP || '',
      },
      metaCategories: category?.metaCategories?.map(record => record.name) || [],
    },
    validationSchema: categoryFormValidationSchema,
    onSubmit: (values) => {
      handleSubmit({ id: category?.id, ...values });
    },
  });

  const { setFieldValue, handleChange, values, errors, isValid, validateForm } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  const handleChangeMetaCategories = (e: SyntheticEvent<Element, Event>, value: string[]) => {
    setFieldValue('metaCategories', value);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={modalContentContainerStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <TranslationInput
          defaultValue={values.translation.EN}
          onChange={handleChange}
          error={!!errors.translation}
          helperText={String(errors.translation)}
          label={'Category name EN'}
          language={TranslationForm.EN}
        />
        <TranslationInput
          defaultValue={values.translation.DE}
          onChange={handleChange}
          error={!!errors.translation}
          helperText={String(errors.translation)}
          label={'Category name DE'}
          language={TranslationForm.DE}
        />
        <TranslationInput
          defaultValue={values.translation.FR}
          onChange={handleChange}
          error={!!errors.translation}
          helperText={String(errors.translation)}
          label={'Category name FR'}
          language={TranslationForm.FR}
        />
        <TranslationInput
          defaultValue={values.translation.IT}
          onChange={handleChange}
          error={!!errors.translation}
          helperText={String(errors.translation)}
          label={'Category name IT'}
          language={TranslationForm.IT}
        />
        <TranslationInput
          defaultValue={values.translation.BP}
          onChange={handleChange}
          error={!!errors.translation}
          helperText={String(errors.translation)}
          label={'Category name PT'}
          language={TranslationForm.BP}
        />
        <Autocomplete
          multiple
          limitTags={8}
          id={'metaCategories'}
          options={[]}
          onChange={handleChangeMetaCategories}
          fullWidth
          value={values.metaCategories}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Synonyms" placeholder="Synonyms" />
          )}
        />
      </Box>
      <Box sx={modalButtonsSectionStyle}>
        <Button size="large" variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="large" variant="contained" type="submit" disabled={!isValid}>
          {submitButtonText}
        </Button>
      </Box>
    </form>
  );
};

export default CategoryModal;
