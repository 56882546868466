import { InitialProductsStateType, ReducerAction } from '@reducers/types';
import { SET_PRODUCTS, SET_PRODUCTS_LOADING_STATE } from '@actions/actionTypes';

const initialState: InitialProductsStateType = {
  records: [],
  pagesTotal: 1,
  isLoading: false,
};

const productsReducer = (
  state: InitialProductsStateType = initialState,
  { type, payload }: ReducerAction,
): InitialProductsStateType => {
  switch (type) {
    case SET_PRODUCTS: {
      const { data, pagesTotal } = payload;
      return { ...state, records: data, pagesTotal };
    }
    case SET_PRODUCTS_LOADING_STATE: {
      return { ...state, isLoading: payload };
    }
  }

  return state;
};

export default productsReducer;
