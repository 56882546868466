import styles from './Products.module.scss';
import React from 'react';
import SearchInput from '@components/SearchInput/SearchInput';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CardTile from '@components/CardTile/CardTile';
import useBindDispatch from '@hooks/useBindDispatch';
import { showModalAction } from '@actions/app/modal';
import { useSelector } from 'react-redux';
import {
  selectCategoryByIdFromShop,
  selectedShopId,
  selectIsLoading,
  selectProductsData,
  selectProductsPagesAmount,
} from '@selectors/productsSelectors';
import ProductCard from '@components/CardItems/Product/ProductCard';
import { MODAL_TYPES } from '@components/Modal/types';
import { useSearchParams } from 'react-router-dom';
import ImportButton from '@components/Buttons/ImportButton/ImportButton';
import AddItemButton from '@components/Buttons/AddItemButton/AddItemButton';
import Pagination from '@components/Pagination/Pagination';
import { selectShopsData } from '@selectors/shopsSelectors';

const Products = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const showModal = useBindDispatch(showModalAction);
  const pagesTotal = useSelector(selectProductsPagesAmount);
  const records = useSelector(selectProductsData);
  const isLoading = useSelector(selectIsLoading);
  const selectedShop = useSelector(selectedShopId);
  const selectedCategory = useSelector(selectCategoryByIdFromShop);
  const shops = useSelector(selectShopsData);

  const handleShowAddProductModal = () => {
    showModal({ type: MODAL_TYPES.CREATE_PRODUCT });
  };

  const handleSelectShop = (e: SelectChangeEvent) => {
    const shopId = e.target.value;
    const newShopSelectValue = shops.find((shop) => shop.id === shopId)?.id;
    if (newShopSelectValue) {
      searchParams.set('shopId', newShopSelectValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('shopId');
      searchParams.delete('categoryId');
      setSearchParams(searchParams);
    }
  };

  const handleSelectCategory = (e: SelectChangeEvent) => {
    const categoryId = e.target.value;
    const newCategorySelectValue = selectedShop?.categories?.find(
      (category) => category?.id === categoryId,
    )?.id;
    if (newCategorySelectValue) {
      searchParams.set('categoryId', newCategorySelectValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('categoryId');
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <div className={styles.header}>
        <h1>Products</h1>
        <SearchInput />
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
        <FormControl fullWidth sx={{ marginBottom: '10px', marginRight: '25px' }}>
          <InputLabel id="select-shop-input-label">Select shop</InputLabel>
          <Select
            onChange={handleSelectShop}
            labelId="select-shop-label"
            value={selectedShop?.id || 'All'}
            name="shop"
            label="Shop"
          >
            <MenuItem value={'All'} id={'empty-select-shop'}>
              {'All'}
            </MenuItem>
            {shops?.map((shop) => (
              <MenuItem value={shop.id} id={shop.id} key={shop.id}>
                {shop.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
          <InputLabel id="select-category-input-label">Select category</InputLabel>
          <Select
            onChange={handleSelectCategory}
            labelId="select-shop-label"
            value={selectedCategory?.id || 'All'}
            name="shop"
            label="Shop"
          >
            <MenuItem value={'All'} id={'empty-select-category'}>
              {'All'}
            </MenuItem>
            {selectedShop?.categories?.map((category) => (
              <MenuItem value={category.id} id={category.id} key={category.id}>
                {category.names.EN}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ImportButton />
      </Box>
      <AddItemButton title={'Create product'} onClick={handleShowAddProductModal} />
      <Pagination pagesTotal={pagesTotal} />
      <CardTile Card={ProductCard} items={records} isLoading={isLoading} />
    </>
  );
};

export default Products;
