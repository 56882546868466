import React from 'react';
import './App.css';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Provider } from 'react-redux';
import store, { history } from '@store/index';
import ModalContainer from '@components/Modal/ModalContainer';
import Notification from '@components/Notification/Notification';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from '@router/PrivateRoutes';
import { ROUTE } from '@router/routes';
import Layout from '@components/Layout/Layout';
import Statistic from '@pages/Statistic';
import Categories from '@pages/Categories';
import Shops from '@pages/Shops';
import ShopPageId from '@pages/Shops/ShopPageID/ShopPageId';
import Papers from '@pages/Papers';
import Products from '@pages/Products';
import LoginPage from '@pages/LoginPage';
import ImportPage from '@pages/Import/Import';

function App() {
  return (
    <Provider store={store}>
      <ModalContainer />
      <Notification />
      <Router history={history}>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path={ROUTE.MAIN} element={<Layout view={<></>} />} />
            <Route path={ROUTE.STATISTIC} element={<Layout view={<Statistic />} />} />
            <Route path={ROUTE.CATEGORIES} element={<Layout view={<Categories />} />} />
            <Route path={`${ROUTE.SHOPS}/*`}>
              <Route index element={<Layout view={<Shops />} />} />
              <Route path={':id'} element={<Layout view={<ShopPageId />} />} />
            </Route>
            <Route path={ROUTE.PAPERS} element={<Layout view={<Papers />} />}>
              <Route path={`${ROUTE.PAPERS}/import`} element={<Papers />}>
                <Route path={':id'} element={<Papers />} />
              </Route>
            </Route>
            <Route path={ROUTE.PRODUCTS} element={<Layout view={<Products />} />}>
              <Route path={`${ROUTE.PRODUCTS}/import`} element={<Products />}>
                <Route path={':id'} element={<Products />} />
              </Route>
            </Route>
          </Route>
          <Route path={ROUTE.IMPORT} element={<Layout view={<ImportPage />} />} />
          <Route path={ROUTE.LOGIN} element={<LoginPage />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
