import { IAppState } from '@store/model';
import { createSelector } from 'reselect';

export const selectShops = (state: IAppState) => state.shops;

export const selectShopsData = createSelector(selectShops, (shops) => shops.records);

export const selectShopsLoadingState = createSelector([selectShops], (shops) => shops.isLoading);

export const selectShop = createSelector([selectShops], (shops) => shops.selected);
