import React from 'react';
import { Box } from '@mui/material';
import SmartTable from '@components/SmartTable/SmartTable';
import { useSelector } from 'react-redux';
import { selectDocumentsForImport, selectTemplates } from '@selectors/importSelectors';
import { ImportDocType } from '@reducers/types';
import { capitalizeWord } from '@src/utils';
import { DeleteDocumentPayloadType } from '@actions/types';

const templatesHead = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Template name',
  },
];

const documentsHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'id',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
];

interface ImportTabProps {
  type: ImportDocType;
  deleteTemplateAction: (payload: DeleteDocumentPayloadType) => void;
  deleteDocumentAction: (payload: DeleteDocumentPayloadType) => void;
}

const ImportTab = ({ type, deleteTemplateAction, deleteDocumentAction }: ImportTabProps) => {
  const templates = useSelector(selectTemplates);
  const documents = useSelector(selectDocumentsForImport);

  const paperTemplates = templates.filter((template) => template.type === type);
  const paperDocuments = documents.filter((document) => document.type === type);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <SmartTable
        title={`${capitalizeWord(type)} templates`}
        headerCells={templatesHead}
        data={paperTemplates}
        deleteAction={deleteTemplateAction}
        type={type}
      />
      <SmartTable
        title={`Imported ${type} files`}
        headerCells={documentsHead}
        data={paperDocuments}
        deleteAction={deleteDocumentAction}
        type={type}
      />
    </Box>
  );
};

export default ImportTab;
