import * as types from '@actions/actionTypes';
import { IModalStateType } from '@reducers/types';

export const closeModalAction = () => ({
  type: types.CLOSE_MODAL,
});

export const showModalAction = (payload: IModalStateType) => ({
  type: types.SHOW_MODAL,
  payload,
});
