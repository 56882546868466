import { createBrowserHistory } from 'history';

const routeHistory = createBrowserHistory();

export const goTo = (path: string): void => routeHistory.push(path);

export const backRoute = (): void => {
  const { location } = routeHistory;
  const locationArray = location.pathname.split('/');

  locationArray.pop();

  const backLocation = locationArray.join('/');
  goTo(backLocation);
};

export const appendPath = (_path: string): void => {
  const { location } = routeHistory;
  const locationArray = location.pathname.split('/');
  const path = _path.replaceAll('/', '');
  locationArray.push(path);

  goTo(locationArray.join('/'));
};

export const setImportIdUrl = (id: string): void => {
  const { location } = routeHistory;
  const locationArray = location.pathname.split('/');
  const importPath = locationArray.slice(0, locationArray.indexOf('import') + 1);

  importPath.push(id);

  goTo(importPath.join('/'));
};

export const leaveImportUrl = (): void => {
  const { location } = routeHistory;
  const locationArray = location.pathname.split('/');

  const newPath = locationArray.slice(0, locationArray.length - 2);

  goTo(newPath.join('/'));
};

export default routeHistory;
