import React from 'react';
import style from './Button.module.scss';
import { Button as MUIButton } from '@mui/material';
import { IButton } from '@components/types';

const Button = ({ disabled = false, children, action }: IButton) => {
  return (
    <MUIButton
      type="submit"
      fullWidth
      disabled={disabled}
      variant="contained"
      className={style.regular}
      sx={{
        mt: 3,
        mb: 2,
      }}
      onClick={action}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
