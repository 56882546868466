import { ChartOptions } from 'chart.js';

export const CONTENT_TYPE_JSON = { 'Content-Type': 'application/json' };
export const AUTH_TOKEN_KEY = 'AUTH_TOKEN';
export const USER_DATA = 'USER_DATA';
export const BAR_CHART_OPTIONS: ChartOptions<'bar'> = {
  indexAxis: 'x' as const,
  responsive: true,
  scales: {
    y: {
      position: 'right',
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

export const META_BAR_CHART_OPTIONS: ChartOptions<'line'> = {
  indexAxis: 'x' as const,
  responsive: true,
  scales: {
    y: {
      position: 'right',
      display: false,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

export const LINE_CHART_OPTIONS: ChartOptions<'line'> = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
  },
};
