import * as types from '@actions/actionTypes';
import { IGetWithPagination, IPaperRecord } from '@reducers/types';
import { ICreatePaperFormState } from '@components/Modal/types';

export const fetchPapersAction = (payload?: any) => ({
  type: types.FETCH_PAPERS,
  payload,
});

export const savePapersAction = (payload: IGetWithPagination<IPaperRecord>) => ({
  type: types.SET_PAPERS,
  payload,
});

export const createPaperAction = (payload: ICreatePaperFormState) => ({
  type: types.CREATE_PAPER,
  payload,
});

export const deletePaperAction = (payload: string) => ({
  type: types.DELETE_PAPER,
  payload,
});

export const updatePaperAction = (payload: ICreatePaperFormState) => ({
  type: types.UPDATE_PAPER,
  payload,
});

export const setIsLoadingAction = (payload: boolean) => ({
  type: types.SET_PAPER_IS_LOADING,
  payload,
});
