import React from 'react';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import { IStoreRecord, ITimeTableType } from '@reducers/types';
import moment from 'moment';
import { ITimeTableDay, MODAL_TYPES } from '@components/Modal/types';
import useBindDispatch from '@hooks/useBindDispatch';
import { showModalAction } from '@actions/app/modal';
import { ICard } from '@components/types';
import IconButtonEdit from '@components/Buttons/IconButtons/IconButtonEdit';
import IconButtonDelete from '@components/Buttons/IconButtons/IconButtonDelete';
import { useParams } from 'react-router-dom';

const StoreCard = ({ item: _item }: ICard) => {
  const { id: shopId } = useParams();
  const showModal = useBindDispatch(showModalAction);
  const item = _item as IStoreRecord;

  const getOpenHours = () => {
    const today = moment().format('dddd') as keyof Omit<ITimeTableType, 'id'>;
    const from =
      moment((item?.timeTable[today] as unknown as ITimeTableDay).from).format('hh:mm') || 'null';
    const till =
      moment((item?.timeTable[today] as unknown as ITimeTableDay)?.till).format('hh:mm') || 'null';

    return `${from} to ${till}`;
  };

  const handleClickEdit = () => {
    showModal({ type: MODAL_TYPES.EDIT_STORE, entityId: item.id });
  };

  const handleClickDelete = () => {
    showModal({ type: MODAL_TYPES.DELETE_STORE, entityId: item.id });
  };

  return (
    <Card style={{ minHeight: '75px' }}>
      <CardActions>
        <IconButtonEdit onClick={handleClickEdit} />
        <IconButtonDelete onClick={handleClickDelete} />
      </CardActions>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {item.address}
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          Open today: {getOpenHours()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StoreCard;
