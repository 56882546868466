import { IAppState } from '@store/model';
import { createSelector } from 'reselect';

export const selectImport = (state: IAppState) => state.import;

export const selectIsImportLoading = createSelector(
  selectImport,
  (importState) => importState.isLoading,
);

export const selectImportType = createSelector(selectImport, (importState) => importState.type);

export const selectImportStatus = createSelector(selectImport, (importState) => importState.status);

export const selectImportFields = createSelector(selectImport, (importState) => importState.fields);

export const selectImportId = createSelector(selectImport, (importState) => importState.id);

export const selectMappedFields = createSelector(
  selectImport,
  (importState) => importState.mappedFields,
);

export const selectBakedData = createSelector(selectImport, (importState) => importState.baked);

export const selectBakedChecked = createSelector(selectImport, (importState) =>
  importState.baked.filter((baked) => baked.selected).map((baked) => baked.id),
);

export const selectTemplates = createSelector(selectImport, (importState) => importState.templates);
export const selectDocumentsForImport = createSelector(
  selectImport,
  (importState) => importState.documents,
);
