import React from 'react';
import { ICreatePaperFormState } from '@components/Modal/types';
import { Card, CardActions, CardMedia, InputLabel } from '@mui/material';
import * as uuid from 'uuid';
import IconButton from '@mui/material/IconButton';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';

const ImageCard = ({ src, handleDelete }: { src: string; handleDelete: () => void }) => {
  return (
    <Card sx={{ width: 'fit-content!important', position: 'relative', overflow: 'visible' }}>
      <CardActions style={{ position: 'absolute', right: '-50%', top: '-50%' }}>
        <IconButton
          color={'primary'}
          aria-label="delete"
          onClick={(e) => {
            e.preventDefault();
            handleDelete();
          }}
        >
          <HighlightOffTwoToneIcon />
        </IconButton>
      </CardActions>
      <CardMedia
        sx={{ borderRadius: '8px', width: '56px!important', height: '56px!important' }}
        component="img"
        image={src}
        alt="card-image"
        height={'56px'}
        width={'56px!important'}
      />
    </Card>
  );
};

const ImagesInput = ({
  images,
  handleChange,
  handleDeleteImage,
}: {
  images: string[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteImage: (e: any, idx: number) => void;
}) => {
  return (
    <>
      <input
        style={{ display: 'none' }}
        accept="image/*"
        id="images-input"
        type="file"
        multiple
        onChange={handleChange}
      />
      <label htmlFor="images-input" style={{ position: 'relative' }}>
        <InputLabel shrink style={{ background: 'white', position: 'absolute' }}>
          Select images
        </InputLabel>
        <div
          style={{
            maxHeight: '200px',
            overflow: 'overlay',
            position: 'relative',
            border: '1px solid rgba(0, 0, 0, 0.27)',
            borderRadius: '4px',
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: 'fit-content',
              padding: '25px',
              display: 'flex',
              gap: '20px',
              flexWrap: 'wrap',
            }}
          >
            {images.map((image, idx) => (
              <ImageCard
                key={uuid.v4()}
                src={image}
                handleDelete={handleDeleteImage.bind(null, null, idx)}
              />
            ))}
          </div>
        </div>
      </label>
    </>
  );
};

export default ImagesInput;
