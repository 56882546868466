import React, { ReactNode } from 'react';

import { useSelector } from 'react-redux';
import { selectModal } from '@selectors/appSelectors';
import { Box, Modal, Fade } from '@mui/material';
import { closeModalAction } from '@actions/app/modal';
import useBindDispatch from '@hooks/useBindDispatch';
import CategoryModal from '@components/Modal/ModalComponents/Category/CategoryModal/CategoryModal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteCategoryModal from '@components/Modal/ModalComponents/Category/DeleteCategoryModal/DeleteCategoryModal';
import { selectCategoriesData } from '@selectors/categoriesSelectos';
import LoaderModal from '@components/Modal/ModalComponents/LoaderModal/LoaderModal';
import ProductModal from '@components/Modal/ModalComponents/Product/ProductModal/ProductModal';
import { selectShop, selectShopsData } from '@selectors/shopsSelectors';
import DeleteProductModal from '@components/Modal/ModalComponents/Product/DeleteProductModal/DeleteProductModal';
import { selectProducts } from '@selectors/productsSelectors';
import { MODAL_TYPES } from '@components/Modal/types';
import {
  createCategoryAction,
  deleteCategoryAction,
  updateCategoryAction,
} from '@actions/categories';
import { createProductAction, deleteProductAction, updateProductAction } from '@actions/products';
import Import from '@components/Modal/ModalComponents/Import/Import';
import { goTo } from '@src/routeHistory';
import { clearImportAction, uploadTableAction } from '@actions/import';
import ShopModal from '@components/Modal/ModalComponents/Shop/ShopModal/ShopModal';
import {
  createShopAction,
  createStoreAction,
  deleteShopAction,
  deleteStoreAction,
  editStoreAction,
  updateShopAction,
} from '@actions/shops';
import DeleteShopModal from '@components/Modal/ModalComponents/Shop/DeleteShopModal/DeleteShopModal';
import StoreModal from '@components/Modal/ModalComponents/Store/StoreModal/StoreModal';
import DeleteStoreModal from '@components/Modal/ModalComponents/Store/DeleteStoreModal/DeleteStoreModal';
import PaperModal from '@components/Modal/ModalComponents/Paper/PaperModal';
import { createPaperAction, deletePaperAction, updatePaperAction } from '@actions/papers';
import DeletePaperModal from '@components/Modal/ModalComponents/Paper/DeletePaperModal';
import { selectPapersData } from '@selectors/papersSelectors';

const wrapperStyle = {
  position: 'relative' as const,
  margin: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  width: '50vw',
  minWidth: '700px',
  padding: '20px 25px',
  display: 'inline-grid',
};

export const modalContentContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  p: 4,
  padding: '25px 0',
};

export const modalButtonsSectionStyle = {
  display: 'flex',
  justifyContent: 'end',
};

interface IModalWrapper {
  isOpened: boolean;
  withCloseButton?: boolean;
  children: ReactNode;
  handleClose?: () => void;
}

const ModalWrapper = ({ isOpened, children, withCloseButton, handleClose }: IModalWrapper) => {
  return (
    <Modal
      sx={{
        overflow: 'auto',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
      }}
      open={isOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={isOpened}>
        <Box sx={wrapperStyle}>
          {withCloseButton && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                margin: '0 0 0 auto',
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

const ModalContainer = () => {
  const { type, isOpened, entityId } = useSelector(selectModal);

  const createCategory = useBindDispatch(createCategoryAction);
  const updateCategory = useBindDispatch(updateCategoryAction);
  const deleteCategory = useBindDispatch(deleteCategoryAction);
  const deleteShop = useBindDispatch(deleteShopAction);
  const createProduct = useBindDispatch(createProductAction);
  const deleteProduct = useBindDispatch(deleteProductAction);
  const updateProduct = useBindDispatch(updateProductAction);
  const uploadDocument = useBindDispatch(uploadTableAction);
  const createShop = useBindDispatch(createShopAction);
  const updateShop = useBindDispatch(updateShopAction);
  const createStore = useBindDispatch(createStoreAction);
  const updateStore = useBindDispatch(editStoreAction);
  const deleteStore = useBindDispatch(deleteStoreAction);
  const createPaper = useBindDispatch(createPaperAction);
  const deletePaper = useBindDispatch(deletePaperAction);
  const updatePaper = useBindDispatch(updatePaperAction);

  const selectedShop = useSelector(selectShop);
  const clearImport = useBindDispatch(clearImportAction);
  const closeModal = useBindDispatch(closeModalAction);
  const categories = useSelector(selectCategoriesData);
  const shops = useSelector(selectShopsData);
  const { records: products } = useSelector(selectProducts);
  const product = products.find((product) => product.id === entityId);
  const category = categories.find((categories) => categories.id === entityId);
  const shop = shops.find((shop) => shop.id === entityId);
  const store = selectedShop?.stores?.find((store) => store.id === entityId);
  const papers = useSelector(selectPapersData);
  const paper = papers.find((paper) => paper.id === entityId);

  const handleCloseModal = () => {
    closeModal();

    switch (type) {
      case MODAL_TYPES.IMPORT_PRODUCT: {
        goTo('/products');
        clearImport();

        break;
      }
      case MODAL_TYPES.IMPORT_PAPER: {
        goTo('/papers');
        clearImport();

        break;
      }
    }
  };

  switch (type) {
    case MODAL_TYPES.CREATE_CATEGORY: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <CategoryModal
            title={'Add category'}
            submitButtonText={'Add category'}
            closeModal={handleCloseModal}
            handleSubmit={createCategory}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.EDIT_CATEGORY: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <CategoryModal
            title={'Edit category'}
            submitButtonText={'Save changes'}
            handleSubmit={updateCategory}
            category={category}
            closeModal={handleCloseModal}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.DELETE_CATEGORY: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <DeleteCategoryModal
            handleSubmit={deleteCategory}
            closeModal={handleCloseModal}
            category={category}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.CREATE_PRODUCT: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <ProductModal
            title={'Create product'}
            submitButtonText={'Create product'}
            closeModal={handleCloseModal}
            shops={shops}
            handleSubmit={createProduct}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.DELETE_PRODUCT: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <DeleteProductModal
            title={'Delete product'}
            handleSubmit={deleteProduct}
            product={product}
            closeModal={handleCloseModal}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.EDIT_PRODUCT: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <ProductModal
            title={'Edit product'}
            submitButtonText={'Save changes'}
            closeModal={handleCloseModal}
            shops={shops}
            product={product}
            handleSubmit={updateProduct}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.IMPORT_PRODUCT: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <Import uploadAction={uploadDocument} />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.IMPORT_PAPER: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <Import uploadAction={uploadDocument} />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.CREATE_SHOP: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <ShopModal
            categories={categories}
            title={'Add shop'}
            submitButtonText={'Add shop'}
            closeModal={handleCloseModal}
            handleSubmit={createShop}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.DELETE_SHOP: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <DeleteShopModal handleSubmit={deleteShop} closeModal={handleCloseModal} shop={shop} />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.EDIT_SHOP: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <ShopModal
            title={'Edit shop'}
            submitButtonText={'Save changes'}
            handleSubmit={updateShop}
            categories={categories}
            closeModal={handleCloseModal}
            shop={selectedShop}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.CREATE_STORE: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <StoreModal
            title={'Add store'}
            submitButtonText={'Create store'}
            handleSubmit={createStore}
            closeModal={handleCloseModal}
            shop={selectedShop}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.EDIT_STORE: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <StoreModal
            title={'Edit store'}
            submitButtonText={'Save changes'}
            handleSubmit={updateStore}
            closeModal={handleCloseModal}
            store={store}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.DELETE_STORE: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <DeleteStoreModal
            handleSubmit={deleteStore}
            closeModal={handleCloseModal}
            store={store}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.CREATE_PAPER: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <PaperModal
            title={'Add paper'}
            submitButtonText={'Save changes'}
            handleSubmit={createPaper}
            closeModal={handleCloseModal}
            shops={shops}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.DELETE_PAPER: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <DeletePaperModal
            submitButtonText={'Yes'}
            handleSubmit={deletePaper}
            closeModal={handleCloseModal}
            paper={paper}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.EDIT_PAPER: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton handleClose={handleCloseModal}>
          <PaperModal
            title={'Edit paper'}
            submitButtonText={'Save changes'}
            handleSubmit={updatePaper}
            closeModal={handleCloseModal}
            shops={shops}
            paper={paper}
          />
        </ModalWrapper>
      );
    }
    case MODAL_TYPES.LOADER: {
      return (
        <ModalWrapper isOpened={!!isOpened} withCloseButton={false}>
          <LoaderModal />
        </ModalWrapper>
      );
    }
    default:
      return <></>;
  }
};

export default ModalContainer;
